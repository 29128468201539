import Konami from "@/konami";
import Fuse from "fuse.js";
import _ from "lodash";

/**
 * @description Add watcher to vuex store
 * @return {void}
 */
export function initStoreWatcher(store: any) {
  new (Konami as any)(() => {
    store.dispatch("KONAMI");
  });

  store.watch(
    (state: any) => state.nodes.event,
    (event: any) => {
      const searchList = ["$display", "$zopim", ...event].map((key) => ({ value: key })) || [];
      store.state.search.event = new Fuse(searchList, {
        keys: ["value"],
      });
    }
  );

  store.watch(
    (state: any) => state.apieditor.mappings,
    (mappings: any) => {
      const searchList = _.keys(mappings).map((key: any) => ({ value: key })) || [];
      store.state.search.api_mappings = new Fuse(searchList, {
        keys: ["value"],
      });
    }
  );

  store.watch(
    (state: any) => state.nodes.content,
    (content: any) => {
      const contentNodeIds = _.keys(content);
      const baseContentNodeIds = _.filter(contentNodeIds, (key: any) => !key.includes(":"));
      const mappedBaseContentNodeIds = _.map(baseContentNodeIds, (key: any) => ({
        value: key,
      }));
      store.state.search.content = new Fuse(mappedBaseContentNodeIds || [], {
        keys: ["value"],
      });
    }
  );

  store.watch(
    (state: any) => _.get(state, "nodes.entity", {}),
    (content: any) => {
      const searchList = _.keys(content).map((key: any) => ({ value: key })) || [];
      store.state.search.entity = new Fuse(searchList, {
        keys: ["value"],
      });
    }
  );
}
