<template>
  <div style="margin-bottom: 2em; display: flex; align-items: center">
    <span style="color: #909399; fontsize: 14px">
      {{ setting.label || name }}
    </span>
    <Tooltip :label="setting.label || name" :content="setting.detail" />
    <br />

    <div style="margin-left: 10px">
      <el-slider
        v-if="setting.slider === true"
        show-input
        v-model="value[name]"
        :disabled="setting.disabled ? true : false"
        :required="setting.required"
        :min="setting.min"
        :max="setting.max"
        :step="setting.step ? setting.step : 1"
        style="width: 720px; max-width: 100%"
      />

      <el-input-number
        v-else
        v-model="value[name]"
        :disabled="setting.disabled ? true : false"
        :required="setting.required"
        :min="min"
        :max="max"
        :step="setting.step ? setting.step : 1"
      />
    </div>
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  computed: {
    min() {
      const conditional = this.setting.conditional;
      if (!conditional || !conditional.min) {
        return this.setting.min;
      }

      const dependentKey = Object.keys(conditional.min)[0];
      if (!dependentKey) {
        return this.setting.min;
      }

      const dependentValue = this.value[dependentKey];
      if (!dependentValue) {
        return this.setting.min;
      }

      const minValue = conditional?.min?.[dependentKey]?.[dependentValue];
      if (minValue && this.value[this.name] < minValue) {
        this.$set(this.value, this.name, minValue);
      }
      return minValue;
    },
    max() {
      const conditional = this.setting.conditional;
      if (!conditional || !conditional.max) {
        return this.setting.max;
      }

      const dependentKey = Object.keys(conditional.max)[0];
      if (!dependentKey) {
        return this.setting.max;
      }

      const dependentValue = this.value[dependentKey];
      if (!dependentValue) {
        return this.setting.max;
      }

      const maxValue = conditional?.max?.[dependentKey]?.[dependentValue];
      if (maxValue && this.value[this.name] > maxValue) {
        this.$set(this.value, this.name, maxValue);
      }
      return maxValue;
    },
  },
  mounted() {
    if (this.value[this.name] == null && typeof this.value === "object") {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
