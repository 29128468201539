<template>
  <div style="display: inline-block">
    <el-checkbox
      v-model="value[name]"
      :label="setting.label || name"
      border
      style="color: #909399; margin-bottom: 1em; margin-right: 5px"
    />
    <Tooltip :label="setting.label || name" :content="setting.detail" />
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null && typeof this.value === "object") {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
