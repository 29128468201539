<template>
  <div class="error-wrapper">
    <div class="error-container">
      <el-row>
        <el-col class="error-header">
          <div class="error-image">
            <img
              class="image"
              src="../../static/keyreply-bot-customer-service-dead.png"
              style="max-width: 100%; width: 500px"
            />
          </div>

          <h2>{{ message }}</h2>
        </el-col>
      </el-row>

      <el-row>
        <el-col class="error-details">
          <h4>Here are some reasons why you may be seeing this page</h4>

          <ul>
            <li v-if="type === 'session-timeout'">
              Your session may have expired
              <br />
              <br />
              <el-button size="small" type="success" @click="$store.dispatch('LOGOUT')">
                Re-login
              </el-button>
            </li>

            <li v-if="type === 'no-permission'">
              You do not have permission to see this page.
              <br />
              <br />
              <router-link :to="'/'">
                <el-button size="small" type="success">Back to home</el-button>
              </router-link>
            </li>

            <li v-if="type === 'not-found'">
              This page does not exist.
              <br />
              <br />
              <router-link :to="'/'">
                <el-button size="small" type="success">Back to home</el-button>
              </router-link>
            </li>

            <li v-if="type === 'connection-error'">
              The chatbot server ({{ $store.state.server }}) you are trying to access might not be
              online.
              <b>Please close the browser and try again later.</b>
            </li>
          </ul>

          <p class="help-block">
            If the above doesn't help, you may contact KeyReply support for assistance (we are here
            to help!)
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      message: "Not Found",
      notAccess: "You do not have access",
    };
  },
  computed: {},
  updated() {
    this.getType();
  },
  mounted() {
    this.getType();
  },
  methods: {
    getType() {
      if (this.type === "session-timeout") {
        this.message = "Oops! Your session has ended. Please login again.";
      } else if (this.type === "no-permission") {
        this.message = "Oops! You have no access to this page.";
      } else if (this.type === "connection-error") {
        this.message = "Oops! Cannot establish connection with Server.";
      } else {
        this.message = "Oops! This page does not exist.";
        this.type = "not-found";
      }
    },
  },
};
</script>

<style scoped>
.error-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  place-content: center;
  place-items: center;
  background: #e4392b;
  z-index: 999;
}
.error-container {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 40px;
  margin: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
}
.error-header img {
  display: block;
}
.error-header {
  text-align: center;
}
.error-image {
  display: flex;
  place-content: center;
  place-items: center;
}
.error-details {
  text-align: center;
}
.error-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.help-block {
  margin-top: 20px;
  padding-top: 20px;
  display: block;
  border-top: 1px solid #eee;
  font-size: 14px;
  color: #aaa;
}
</style>
