<template>
  <section>
    <el-popover v-model="isPopoverOpened" placement="bottom" width="344">
      <!-- What will be shown in the popover -->
      <el-input v-model="newMappingName" autofocus :disabled="isBusy" @keyup.enter.native="add">
        <template slot="prepend">Name</template>
        <el-button
          :disabled="isBusy"
          :loading="isBusy"
          slot="append"
          icon="el-icon-check"
          @click="add"
        />
      </el-input>

      <!-- Add new content -->

      <el-button
        slot="reference"
        :disabled="isBusy"
        icon="el-icon-plus"
        style="margin-bottom: 15px"
        type="primary"
        size="mini"
        plain
        >New Mapping</el-button
      >
    </el-popover>

    <el-row type="flex" style="flex-flow: row wrap">
      <el-card
        v-for="(mapping, index) in mappings"
        style="width: 45%; margin-right: 15px; margin-bottom: 15px"
        :key="index"
        shadow="hover"
      >
        <!-- Default settings -->
        <div slot="header" class="clearfix">
          <span>{{ mapping.name }}</span>
          <el-button
            style="float: right"
            @click.prevent="remove(mapping)"
            :disabled="isBusy"
            type="danger"
            circle
            plain
            size="mini"
            icon="el-icon-delete"
          ></el-button>
        </div>

        <el-form ref="apiMappingDetails" label-position="left" v-if="mapping">
          <el-form-item style="margin-bottom: 0">
            <el-input
              placeholder="Enter save as value"
              v-model="mapping.stateKey"
              :span="12"
              size="mini"
            >
              <div slot="prepend">
                Save As
                <el-tooltip content="State key to save api response">
                  <i class="el-icon-question" />
                </el-tooltip>
              </div>

              <div slot="append">
                <el-checkbox size="mini" v-model="mapping.saveInSystem"
                  ><i class="el-icon-lock" /> system</el-checkbox
                >
              </div>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 0; line-height: 1">
            <el-autocomplete
              v-model="mapping.preRenderNode"
              class="autocomplete-list autocomplete-list-target"
              placeholder="Select pre render node"
              size="mini"
              :disabled="isBusy"
              :fetch-suggestions="getContentNodes"
              :trigger-on-focus="false"
            >
              <div slot="prepend">
                Pre Render Node
                <el-tooltip
                  content="The Node to show before the target node displays. This will only show the very first time."
                >
                  <i class="el-icon-question" />
                </el-tooltip>
              </div>
              <el-button
                v-if="mapping.preRenderNode"
                slot="append"
                icon="el-icon-d-arrow-right"
                @click="selectNode({ type: 'content', id: mapping.preRenderNode })"
              />
            </el-autocomplete>
          </el-form-item>
          <el-form-item style="margin-bottom: 0; line-height: 1">
            <el-autocomplete
              v-model="mapping.target"
              class="autocomplete-list autocomplete-list-target"
              placeholder="Select target node"
              size="mini"
              :fetch-suggestions="getContentNodes"
              :disabled="isBusy"
              :trigger-on-focus="false"
            >
              <div slot="prepend">
                Target Node
                <el-tooltip content="The Node which bind the data from the API result">
                  <i class="el-icon-question" />
                </el-tooltip>
              </div>
              <el-button
                v-if="mapping.target"
                slot="append"
                icon="el-icon-d-arrow-right"
                @click="selectNode({ type: 'content', id: mapping.target })"
              />
            </el-autocomplete>
          </el-form-item>
          <el-form-item style="margin-bottom: 0; line-height: 1">
            <el-autocomplete
              v-model="mapping.noRecordNode"
              size="mini"
              class="autocomplete-list autocomplete-list-target"
              placeholder="Select no record node"
              :fetch-suggestions="getContentNodes"
              :disabled="isBusy"
              :trigger-on-focus="false"
            >
              <div slot="prepend">
                No Record Node
                <el-tooltip content="The Node to show if there is no data returned by the API">
                  <i class="el-icon-question" />
                </el-tooltip>
              </div>
              <el-button
                v-if="mapping.noRecordNode"
                slot="append"
                icon="el-icon-d-arrow-right"
                @click="selectNode({ type: 'content', id: mapping.noRecordNode })"
              />
            </el-autocomplete>
          </el-form-item>
          <el-form-item style="margin-bottom: 0; line-height: 1">
            <el-autocomplete
              size="mini"
              v-model="mapping.errorNode"
              class="autocomplete-list autocomplete-list-target"
              placeholder="Select error node"
              :fetch-suggestions="getContentNodes"
              :disabled="isBusy"
              :trigger-on-focus="false"
            >
              <div slot="prepend">
                Error Node
                <el-tooltip content="The Node to show if there is an error returned by the API">
                  <i class="el-icon-question" />
                </el-tooltip>
              </div>
              <el-button
                v-if="mapping.errorNode"
                slot="append"
                icon="el-icon-d-arrow-right"
                @click="selectNode({ type: 'content', id: mapping.errorNode })"
              />
            </el-autocomplete>
          </el-form-item>

          <div style="text-align: right">
            <el-button
              @click.prevent="save(mapping)"
              :disabled="isBusy"
              icon="el-icon-check"
              style="color: #67c23a"
              size="mini"
              plain
              >Save changes</el-button
            >
          </div>
        </el-form>
      </el-card>
    </el-row>
  </section>
</template>
<script>
export default {
  components: {},
  props: ["api"],
  data() {
    return {
      isBusy: false,
      mappings: [],
      newMappingName: null,
      isPopoverOpened: false,
    };
  },
  watch: {
    api: {
      deep: true,
      handler(newApi, oldApi) {
        if (newApi.name !== oldApi.name) {
          this.findMappings();
        }
      },
    },
  },
  mounted() {
    this.findMappings();
  },
  methods: {
    findMappings() {
      this.mappings = _.chain(this.$store.state)
        .get("apieditor.mappings", {})
        .values()
        .filter((mapping) => mapping.api === this.api.name)
        .value();
    },
    getContentNodes(value, cb) {
      if (value) {
        cb(this.$store.state.search.content.search(value));
      }
    },
    async add() {
      if (this.isBusy) {
        return;
      }

      try {
        this.isBusy = true;
        this.newMappingName = this.newMappingName.toLowerCase().replace(/ /g, "_");

        const newApi = await this.$store.dispatch("CREATE_API_MAPPING", {
          apiMappingName: this.newMappingName,
          api: this.api.name,
        });
        this.findMappings();
        this.$message({
          type: "success",
          message: "Successfully created Mapping",
        });
        this.newMappingName = null;
        this.isPopoverOpened = false;
      } catch (error) {
        if (error.message.includes("Mapping already exists")) {
          this.$message({
            type: "error",
            message:
              "Mapping with same name already exists. Provide a different name and try again.",
          });
        } else {
          this.$message({
            type: "error",
            message: "Encountered error creating api.",
          });
        }
      } finally {
        this.isBusy = false;
      }
    },
    async remove(mapping) {
      if (this.isBusy) {
        return;
      }

      try {
        this.isBusy = true;
        await this.$store.dispatch("DELETE_API_MAPPING", {
          apiMappingName: mapping.name,
        });
        this.$message({
          type: "success",
          message: "Successfully deleted mapping",
        });
        this.findMappings();
      } catch (error) {
        this.$message({
          type: "error",
          message: "Encountered error deleting mapping",
        });
      } finally {
        this.isBusy = false;
      }
    },
    async save(mapping) {
      if (this.isBusy) {
        return;
      }

      try {
        this.isBusy = true;
        await this.$store.dispatch("SAVE_API_MAPPING", {
          apiMappingName: mapping.name,
          mappingObject: mapping,
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: "Encountered error isBusy mapping!",
        });
      } finally {
        this.isBusy = false;
      }
    },
    /**
     * @description On select node event handler
     * @return {void}
     */
    selectNode({ type, id }) {
      this.$eventBus.$emit("editorSwitchTab", "nodes");
      this.$router.push("/editor");
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
  },
};
</script>

<style scoped>
.autocomplete-list {
  margin-top: 0;
}
</style>
