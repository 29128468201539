import _ from "lodash";
import store from "@/store";

export function getUserPermissions(moduleName: string): boolean {
  const accessControlModule = store.state.modules["access_control"];
  const userRoles = store.state.profile["https://login.keyreply.com/roles"] || [];
  const hasAccess = _.chain(accessControlModule.roles)
    .filter((role) => role && userRoles.includes(role["name"]))
    .map((role) => (role ? role["access"] : null))
    .some(moduleName)
    .value();

  return hasAccess;
}
