<template>
  <main>
    <el-dialog class="custom-dialog" :visible.sync="customDialog" width="40%" @closed="closeDialog">
      <span slot="title" class="dialog-heading"> Customize "{{ nodeForm.title }}" </span>
      <el-tabs v-model="customizeTabName">
        <el-tab-pane label="Customize node" name="customize">
          <!-- SLOT -->
          <el-row v-if="nodeForm.type === 'standard' || nodeForm.type === 'frame'" :gutter="10">
            <el-col :span="20">
              <p class="customize-dialog">Slots</p>
              <span>
                Enable this to gather the information your virtual assistant needs to respond to a
                user within a single node.
              </span>
            </el-col>
            <el-col :span="4">
              <el-switch
                v-model="currentSlot"
                @change="
                  setEdited('slotToggled', true);
                  changeSlot();
                "
              />
            </el-col>
          </el-row>
          <!-- MCR -->
          <el-row
            v-if="nodeForm.type === 'standard' || nodeForm.type === 'frame'"
            :gutter="10"
            style="margin-top: 30px"
          >
            <el-col :span="20">
              <p class="customize-dialog">Multiple Responses</p>
              <span>
                Enable multiple responses so that your virtual assistant can provide different
                responses to the same input, based on other conditions.
              </span>
            </el-col>
            <el-col :span="4">
              <el-switch
                v-model="currentMcr"
                @change="
                  setEdited('MCRToggled', true);
                  changeMcr();
                "
              />
            </el-col>
          </el-row>
          <p v-if="nodeForm.type === 'folder'" class="customize-dialog">
            There is no customization available for this node
          </p>
        </el-tab-pane>
        <!-- DIGRESSIONS
        <el-tab-pane
          label="Digressions"
          name="digressions"
        >
          <el-row
            :gutter="10"
            v-if="nodeForm.type === 'standard' || nodeForm.type === 'frame'"
          >
            <el-col :span="4">
              <img
                src="../../assets/digression-goaway.svg"
                width="64px"
                height="69px"
              />
            </el-col>
            <el-col :span="16">
              <p class="customize-dialog"> Digressions can go away from this node</p>
              <span>Allow return from digressions triggered after this node's response.</span>
            </el-col>
            <el-col :span="4">
              <el-switch
                @change="enableDigressOut()"
                v-model="digress_out"
              >
              </el-switch>
            </el-col>
          </el-row>
          <el-row
            :gutter="10"
            style="margin-top: 30px;"
            v-if="nodeForm.type === 'standard' || nodeForm.type === 'frame'"
          >
            <el-col :span="4">
              <img
                src="../../assets/digression-comein.svg"
                width="64px"
                height="69px"
              />
            </el-col>
            <el-col :span="16">
              <p class="customize-dialog">Digressions can come into this node</p>
              <span>Allow digressions into this node.</span>
              <el-checkbox
                :disabled="!digress_in"
                @change="updateReturnDigressIn()"
                v-model="digress_return"
              >Return after digression.</el-checkbox>
            </el-col>
            <el-col :span="4">
              <el-switch
                @change="enableDigressIn()"
                v-model="digress_in"
              >
              </el-switch>
            </el-col>
          </el-row>
        </el-tab-pane>-->
      </el-tabs>
    </el-dialog>

    <!-- RESPONSE DELETE CONFIRM -DIALOGS -->
    <el-dialog :visible.sync="showMcrConfirm" :before-close="closeMcrConfirm">
      <span slot="title" class="dialog-heading"> Warning: "{{ nodeForm.title }}" </span>
      <div class="customize-dialog">
        All existing Multiple Conditioned Responses will be
        <span style="color: red">deleted</span>. Do you wish to proceed?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            showMcrConfirm = false;
            currentMcr = true;
          "
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="
            showMcrConfirm = false;
            enableMcr();
          "
        >
          Confirm
        </el-button>
      </span>
    </el-dialog>

    <!-- RESPONSE DELETE CONFIRM -SLOT NODES -->
    <el-dialog :visible.sync="showSlotNodeConfirm" :before-close="closeSlotNodeConfirm">
      <span slot="title" class="dialog-heading"> Warning: "{{ nodeForm.title }}" </span>
      <div class="customize-dialog">
        All existing Slots will be
        <span style="color: red">deleted</span>. Do you wish to proceed?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            showSlotNodeConfirm = false;
            currentSlot = true;
          "
        >
          Cancel
        </el-button>
        <el-button
          type="primary"
          @click="
            showSlotNodeConfirm = false;
            enableSlot();
          "
        >
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </main>
</template>

<script>
export default {
  name: "DialogCustomize",
  props: {
    nodeForm: Object,
    slotNode: Array,
    responseNodes: Array,
  },
  data() {
    return {
      customizeTabName: "customize",
      digress_in: false,
      digress_return: false,
      digress_out: false,
      currentMcr: false,
      currentSlot: false,
      customDialog: true,
      showMcrConfirm: false,
      showSlotNodeConfirm: false,
    };
  },
  computed: {},
  mounted() {
    this.customDialog = true;
    if (this.nodeForm.type == "frame" && this.slotNode) {
      this.currentSlot = true;
    } else {
      this.currentSlot = false;
    }

    if (
      this.nodeForm.metadata &&
      this.nodeForm.metadata._customization &&
      this.nodeForm.metadata._customization.mcr
    ) {
      // handle mcr
      this.currentMcr = true;
    } else {
      this.currentMcr = false;
    }

    if (this.nodeForm.digress_out) {
      if (this.nodeForm.digress_out === "allow_all") {
        this.digress_out = true;
      } else {
        this.digress_out = false;
      }
    }

    if (this.nodeForm.digress_in) {
      if (this.nodeForm.digress_in === "not_available") {
        this.digress_in = false;
        this.digress_return = false;
      } else if (this.nodeForm.digress_in === "does_not_return") {
        this.digress_in = true;
        this.digress_return = false;
      } else {
        this.digress_in = true;
        this.digress_return = true;
      }
    }
  },
  methods: {
    setEdited(nodeVariableType, isEdited) {
      this.$set(this.$store.state.watson, "isEditedObject", {
        [nodeVariableType]: isEdited,
      });
    },
    closeDialog() {
      this.$emit("closeDialog-click", this.customDialog);
    },
    enableDigressOut() {
      const allowType = this.digress_out ? "allow_all" : "allow_all_never_return";
      this.$set(this.nodeForm, "digress_out", allowType);
      this.$emit("updateNode-click", this.nodeForm);
    },
    changeSlot() {
      if (!this.currentSlot) {
        this.showSlotNodeConfirm = true;
      } else {
        this.enableSlot();
      }
    },
    changeMcr() {
      if (!this.currentMcr) {
        this.showMcrConfirm = true;
      } else {
        this.enableMcr();
      }
    },
    enableMcr() {
      this.customDialog = false;
      this.showMcrConfirm = false;
      if (this.currentMcr) {
        let output = this.nodeForm.output;
        this.$set(this.nodeForm, "output", {});
        this.$set(this.nodeForm, "metadata", { _customization: { mcr: true } });
        this.$emit("updateNode-click", this.nodeForm);
        this.$emit("addMcr-click", this.nodeForm);
      } else {
        let output = {};
        // delete all mcr child nodes before disabelling mcr
        if (this.responseNodes) {
          output = this.responseNodes[0].output;
          this.responseNodes.forEach((node, index) => {
            this.$emit("deleteNode-click", node);
          });
        }
        this.$set(this.nodeForm, "output", output);
        this.$set(this.nodeForm, "metadata", {
          _customization: { mcr: false },
        });
        this.$emit("updateNode-click", this.nodeForm);
      }
    },
    closeMcrConfirm(done) {
      this.currentMcr = true;
      done();
    },
    enableSlot() {
      this.customDialog = false;
      this.showSlotNodeConfirm = false;
      this.$set(this.nodeForm, "type", "frame");
      this.$emit("updateNode-click", this.nodeForm);
      if (this.currentSlot && !this.slotNode) {
        this.$emit("addSlot-click", this.nodeForm);
      } else if (!this.currentSlot) {
        let output = {};
        if (this.slotNode) {
          output = this.slotNode[0].output;
          this.slotNode.forEach((node, index) => {
            this.$emit("deleteNode-click", node);
          });
          this.$set(this.nodeForm, "output", output);
          this.$emit("updateNode-click", this.nodeForm);
        }
      }
    },
    updateReturnDigressIn() {
      const returnType = this.digress_return ? "returns" : "does_not_return";
      this.$set(this.nodeForm, "digress_in", returnType);
      this.$emit("updateNode-click", this.nodeForm);
    },
    enableDigressIn() {
      const returnType = !this.digress_in
        ? "not_available"
        : this.digress_return
        ? "returns"
        : "does_not_return";
      this.$set(this.nodeForm, "digress_in", returnType);
      this.$emit("updateNode-click", this.nodeForm);
    },
  },
};
</script>
