<template>
  <el-drawer
    title="Select intent"
    :visible.sync="$store.state.showIntentSelector"
    direction="ltr"
    :modal="true"
    :wrapperClosable="true"
    class="intent-drawer-modal"
  >
    <div style="padding-left: 20px; padding-right: 20px">
      <SearchBar placeholder="Search intent here..." :onChange="onSearchChange" />
    </div>
    <div style="padding-left: 15px; padding-right: 15px; margin-top: 15px">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="intentsLength"
        @prev-click="togglePage"
        @next-click="togglePage"
        @current-change="togglePage"
        :pageSize="20"
      ></el-pagination>
    </div>
    <div>
      <ul class="intent-row">
        <li
          v-for="intent in pagedIntentsList"
          @click="intentSelected(intent.id, intent.intentName)"
          :key="intent.id"
        >
          <span>
            <span>{{ intent.intentName }}</span>
            <span>
              <el-tooltip
                v-if="intent.invalid"
                content="This intent has conflicting examples ❌"
                placement="top"
              >
                <i class="el-icon-warning list-icon" style="color: #e4392b" />
              </el-tooltip>
              <el-tooltip
                v-if="intent.warning"
                content="This intent has unevaluated examples"
                placement="top"
                style="margin-left: 10px"
              >
                <i class="el-icon-question list-icon" style="color: #a89e32" />
              </el-tooltip>
              <span>
                <el-tooltip v-if="intent.enabled" content="This intent is enabled" placement="top">
                  <span class="keyreply-indicator enabled" />
                </el-tooltip>
                <el-tooltip v-else content="This intent is disabled" placement="top">
                  <span class="keyreply-indicator" />
                </el-tooltip>
              </span>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </el-drawer>
</template>

<script>
import SearchBar from "@/components/Faq/searchBar.vue";
import _ from "lodash";
import { flowchartSetup } from "../helperMethods/simple_flowchart";

export default {
  components: {
    SearchBar,
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    intentsLength() {
      return this.filteredIntents().length;
    },
    intentsList() {
      const dataset = _.get(this.$store.state, "training.dataset", []);
      const intentList = dataset.map((val) => ({
        id: val.id || "",
        intentName: val.intentName,
        enabled: val.enabled,
        invalid: val.invalid,
        warning: this.checkUnevaluatedExamples(val.variations),
        variations: val.variations,
      }));

      return intentList;
    },
    pagedIntentsList() {
      // search implementation

      const intentFiltered = this.filteredIntents();

      // pagination implementation
      const pageSize = 20;
      const startingIndex = (this.currentPage - 1) * pageSize;
      const currentIndex = this.currentPage * pageSize;
      const totalDatasetSize = intentFiltered.length;
      if (totalDatasetSize === 0) {
        return [];
      }

      let endingIndex = totalDatasetSize;
      if (totalDatasetSize >= currentIndex) {
        endingIndex = currentIndex;
      }

      const pagedIntentsList = intentFiltered.slice(startingIndex, endingIndex);
      return pagedIntentsList;
    },
  },
  methods: {
    onSearchChange() {
      // reset page when searching
      this.currentPage = 1;
    },
    filteredIntents() {
      const query = _.get(this, "$store.state.faq.intentSearch", "");
      const intentFiltered = this.intentsList.filter((pair) => {
        let search = query.trim().toLowerCase();
        if (search) {
          const variationText = _.get(pair, "variations", []).map((variation) => {
            return {
              text: variation.text,
              language: variation.language,
            };
          });
          const intentAndExamples = {
            intentName: pair.intentName,
            variations: variationText,
            action: pair.action,
          };
          let isMatch = JSON.stringify(intentAndExamples).trim().toLowerCase().includes(search);
          return isMatch;
        } else {
          return true;
        }
      });
      return intentFiltered;
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
    },
    checkUnevaluatedExamples(variations) {
      const filterUnevaluated = variations.filter(
        (variation) => !variation.intent_ranking || variation.intent_ranking.length === 0
      );
      const unevaluatedExists = filterUnevaluated.length > 0;
      return unevaluatedExists;
    },
    intentSelected(id, name) {
      this.setMappedFlow(id);
      this.$store.commit("SET_INTENT_SELECTED", { id, name });
      this.$store.commit("TOGGLE_INTENT_SELECTOR", false);
    },
    setMappedFlow(id) {
      let output = _.get(this.$store.state, "training.flows", []);

      let data = _.cloneDeep(output);

      const nodes = data.nodes.filter((node) => !node.isVersion);
      data.nodes = nodes;

      const flows = data.flows.filter((node) => !node.isVersion);
      data.flows = flows;

      data = flowchartSetup(data, id, "");

      this.$store.commit("SET_MAPPED_FLOW", data);
    },
  },
};
</script>

<style lang="scss">
.intent-drawer-modal {
  top: 0px;
  left: 0px;
  width: 1500px;
  height: 100%;
  .el-drawer {
    overflow: scroll;
  }
  .intent-row {
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    li {
      cursor: pointer;
      margin: 0;
      padding: 15px;
      padding-left: 20px;
      padding-right: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      list-style-type: none;
      > span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .keyreply-indicator {
        width: 10px;
        height: 10px;
        background-color: #e4392b;
        border-radius: 10px;
        display: inline-block;
        margin-left: 10px;
        &.enabled {
          background-color: #32a84c;
        }
      }
    }
  }
}
</style>
