<template>
  <!-- Content -->
  <div>
    <el-row class="edior-text-node">
      <el-col v-if="multiple" class="row-fluid">
        <div v-if="node.type === 'list'">
          <el-card style="padding-left: 150px; padding-right: 150px">
            <List :node="node" editable @save-side-effect="saveChanges" />
          </el-card>
        </div>
        <div v-else>
          <el-popover
            v-for="(card, index) in node.content"
            v-dragging="{
              item: card,
              list: node.content,
              group: 'node_contents',
            }"
            :key="index"
            placement="top"
            trigger="hover"
          >
            <Card
              slot="reference"
              :index="index"
              :card="card"
              editable
              :style="{
                borderWidth: selectedCardIndex === index ? '2px' : '1px',
              }"
              @selectCard="selectedCardIndex = index"
              @save-side-effect="saveChanges"
            />
            <el-button-group>
              <el-button
                :disabled="index === 0"
                icon="el-icon-arrow-left"
                @click="moveLeft(index)"
              />
              <el-button
                style="width: 100px"
                type="danger"
                icon="el-icon-delete"
                @click="removeCard(index)"
              />
              <el-button
                :disabled="index >= node.content.length - 1"
                icon="el-icon-arrow-right"
                @click="moveRight(index)"
              />
            </el-button-group>
          </el-popover>
        </div>
      </el-col>
      <el-col v-else>
        <div v-if="node.content && node.content.image" class="image-media">
          <el-input
            v-show="edit_image"
            ref="image_input"
            v-model="imageURL"
            name="image"
            placeholder="image"
            required
            type="url"
            style="zIndex=2"
            @change="stopEdit"
          >
            <el-button slot="prepend" icon="el-icon-picture" />
          </el-input>
          <img
            ref="imgRef"
            width="80%"
            height="80%"
            :src="node.content.image || imageURL"
            style="max-height: 300px; max-width: 300px"
            @error="setDefault"
            @click="edit"
          />
        </div>
        <Card
          v-else-if="isCard"
          :card="node.content"
          editable
          @addButton="addButton"
          @save-side-effect="saveChanges"
        />
        <Bubble
          v-else
          v-model="node.content"
          editable
          :nodeIsInvalid="nodeIsInvalid"
          @addButton="addButton"
          @addVariation="addTextVariation"
          @addSelection="addSelection"
          @save-side-effect="saveChanges"
        />
      </el-col>
    </el-row>

    <el-row v-if="allowUserFeedback && enabledFeedbackCollectionOnContentNode">
      <FeedbackCollections />
    </el-row>

    <!-- Quick Replies -->
    <el-row v-if="nodeTextIsNotEmpty">
      <QuickReplies
        v-model="node.quickReplies"
        @addQuickReply="addQuickReply"
        @save-side-effect="saveChanges"
      />
    </el-row>
  </div>
</template>

<script>
import { getDefaultPlaceholderImage } from "@/helperMethods/util";
import Card from "@/components/Card";
import List from "@/components/List";
import FeedbackCollections from "@/components/FeedbackCollections";
import _get from "lodash/get";
import { validateContent } from "@/helperMethods/task_management/content";
import { allowFeedbackOnContentNode } from "@/helperMethods/faq/util";
const placeholderURL = getDefaultPlaceholderImage();

export default {
  name: "ContentNode",
  components: {
    Bubble: () => import("@/components/Bubble"),
    QuickReplies: () => import("@/components/QuickReplies"),
    Card,
    List,
    FeedbackCollections,
  },
  data() {
    return {
      edit_image: false,
      imageURL: (this.node && this.node.content && this.node.content.image) || placeholderURL,
      selectedCardIndex: 0,
    };
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nodeTextIsNotEmpty() {
      return !!_.get(this.node, "content.text");
    },
    nodeIsInvalid() {
      return validateContent(this.node.id);
    },
    multiple() {
      if (this.node) {
        return Array.isArray(this.node.content);
      } else {
        return false;
      }
    },
    isCard() {
      if (this.node && this.node.content) {
        if (this.multiple) {
          return this.node.content[0].image;
        } else {
          return this.node.content.image && this.node.content.subtext;
        }
      } else {
        return false;
      }
    },
    enabledFeedbackCollectionOnContentNode() {
      const feedbackCollectionSettings = _get(this, "$store.state.modules.faq.feedbackCollection");
      return feedbackCollectionSettings && feedbackCollectionSettings?.enabledForContentNode;
    },
    allowUserFeedback() {
      const contentNode = _get(this, `$store.state.nodes.content[${this.node.id}]`, {});
      const collectionOptions = _get(this, "$store.state.modules.faq.feedbackCollection", {});
      const isAllowUserFeedback = allowFeedbackOnContentNode(contentNode, collectionOptions);
      return isAllowUserFeedback;
    },
  },
  methods: {
    moveLeft(index) {
      let placeholder = this.node.content[index - 1];
      this.node.content[index - 1] = this.node.content[index];
      this.node.content[index] = placeholder;
      this.$forceUpdate();
    },
    moveRight(index) {
      let placeholder = this.node.content[index + 1];
      this.node.content[index + 1] = this.node.content[index];
      this.node.content[index] = placeholder;
      this.$forceUpdate();
    },
    removeCard(index) {
      const self = this;
      // storing first card in the card list to a temporary variable to retain the full details
      if (!self.currentNode) {
        if (self.node.content.length > 0) {
          self.currentNode = self.node.content[0];
        }
      }
      this.$confirm("This will delete the card. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => {
          if (self.node.content.length === 1) {
            self.node.content = self.currentNode; // assign the current node
            delete self.node.content.image; // deleting image
            delete self.currentNode; // deleting temporary instance
            delete self.node.type; // deleting type, default content node does not contain "type"
          } else {
            self.node.content.splice(index, 1);
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            position: "bottom-right",
            message: `Error occured while deleting card.`,
          });
        });
    },
    setDefault(event) {
      this.$refs.imgRef.src = placeholderURL;
    },
    edit() {
      this.edit_image = true;
      // To wait for the url textbox to show first
      this.$nextTick(() => {
        this.$refs.image_input.focus();
      });
    },
    stopEdit() {
      this.node.content.image = this.imageURL.trim();
      this.edit_image = false;
    },
    addTextVariation() {
      if (!Array.isArray(this.node.content)) {
        if (Array.isArray(this.node.content.text)) {
          this.node.content.text.push("");
        } else {
          this.node.content.text = [this.node.content.text, ""];
        }
      } else {
        console.warn("Cannot add variation");
      }
    },
    addButton() {
      if (Array.isArray(this.node.content)) {
        let index = this.selectedCardIndex || 0;
        let card = this.node.content[index];
        if (!card.buttons) {
          this.$set(card, "buttons", []);
        }

        card.buttons.push({
          text: "",
          type: "event",
          active: "event",
          event: null,
          data: null,
          url: null,
          phone: null,
          webview_height_ratio: null,
        });
      } else {
        if (!this.node.content.buttons) {
          this.$set(this.node.content, "buttons", []);
        }

        this.node.content.buttons.push({
          text: "",
          type: "event",
          active: "event",
          event: null,
          data: null,
          url: null,
          phone: null,
          webview_height_ratio: null,
        });
      }
    },
    addQuickReply() {
      if (!this.node.quickReplies) {
        this.$set(this.node, "quickReplies", []);
      }

      this.node.quickReplies.push({
        text: "Quick Reply",
        event: null,
        data: null,
      });
    },
    saveChanges() {
      if (this.nodeHistoryType !== "Current") {
        this.$confirm(
          "Are you sure you want to overwrite the current node with one from an older snapshot?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        )
          .then(() => {
            this.saveContentNode();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Overwrite cancelled",
            });
          });
      } else {
        this.saveContentNode();
      }
    },
    addSelection() {
      if (!Array.isArray(this.node.content) && !this.node.content.selections) {
        this.$set(this.node.content, "selections", {
          enabled: true,
          type: "keyValue",
          data: [{ key: "", value: "" }],
          captureAs: "",
          label: "",
          next: null,
          options: { multiselect: false, saveAsBoolean: false },
        });
      }
    },
  },
  watch: {
    node(newVal) {
      if (newVal.content && newVal.content.image) {
        this.$set(this, "imageURL", newVal.content.image);
      }
    },
  },
};
</script>
