<template>
  <div>
    <span style="font-size: 32px; font-weight: lighter"> <b>Speech API</b> Demonstration </span>
    <el-button
      style="float: right"
      size="small"
      :plain="true"
      icon="el-icon-sort"
      @click="changeLanguage"
    >
      中
    </el-button>
    <br />
    <br />

    <div style="text-align: center">
      <el-button
        v-if="!listening"
        style="height: 150px; width: 150px; border-radius: 75px; font-size: 18px"
        type="success"
        icon="el-icon-phone-outline"
        @click="start"
      >
        Speak
      </el-button>
      <el-button
        v-else
        style="height: 150px; width: 150px; border-radius: 75px; font-size: 18px"
        type="error"
        icon="el-icon-loading"
        @click="stop"
      />
    </div>

    <p style="font-size: 40px; text-align: center">{{ text }}</p>
    <div style="text-align: center">
      <Bubble v-if="answer" :value="answer" />
      <el-tag v-if="label" type="success">{{ label }}</el-tag>
    </div>

    <!-- <iframe src="https://www.google.com/intl/en/chrome/demos/speech.html" style="width: 100%; height: 100vh; overflow: none;" frameBorder="0" scrolling="no"> -->
    <!-- </iframe> -->
  </div>
</template>

<script>
import _ from "lodash";
import Bubble from "@/components/Bubble.vue";

export default {
  components: {
    Bubble,
  },
  mounted() {
    this.$store.dispatch("FETCH_FAQ_DATASET");
  },
  data() {
    return {
      engine: null,
      text: "",
      answer: null,
      label: "",
      question: "",
      spokenAnswer: "",
      listening: false,
      testing: false,
    };
  },
  methods: {
    /**
     * @description Change language handler
     * @return {void}
     */
    changeLanguage() {
      if (this.engine) {
        if (this.engine.lang === "en-US") {
          this.engine.lang = "cmn-Hans-CN";
        } else {
          this.engine.lang = "en-US";
        }
      }
    },

    stop() {
      if (this.engine) this.engine.stop();
    },

    reset() {
      this.text = "";
      this.label = "";
      this.answer = null;
    },

    async recursiveCall(nodeName) {
      let contentNode = this.$store.state.nodes.content[nodeName];
      let nextNode = contentNode.next;

      if (contentNode) {
        this.answer = contentNode.content;
        let answerText = this.answer.text;
      } else {
        this.answer = this.$store.state.nodes.content["fallback_to_complex"];
      }

      this.spokenAnswer = this.$extractText(this.answer);

      const res = this.$speak(this.spokenAnswer).then(() => {
        if (nextNode) {
          this.recursiveCall(nextNode);
        }
      });
    },

    start() {
      this.reset();
      this.listening = true;
      if (_.get(this.refs, "siri.wave")) {
        this.refs.siri.wave.start();
      }

      if (this.engine) {
        this.engine.start();
      } else {
        this.engine = this.$listen(
          (text) => {
            this.text = text;
          },
          (text) => {
            if (text) {
              this.listening = false;
              this.testing = true;
              this.text = text;

              return this.$store
                .dispatch("TEST_FAQ", { questions: [this.text] })
                .then((predictions) => {
                  this.testing = false;

                  if (predictions[0] && predictions[0].predictions.length > 0) {
                    let prediction = predictions[0].predictions[0];

                    this.label = prediction.label;

                    let pair = _.find(this.$store.state.training.dataset, {
                      id: this.label,
                    });

                    if (pair) {
                      if (/^@/.test(pair.answer)) {
                        this.recursiveCall(pair.answer.substring(1));
                      } else {
                        this.answer = { text: pair.answer };
                        this.spokenAnswer = pair.answer;
                        this.$speak(this.spokenAnswer);
                      }
                    } else {
                      this.answer = { text: "No answer found" };
                    }
                  }
                });
            }
          }
        );

        this.engine.start();
      }
    },
  },
};
</script>
