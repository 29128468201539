var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"margin-left":"1.5em","margin-right":"3em","color":"#909399","fontsize":"14px","margin-bottom":"1em"}},[_vm._v(_vm._s(_vm.setting.label || _vm.name)+" ")]),_c('el-cascader',{attrs:{"options":_vm.setting.options,"props":_vm.setting.props,"collapse-tags":_vm.setting['collapse-tags'],"clearable":_vm.setting.clearable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return [_c('span',[_vm._v(_vm._s(data.label))]),_c('span',{style:({
          float: 'right',
          color: '#909399',
          fontSize: 13 + 'px',
          marginLeft: '1.5em',
        })},[_vm._v(" "+_vm._s(data.value ? data.value : data.label)+" ")])]}}]),model:{value:(_vm.value[_vm.name]),callback:function ($$v) {_vm.$set(_vm.value, _vm.name, $$v)},expression:"value[name]"}}),_c('Tooltip',{attrs:{"label":_vm.setting.label || _vm.name,"content":_vm.setting.detail}}),_vm._v("s ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }