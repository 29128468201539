import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import { MutationTree } from "vuex";
import { RootState } from "./types";
import ISO6391 from "iso-639-1";

// Different ways to mutate variables
// https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
const mutations: MutationTree<RootState> = {
  SET_USER_PROFILE(state, payload) {
    Vue.set(state, "profile", payload);
  },
  ACTIVATE_EDITOR(state) {
    state.showEditor = true;
  },
  ACTIVATE_ADVANCED_EDITOR(state) {
    state.showAdvanced = true;
  },
  ACTIVATE_DEVELOPER_CONSOLE(state) {
    state.showDeveloperConsole = true;
  },
  TOGGLE_INTENT_SELECTOR(state, shown) {
    state.showIntentSelector = shown;
  },
  SET_INTENT_SELECTED(state, id) {
    state.selectedIntent = id;
  },
  SET_SERVER_STATUS(state, { Server }) {
    Vue.set(state, "serverStatus", Server);
  },
  SET_ANALYTICS_CHART_URL(state, { urlWithUpdatedDate, index }) {
    Vue.set(state.modules.analytics.urlEmbedCharts, `reportURL${index + 1}`, urlWithUpdatedDate);
  },
  SET_AUTH_USERS(state, payload) {
    // state.livechats = _.cloneDeep(payload);
    let data = [];
    data = payload;
    const users: any[] = _.cloneDeep(data);
    _.forEach(users, (user) => {
      if (!user.user_metadata) {
        user.user_metadata = {
          name: "",
        };
      }
      // convert data structure just for use in Element's table
      const newRole: { [index: string]: boolean } = {
        admin: false,
        editor: false,
        agent: false,
      };

      user.app_metadata.roles = _.reduce(
        user.app_metadata.roles,
        (memory, role: string) => {
          memory[role] = true;
          return memory;
        },
        newRole
      );
    });
    state.auth0.users = users;
    state.auth0.usersStatic = _.cloneDeep(users);
  },
  ADD_AUTH_USER(state, payload) {
    const user: any = _.cloneDeep(payload);
    if (!user.user_metadata) {
      user.user_metadata = {
        name: "",
      };
    }
    // convert data structure just for use in Element's table
    const newRole: { [index: string]: boolean } = {
      admin: false,
      editor: false,
      agent: false,
    };

    user.app_metadata.roles = _.reduce(
      user.app_metadata.roles,
      (memory, role: string) => {
        memory[role] = true;
        return memory;
      },
      newRole
    );

    state.auth0.users.push(user);
  },
  DELETE_AUTH_USER(state, email) {
    const notUserWithEmail = _.remove(state.auth0.users, (user) => user.email !== email);
    state.auth0.users = notUserWithEmail;
  },
  UPDATE_LIVECHAT(state, payload) {
    const target_chat = _.find(state.livechats, (chat) => {
      return chat.livechat_data.RowKey === payload.livechat_data.RowKey;
    });
    if (target_chat) {
      Vue.set(target_chat, "livechat_data", payload.livechat_data);
    }
  },
  LOADED(state, value) {
    const currentLoaded = state.loaded;
    Vue.set(state, "loaded", currentLoaded + value);
  },
  SET_USERS(state, { users }) {
    state.users = users;
  },
  SET_AUDIT_LOG(state, { audit }) {
    state.audit = audit;
  },
  SET_BRAIN(state, { Bot: { brain }, Server: { account } }) {
    state.brain = brain;
    state.account = account;
  },
  SET_GREETING(state, { greeting }) {
    state.greeting = greeting;
  },
  SET_PERSISTENT_MENU(state, { persistentMenu }) {
    Vue.set(state, "persistentMenu", persistentMenu);
  },
  ARCHIVE_TRAINING_RECORD(state, row) {
    Vue.set(row, "archived", true);
  },
  ARCHIVE_ARRAY_TRAINING_RECORD(state, rows = []) {
    rows.map((row: any) => Vue.set(row, "archived", true));
  },
  UNARCHIVE_TRAINING_RECORD(state, row) {
    Vue.set(row, "archived", false);
  },
  SET_ANALYTICS_URL(state, reportURL) {
    const groupId = reportURL.match(/groups\/([a-z0-9-]+)/);
    const reportId = reportURL.match(/reports\/([a-z0-9-]+)/);
    Vue.set(state.modules.analytics.powerBI, "groupId", groupId);
    Vue.set(state.modules.analytics.powerBI, "reportId", reportId);

    return { groupId, reportId };
  },
  SET_BOT_LIST(state, { bots }) {
    Vue.set(state, "bots", bots);
  },
  SET_MISSING_DETAILS(state, { missingDetailAPI }) {
    Vue.set(state, "contentMissingDetails", missingDetailAPI);
  },
  SET_INTENT_MISSING_DETAILS(state, { missingIntentDetailAPI }) {
    Vue.set(state, "intentMissingDetails", missingIntentDetailAPI);
  },
  SET_DUPLICATE_TRIGGERS(state, { identicalTriggerAPI }) {
    Vue.set(state, "duplicateTriggers", identicalTriggerAPI);
  },
  SET_WILDCARD_TRIGGERS(state, { wildCardTriggerAPI }) {
    Vue.set(state, "wildcardTriggers", wildCardTriggerAPI);
  },
  SET_CONTENT_EXPIRY(state, setContentExpiry) {
    Vue.set(state, "missingContentExpiry", setContentExpiry);
  },
  SET_INTENT_EXPIRY(state, setIntentExpiry) {
    Vue.set(state, "missingIntentExpiry", setIntentExpiry);
  },
  SET_TRIGGER_EXPIRY(state, setTriggerExpiry) {
    Vue.set(state, "identicalTriggerExpiry", setTriggerExpiry);
  },
  SET_RATINGS(state, ratings) {
    Vue.set(state, "ratings", ratings);
  },
  SET_RATING_CHANNELS(state, ratingChannels) {
    Vue.set(state, "ratingChannels", ratingChannels);
  },
  SET_SNAPSHOT_HISTORY(state, data) {
    const nodes = state.nodes;
    nodes.changes = data.editorAPI.getSnapshots;
    Vue.set(state, "nodes", nodes);
  },
  SET_BOT_SNAPSHOTS(state, data) {
    Vue.set(state, "botSnapshots", data.Bot.getBotSnapshots);
  },
  SET_FAQ_SNAPSHOTS(state, data) {
    Vue.set(state, "faqSnapshots", data.Bot.getFAQSnapshots);
  },
  DELETE_BOT_SNAPSHOTS(state, snapshotsDeleted) {
    const botSnapshotsCopy = _.cloneDeep(state.botSnapshots);
    _.forEach(botSnapshotsCopy, function (value, key) {
      botSnapshotsCopy[key] = _.filter(value, (o) => !snapshotsDeleted.includes(o.snapshot));
    });
    Vue.set(state, "botSnapshots", botSnapshotsCopy);
  },
  DELETE_BOT(state) {
    state.greeting = "";
    state.persistentMenu = [];
    state.modules = {};
    state.nodes = {
      content: {},
      trigger: {},
      condition: {},
      event: [],
      tasks: [],
      changes: [],
      keywords: [],
    };
  },

  DELETE_QUESTION(state, questionId: string) {
    const questionIndex = _.findIndex(state.training.dataset, (q) => q.id === questionId);
    state.training.dataset.splice(questionIndex, 1);
  },
  SET_MODULES(state, { Bot: { modules } }) {
    Vue.set(state, "modules", Object.assign(state.modules, modules));
  },
  // Conditional Answers
  SET_NODES(state, { Bot: { content, trigger, entity, event, tasks, changes } }) {
    // set a map of keywords into the state, to check for duplicates
    const keywords = _.reduce(
      content,
      (accu: any, val: any, key: string) => {
        if (val.keywords && val.keywords.length) {
          const kwords = val.keywords;
          kwords.forEach((keyword: any) => {
            accu[keyword.value] = key;
          });
        }

        return accu;
      },
      {}
    );

    Vue.set(state, "nodes", {
      content,
      trigger,
      entity,
      event,
      tasks,
      changes,
      keywords,
    });
  },
  SET_KEYWORDS(state, keywords) {
    Vue.set(state.nodes, "keywords", keywords);
  },
  SET_MODEL_SCORE(state, score) {
    state.training.score = score;
  },
  NODE_NAVIGATION(state, select) {
    if (state.nodeNavigationStack.length === 10) {
      state.nodeNavigationStack.pop();
      state.nodeNavigationStack.unshift(select);
    } else {
      state.nodeNavigationStack.unshift(select);
    }
  },
  UPDATE_TRIGGER_DIFF: (state, triggerDiff) => {
    Vue.set(state.editor, "triggerDiff", triggerDiff);
  },
  SELECT_NODE(state, select) {
    if (select.type === "content" && select.id !== state.activeNodeId) {
      state.activeNodeHistoryType = "Current";
    }
    state.activeNodeType = select.type;
    state.activeNodeId = select.id;
  },
  SET_NODE(state, { type, id, node }) {
    if (node) {
      node.new = false;

      Vue.set(state.nodes[type as string], id, node);
    }
  },
  DELETE_NODE(state, { type, id }) {
    Vue.set(state, "activeNodeId", "conversation_start");

    Vue.delete(state.nodes[type], id);
  },
  DELETE_RENAMED_OLD_NODE(state, { type, id }) {
    Vue.delete(state.nodes[type], id);
  },
  RENAME_NODE(state, { type, oldId, newId }) {
    Vue.set(state.nodes[type], newId, state.nodes[type][oldId]);
    Vue.delete(state.nodes[type], oldId);
  },

  // TODO: move CREATE_CONTENT_NODE into editor state module
  CREATE_CONTENT_NODE(state, { id, node }) {
    if (!node) {
      node = {
        content: {
          text: "",
        },
        department: [],
        new: true,
      };
    }

    // node.uuid = uuid()
    Vue.set(state.nodes.content, id, node);
  },
  CREATE_TRIGGER_NODE(state, { id, gotoTarget, node }) {
    if (!node) {
      node = {
        priority: 10,
        pattern: "/./i",
        event: "goto",
        data: gotoTarget || "menu",
      };
    }

    Vue.set(state.nodes.trigger, id, node);
  },
  // Conditional Answers
  CREATE_CONDITION_NODE(state, { id }) {
    const condition = {
      condition: "placeholder",
      priority: 10,
    };

    Vue.set(state.nodes.condition, id, condition);
  },

  SET_POSTS(state, postsByDate) {
    state.posts = _.cloneDeep(postsByDate);
  },
  SET_POSTS_FILLER(state, postsFiller) {
    state.filler = postsFiller;
    let today = moment().format("YYYY-MM-DD");

    //tODO use sendingTime
    if (moment().hour() > _.get(state, "modules['subscription'].cron")) {
      today = moment().add(1, "day").format("YYYY-MM-DD");
    }

    if (state.posts[today] == null) {
      Vue.set(state.posts, today, []);
    }

    const todayPosts = state.posts[today];
    if (Array.isArray(todayPosts) && state.filler) {
      state.filler.forEach((post) => {
        if (!_.find(todayPosts, { text: post.text })) {
          todayPosts.push(post);
        }
      });
    }
  },

  // FIXME: will move this function
  ADD_CARD(state, { date, card }) {
    if (state.posts[date]) {
      state.posts[date].unshift(card);
    } else {
      Vue.set(state.posts, date, [card]);
    }
  },
  EDIT_CARD(state, { date, slot, card }) {
    Vue.set(state.posts[date], slot, card);
  },
  REMOVE_CARD(state, { date, slot }) {
    state.posts[date].splice(slot, 1);
    Vue.set(state.posts, date, state.posts[date]);
  },
  SWAP(state, { date, from, to }) {
    const placeholder = state.posts[date][to];
    Vue.set(state.posts[date], to, state.posts[date][from]);
    Vue.set(state.posts[date], from, placeholder);
  },
  SET_FAQ_DATASET(state, { dataset }) {
    Vue.set(state.training, "dataset", dataset);
  },
  SET_FAQ_SUPPORTED_LANGUAGES(state, { dataset }) {
    const languages: any = {};
    dataset.forEach((intent: { variations: any[] }) => {
      const variations = intent.variations;
      if (variations && variations.length > 0) {
        _.forEach(variations, (variation) => {
          const languageCode = variation.language;
          if (languageCode) {
            if (ISO6391.validate(languageCode)) {
              const languageName = ISO6391.getName(languageCode);
              const languageObject = { name: languageName, code: languageCode };
              languages[languageCode] = languageObject;
            }
          }
        });
      }
    });
    Vue.set(state.training, "supportedLanguages", languages);
  },
  SET_FAQ_FLOW(state, flows) {
    Vue.set(state.training, "flows", flows);
  },
  SET_FAQ_MODELS(state, { models, latestModel }) {
    const modelTraining = _.find(models, (o) => o.status === "In Progress");
    if (modelTraining) {
      Vue.set(
        state.training,
        "trainingProgress",
        !state.training.trainingProgress || state.training.trainingProgress < modelTraining.progress
          ? modelTraining.progress
          : state.training.trainingProgress
      );
      Vue.set(state.training, "training", true);
    } else {
      Vue.set(state.training, "trainingProgress", null);
      Vue.set(state.training, "training", false);
    }
    Vue.set(state.training, "models", models);
    Vue.set(state.training, "latestModel", latestModel);
  },
  SET_FAQ_UNLABELED(state, { questions }) {
    Vue.set(state.training, "all", questions);
  },
  SET_SEARCH_FAQ_UNLABELED(state, { questions }) {
    Vue.set(state.training, "searched", questions);
  },
  SET_MAPPED_FLOW(state, mappedFlow) {
    Vue.set(state, "mappedFlow", mappedFlow);
  },
  SET_VISUALIZATION_DATA(state, data) {
    Vue.set(state, "visualizationData", data);
  },
  SET_PROCESSING_VISUALIZATION(state, data) {
    Vue.set(state, "processingVisualization", data);
  },
  SET_RELEASE_ID(state, data) {
    Vue.set(state.releaseId, data["key"], data["value"]);
  },
  SET_CURRENT_TAB_LOGGED_OUT(state, data) {
    Vue.set(state, "currentTabLoggedOut", data);
  },
};

export default mutations;
