<template>
  <div class="loader-target">
    <!-- BUTTONS -->
    <el-row type="flex" justify="space-between" style="flex-flow: row wrap">
      <div>
        <el-button
          size="mini"
          type="primary"
          round
          icon="el-icon-plus"
          plain
          @click="openAddNodeDialog('first')"
          >Add Node</el-button
        >

        <el-button type="secondary" round size="mini" @click="openAddNodeDialog('firstfolder')"
          >Add Folder</el-button
        >
      </div>
      <el-input
        v-model="query"
        size="mini"
        style="max-width: 350px"
        placeholder="Search for dialog.."
        prefix-icon="el-icon-search"
      />
    </el-row>

    <el-row>
      <!-- LEFT NODE TREE -->
      <el-col v-if="dialog_nodes.length > 0" class="node-tree">
        <!-- TREE CHILD PARENT STRUCTURE -->
        <DialogNode
          v-for="(node, index) in dialog_nodes"
          :key="node.dialog_node"
          :length="dialog_nodes.length"
          :child-nodes="childNodes"
          :title="nodeForm.dialog_node"
          :node="node"
          :index="index"
          :display-node="displayNode"
          @node-clicked="nodeClick"
          @addChild-click="openAddNodeDialog('child', $event)"
          @addAbove-click="openAddNodeDialog('above', $event)"
          @addBelow-click="openAddNodeDialog('below', $event)"
          @addFolder-click="openAddNodeDialog('folder', $event)"
          @delete-click="deleteNode"
        />
      </el-col>
      <!-- EL-DIALOGS -->
      <!-- CUSTOMIZE NODE -DIALOG -->
      <DialogCustomize
        v-if="dialogCustomizeVisible"
        :node-form="nodeForm"
        :slot-node="slotNodes[nodeForm.dialog_node]"
        :response-nodes="responseNodes[nodeForm.dialog_node]"
        @closeDialog-click="closeCustomDialog()"
        @deleteNode-click="deleteNode($event)"
        @addSlot-click="addSlot($event)"
        @addMcr-click="addMcr($event)"
        @updateNode-click="updateNode($event)"
      />

      <!-- ADD NODE -DIALOGS -->
      <el-dialog :title="addNodeTitle" :visible.sync="showAddNodeDialog">
        <el-form ref="addNodeForm" :model="addNodeForm" label-width="120px" class="demo-dynamic">
          <el-form-item prop="title" label="Title">
            <el-input size="mini" v-model.trim="addNodeForm.title" placeholder="Add Title" />
          </el-form-item>
          <el-form-item prop="description" label="Permissions">
            <el-select
              size="mini"
              v-model="addNodeForm.description"
              style="width: 100%"
              multiple
              placeholder="Select Permissions"
            >
              <el-option
                v-for="department in formattedDepartments"
                :key="department"
                :label="department"
                :value="department"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="addNodeType === 'first'"
              type="primary"
              size="mini"
              @click="addFirstNode(addNodeForm)"
            >
              Submit
            </el-button>
            <el-button
              v-if="addNodeType === 'firstfolder'"
              type="primary"
              size="mini"
              @click="addFirstFolder(addNodeForm)"
            >
              Submit
            </el-button>
            <el-button
              size="mini"
              v-if="addNodeType === 'above'"
              type="primary"
              @click="addAboveBelowOrFolder(addFromNode, addNodeForm, 'above')"
            >
              Submit
            </el-button>
            <el-button
              v-if="addNodeType === 'below'"
              size="mini"
              type="primary"
              @click="addAboveBelowOrFolder(addFromNode, addNodeForm, 'below')"
            >
              Submit
            </el-button>
            <el-button
              v-if="addNodeType === 'child'"
              size="mini"
              type="primary"
              @click="addChild(addFromNode, addNodeForm)"
            >
              Submit
            </el-button>
            <el-button
              v-if="addNodeType === 'folder'"
              size="mini"
              type="primary"
              @click="addAboveBelowOrFolder(addFromNode, addNodeForm, 'folder')"
            >
              Submit
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- RIGHT NODE CONTENT -->
      <el-dialog @close="closeNode" :title="nodeForm.title || 'No title'" :visible="displayNode">
        <el-form
          v-if="displayNode"
          ref="nodeForm"
          :model="nodeForm"
          label-width="120px"
          class="dialog-form"
        >
          <el-row :gutter="10">
            <!-- NODE TITLE -->
            <el-form-item style="margin-bottom: 0" prop="title">
              <el-col :span="16">
                <el-input
                  size="mini"
                  v-model.trim="nodeForm.title"
                  :disabled="!authorized(nodeForm.description.join(','))"
                  @change="setEdited('title', true)"
                  @blur="updateNode(nodeForm)"
                />
              </el-col>
              <el-col :span="6">
                <el-button
                  size="mini"
                  :disabled="!authorized(nodeForm.description.join(','))"
                  @click="customizeNode()"
                  >Customize</el-button
                >
              </el-col>
            </el-form-item>

            <!-- NODE DESCRIPTION -->
            <el-form-item style="margin-bottom: 0" prop="description">
              <el-col :span="24">
                <el-select
                  v-model="nodeForm.description"
                  :disabled="!authorized(nodeForm.description.join(','))"
                  size="mini"
                  style="width: 100%"
                  multiple
                  placeholder="Select Permissions"
                  @change="
                    setEdited('description', true);
                    updateNode(nodeForm);
                  "
                >
                  <el-option
                    v-for="department in formattedDepartments"
                    :key="department"
                    :label="department"
                    :value="department"
                  />
                </el-select>
              </el-col>
            </el-form-item>

            <el-divider />

            <!-- NODE CONDITIONS -->
            <NodeConditions
              :node-form="nodeForm"
              :authorized="authorized(nodeForm.description.join(','))"
              :search-intent="searchIntent"
              @updateNode-click="updateNode($event)"
            />

            <!-- SLOTS CONDITIONS -->
            <SlotsConditions
              v-if="nodeForm.type === 'frame'"
              :node-form="nodeForm"
              :authorized="authorized(nodeForm.description.join(','))"
              :slot-nodes="slotNodes[nodeForm.dialog_node]"
              :notify-error="notifyError"
              :saved-success="savedSuccess"
              :event-nodes="eventNodes"
              :get-dialog-nodes="getDialogNodes"
              @deleteNode-click="deleteNode($event)"
              @updateNode-click="updateNode($event)"
              @addSlot-Click="addSlot($event)"
              @addWatsonDialog-click="addWatsonDialog($event)"
            />

            <!-- RESPONSE  -->
            <!-- MULTIPLE CONDITIONING RESPONSE  -->
            <Response
              :node-form="nodeForm"
              :authorized="authorized(nodeForm.description.join(','))"
              :search-content="searchContent"
              :search-intent="searchIntent"
              :response_nodes="responseNodes[nodeForm.dialog_node]"
              @updateNode-click="updateNode($event)"
              @addMcr-click="addMcr($event)"
              @deleteNode-click="deleteNode($event)"
            />
          </el-row>
        </el-form>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import _ from "lodash";
import DialogNode from "@/components/WatsonDialog/DialogNode";
import DialogCustomize from "@/components/WatsonDialog/DialogCustomize";
import NodeConditions from "@/components/WatsonDialog/NodeConditions";
import SlotsConditions from "@/components/WatsonDialog/SlotsConditions";
import Response from "@/components/WatsonDialog/Response";
import { v4 as uuid } from "uuid";
import { setTimeout } from "timers";
import { mapGetters } from "vuex";

const NODE_TYPES = ["standard", "frame", "folder"];
const LOADER_INIT = {
  target: ".loader-target",
  fullscreen: false,
  text: "Loading...",
};

// Common fields and values when creating a new dialog node. Can merge other fields to this object and create new node.
const createNodePayload = (newValues = {}) =>
  Object.assign(
    {
      conditions: null,
      output: null,
      context: null,
      next_step: null,
      event_name: null,
      digress_out_slots: null,
      user_label: null,
      variable: null,
      digress_in: null,
      digress_out: null,
      metadata: {},
      previous_sibling: null,
    },
    newValues
  );

export default {
  data() {
    return {
      old_values: null,
      addNodeTitle: "Add node",
      query: "",
      nodeForm: {
        description: [],
        conditionArray: [],
        output: {
          generic: [],
        },
        contextArray: [],
      },
      showAddNodeDialog: false,
      addNodeForm: {
        title: "",
        description: [],
      },
      addNodeType: "",
      addFromNode: {},
      currentNode: {},
      childNodes: {},
      slotNodes: {},
      eventNodes: {},
      responseNodes: {},
      visible: false,
      displayNode: false,
      dialogCustomizeVisible: false,
    };
  },
  computed: {
    ...mapGetters([
      "watsonIntents",
      "watsonDialogNodes",
      "watsonEntities",
      "watsonisEditedObject",
      "formattedDepartments",
      "userDepartment",
    ]),
    // sort dialog nodes as per tree
    dialog_nodes() {
      const self = this;
      let dialogs = [],
        flag = false,
        unprocesses = [];
      (self.childNodes = {}),
        (self.slotNodes = {}),
        (self.eventNodes = {}),
        (self.responseNodes = {});
      const watsonDialogs = _.cloneDeep(self.watsonDialogNodes);
      watsonDialogs.forEach((node) => {
        // add matched for search
        if (!node["matched"]) {
          self.$set(node, "matched", "none");
        }
        if (self.query !== "") {
          if (
            (node.conditions &&
              JSON.stringify(node.conditions).toLowerCase().includes(this.query.toLowerCase())) ||
            (node.output &&
              JSON.stringify(node.output).toLowerCase().includes(this.query.toLowerCase())) ||
            (node.title &&
              JSON.stringify(node.title).toLowerCase().includes(this.query.toLowerCase()))
          ) {
            node["matched"] = "full";
            if (node.parent) {
              let currNode = watsonDialogs.find((ele) => {
                if (ele.dialog_node === node.parent) {
                  return ele;
                }
              });
              // in case of slot and mcr, make parent matched = full
              if (
                node.type !== "standard" &&
                node.type !== "frame" &&
                node.type !== "folder" &&
                node.parent
              ) {
                self.$set(currNode, "matched", "full");
              }
              // check parent and mark matched = child
              while (currNode) {
                if (currNode["matched"] && currNode["matched"] === "none") {
                  currNode["matched"] = "child";
                } else if (!currNode["matched"]) {
                  self.$set(node, "matched", "child");
                }
                currNode = watsonDialogs.find((ele) => {
                  if (ele.dialog_node === currNode.parent) {
                    return ele;
                  }
                });
              }
            }
          }
        }
        // initialise childnodes
        if (!self.childNodes[node.dialog_node]) {
          self.childNodes[node.dialog_node] = [];
        }
        // add root level node to tree
        // condition = (node_type = standard, folder, frame && !parent)
        const isValidParent = _.includes(NODE_TYPES, node.type) && !node.parent;

        const isNormalParent = isValidParent && node.previous_sibling;
        const isFirstParent = isValidParent && !node.previous_sibling;

        if (isNormalParent) {
          dialogs.forEach((dialog, index) => {
            if (dialog.dialog_node === node.previous_sibling) {
              dialogs.splice(index + 1, 0, node);
              flag = true;
            }
          });

          if (flag) {
            flag = false;
          } else {
            unprocesses.push(node);
          }
        } else if (isFirstParent) {
          dialogs.unshift(node);
        } else if (isValidParent) {
          // Which will never happen
          dialogs.push(node);
        }

        // adding to child/slot/response/event folder
        // condition = (node_type = all && parent)
        if (node.parent) {
          if (_.includes(NODE_TYPES, node.type)) {
            if (!this.childNodes[node.parent]) {
              self.childNodes[node.parent] = [];
            }
            self.childNodes[node.parent].push(node);
          } else if (node.type === "slot") {
            if (!self.slotNodes[node.parent]) {
              self.slotNodes[node.parent] = [];
            }
            self.slotNodes[node.parent].push(node);
          } else if (node.type === "event_handler") {
            if (!self.eventNodes[node.parent]) {
              self.eventNodes[node.parent] = [];
            }
            self.eventNodes[node.parent].push(node);
          } else if (node.type === "response_condition") {
            if (!self.responseNodes[node.parent]) {
              self.responseNodes[node.parent] = [];
            }
            self.responseNodes[node.parent].push(node);
          }
        }
      });

      // sort root level tree nodes
      // condition = (node_type = standar, folder, frame && !parent)
      flag = false;
      for (let i = 0; i < unprocesses.length; i++) {
        if (_.includes(NODE_TYPES, unprocesses[i].type) && unprocesses[i].previous_sibling) {
          dialogs.forEach((dialog, index) => {
            if (dialog.dialog_node === unprocesses[i].previous_sibling) {
              dialogs.splice(index + 1, 0, unprocesses[i]);
              flag = true;
            }
          });
          flag ? (flag = false) : unprocesses.push(unprocesses[i]);
        } else if (
          _.includes(NODE_TYPES, unprocesses[i].type) &&
          !unprocesses[i].previous_sibling
        ) {
          dialogs.unshift(unprocesses[i]);
        } else if (_.includes(NODE_TYPES, unprocesses[i].type)) {
          dialogs.push(unprocesses[i]);
        }
      }

      // sort child levels
      // condition = (node_type = standar, folder, frame && parent)
      flag = false;
      for (let prop in self.childNodes) {
        let childDialogs = [];
        let nodeObj = {};
        for (let i = 0; i < self.childNodes[prop].length; i++) {
          // make first node parent  = null
          // if previous sibling points to another node_type
          if (self.responseNodes[prop]) {
            self.responseNodes[prop].forEach((res) => {
              if (res.dialog_node == self.childNodes[prop][i].previous_sibling) {
                self.childNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.slotNodes[prop]) {
            self.slotNodes[prop].forEach((res) => {
              if (res.dialog_node == self.childNodes[prop][i].previous_sibling) {
                self.childNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.eventNodes[prop]) {
            self.eventNodes[prop].forEach((res) => {
              if (res.dialog_node == self.childNodes[prop][i].previous_sibling) {
                self.childNodes[prop][i].previous_sibling = null;
              }
            });
          }
          let spliceIndex = -1;
          let len = 0;
          let dialogIndex = -1;
          if (!self.childNodes[prop][i].previous_sibling) {
            childDialogs.push(self.childNodes[prop][i]);
            flag = true;
          } else {
            for (let j = 0; j < childDialogs.length; j++) {
              if (
                self.childNodes[prop][i].previous_sibling &&
                childDialogs[j].dialog_node === self.childNodes[prop][i].previous_sibling
              ) {
                if (nodeObj[self.childNodes[prop][i].dialog_node]) {
                  len = 1;
                }
                childDialogs.splice(j + 1, 0, self.childNodes[prop][i]);
                flag = true;
                dialogIndex = j + 2;

                if (!nodeObj[childDialogs[j].dialog_node]) {
                  nodeObj[childDialogs[j].dialog_node] = [];
                }
                nodeObj[childDialogs[j].dialog_node].push(self.childNodes[prop][i].dialog_node);
              }
              if (
                dialogIndex !== j &&
                self.childNodes[prop][i].dialog_node === childDialogs[j].dialog_node
              ) {
                spliceIndex = j;
              }
            }
          }
          flag ? (flag = false) : self.childNodes[prop].push(self.childNodes[prop][i]);
        }
        self.childNodes[prop] = JSON.parse(JSON.stringify(childDialogs));
      }

      // sort slot nodes
      // condition = (node_type = slot && parent)
      flag = false;
      for (let prop in self.slotNodes) {
        let childDialogs = [];
        let nodeObj = {};
        for (let i = 0; i < self.slotNodes[prop].length; i++) {
          // make first node parent  = null
          // if previous sibling points to another node_type
          if (self.responseNodes[prop]) {
            self.responseNodes[prop].forEach((res) => {
              if (res.dialog_node == self.slotNodes[prop][i].previous_sibling) {
                self.slotNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.childNodes[prop]) {
            self.childNodes[prop].forEach((res) => {
              if (res.dialog_node == self.slotNodes[prop][i].previous_sibling) {
                self.slotNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.eventNodes[prop]) {
            self.eventNodes[prop].forEach((res) => {
              if (res.dialog_node == self.slotNodes[prop][i].previous_sibling) {
                self.slotNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.slotNodes[prop][i].previous_sibling === self.slotNodes[prop][i].parent) {
            self.slotNodes[prop][i].previous_sibling = null;
          }
          let spliceIndex = -1;
          let len = 0;
          let dialogIndex = -1;
          if (!self.slotNodes[prop][i].previous_sibling) {
            childDialogs.push(self.slotNodes[prop][i]);
            flag = true;
          } else {
            for (let j = 0; j < childDialogs.length; j++) {
              if (
                self.slotNodes[prop][i].previous_sibling &&
                childDialogs[j].dialog_node === self.slotNodes[prop][i].previous_sibling
              ) {
                if (nodeObj[self.slotNodes[prop][i].dialog_node]) {
                  len = 1;
                }
                childDialogs.splice(j + 1, 0, self.slotNodes[prop][i]);
                flag = true;
                dialogIndex = j + 2;

                if (!nodeObj[childDialogs[j].dialog_node]) {
                  nodeObj[childDialogs[j].dialog_node] = [];
                }
                nodeObj[childDialogs[j].dialog_node].push(self.slotNodes[prop][i].dialog_node);
              }
              if (
                dialogIndex !== j &&
                self.slotNodes[prop][i].dialog_node === childDialogs[j].dialog_node
              ) {
                spliceIndex = j;
              }
            }
          }
          flag ? (flag = false) : self.slotNodes[prop].push(self.slotNodes[prop][i]);
        }
        self.slotNodes[prop] = JSON.parse(JSON.stringify(childDialogs));
      }

      // sort slot nodes
      // condition = (node_type = response_condition && parent)
      flag = false;
      for (let prop in self.responseNodes) {
        let childDialogs = [];
        let nodeObj = {};
        for (let i = 0; i < self.responseNodes[prop].length; i++) {
          // make first node parent  = null
          // if previous sibling points to another node_type
          if (self.slotNodes[prop]) {
            self.slotNodes[prop].forEach((res) => {
              if (res.dialog_node == self.responseNodes[prop][i].previous_sibling) {
                self.responseNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.childNodes[prop]) {
            self.childNodes[prop].forEach((res) => {
              if (res.dialog_node == self.responseNodes[prop][i].previous_sibling) {
                self.responseNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.eventNodes[prop]) {
            self.eventNodes[prop].forEach((res) => {
              if (res.dialog_node == self.responseNodes[prop][i].previous_sibling) {
                self.responseNodes[prop][i].previous_sibling = null;
              }
            });
          }
          if (self.responseNodes[prop][i].previous_sibling === self.responseNodes[prop][i].parent) {
            self.responseNodes[prop][i].previous_sibling = null;
          }
          let spliceIndex = -1;
          let len = 0;
          let dialogIndex = -1;
          if (!self.responseNodes[prop][i].previous_sibling) {
            childDialogs.push(self.responseNodes[prop][i]);
            flag = true;
          } else {
            for (let j = 0; j < childDialogs.length; j++) {
              if (
                self.responseNodes[prop][i].previous_sibling &&
                childDialogs[j].dialog_node === self.responseNodes[prop][i].previous_sibling
              ) {
                if (nodeObj[self.responseNodes[prop][i].dialog_node]) {
                  len = 1;
                }
                childDialogs.splice(j + 1, 0, self.responseNodes[prop][i]);
                flag = true;
                dialogIndex = j + 2;

                if (!nodeObj[childDialogs[j].dialog_node]) {
                  nodeObj[childDialogs[j].dialog_node] = [];
                }
                nodeObj[childDialogs[j].dialog_node].push(self.responseNodes[prop][i].dialog_node);
              }
              if (
                dialogIndex !== j &&
                self.responseNodes[prop][i].dialog_node === childDialogs[j].dialog_node
              ) {
                spliceIndex = j;
              }
            }
          }
          flag ? (flag = false) : self.responseNodes[prop].push(self.responseNodes[prop][i]);
        }
        self.responseNodes[prop] = JSON.parse(JSON.stringify(childDialogs));
      }
      return dialogs;
    },
    // get content nodes for response autocomplete
    contentNodes() {
      const contents = [];
      Object.keys(this.$store.state.nodes.content).forEach((ele) => {
        contents.push({ value: ele });
      });
      return contents;
    },
    // get intents and enitties for conditions autocomplete
    intentList() {
      const list = [];

      for (let i = 0; i < this.watsonIntents.length; i++) {
        if (
          !this.watsonIntents[i].hasOwnProperty("description") ||
          this.watsonIntents[i].description == ""
        ) {
          this.$set(this.$store.state.watson.intents[i], "description", "General");
        }
        list.push({ value: "#" + this.watsonIntents[i].intent });
      }
      for (let i = 0; i < this.watsonEntities.length; i++) {
        if (
          !this.watsonEntities[i].hasOwnProperty("description") ||
          this.watsonEntities[i].description == ""
        ) {
          this.$set(this.$store.state.watson.entities[i], "description", "General");
        }
        list.push({ value: "@" + this.$store.state.watson.entities[i].entity });
      }
      return list;
    },
  },
  mounted() {
    this.getDialogNodes(true);
    this.$store
      .dispatch("FETCH_WATSON_MY_ENTITIES")
      .catch((err) => this.notifyError("fetching watson entities"));
  },
  methods: {
    /* GROUP - COMMON FUNCTIONS */
    searchIntent(queryString, cb) {
      const contentNodes = this.intentList;
      const results = queryString
        ? contentNodes.filter(this.createFilter(queryString))
        : contentNodes;
      // call callback function to return suggestions
      cb(results);
    },
    searchContent(queryString, cb) {
      var contentNodes = this.contentNodes;
      var results = queryString
        ? contentNodes.filter(this.createFilter(queryString))
        : contentNodes;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return (link) => link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    goToEditor(val) {
      let id = val;
      this.$store.dispatch("SELECT_NODE", { type: "content", id });
      this.$router.push("/editor");
    },
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
      });
    },
    savedSuccess() {
      this.$message({
        message: "Saved",
        type: "success",
      });
    },
    addWatsonDialog(payload, loader = null) {
      const self = this;
      this.$store
        .dispatch("ADD_WATSON_DIALOG_NODE", { payload })
        .then(() => {
          if (loader) {
            loader.close();
          }
          self.savedSuccess();
          self.getDialogNodes();
        })
        .catch((e) => this.notifyError("Encountered error adding watson dialog node"));
    },
    addFirstFolder(form) {
      if (this.dialog_nodes.length == 0) {
        if (form.description.length === 0) {
          form.description = ["General"];
        } else if (form.description[0] === "General") {
          form.description.splice(0, 1);
        }
        this.showAddNodeDialog = false;
        let loader = this.$loading(LOADER_INIT);
        const payload = createNodePayload({
          description: form.description.join(","),
          title: form.title,
          type: "folder",
          dialog_node: "node_" + uuid(),
          parent: null,
        });
        this.addWatsonDialog(payload, loader);
      } else {
        this.addAboveBelowOrFolder(this.dialog_nodes[this.dialog_nodes.length - 2], form, "folder");
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    authorized(description = "") {
      return (
        description.includes("General") ||
        _.intersection(this.userDepartment, description.toLowerCase().split(",")).length > 0
      );
    },
    addFirstNode(form) {
      if (this.dialog_nodes.length == 0) {
        if (form.description.length === 0) {
          form.description = ["General"];
        } else if (form.description[0] === "General") {
          form.description.splice(0, 1);
        }
        this.showAddNodeDialog = false;
        const loader = this.$loading(LOADER_INIT);
        const payload = createNodePayload({
          description: form.description.join(","),
          title: form.title,
          type: "standard",
          dialog_node: "node_" + uuid(),
          parent: null, // No parent for first node
        });
        this.addWatsonDialog(payload, loader);
      } else {
        this.addAboveBelowOrFolder(this.dialog_nodes[this.dialog_nodes.length - 1], form, "above");
      }
    },
    getDialogNodes(loader = false) {
      const self = this;
      let loder = null;
      if (loader) {
        loder = self.$loading(LOADER_INIT);
      }
      self.$store
        .dispatch("FETCH_WATSON_DIALOG", { sort: "depth_first" })
        .then(() => {
          if (loader) {
            loder.close();
          }
          if (self.displayNode) {
            self.watsonDialogNodes.forEach((node) => {
              if (node.dialog_node == self.nodeForm.dialog_node) {
                self.nodeClick(node);
              }
            });
          }
        })
        .catch((e) => this.notifyError("Encountered error fetching dialog nodes"));
    },
    closeNode() {
      this.displayNode = false;
    },

    /* GROUP - CUSTOMIZE NODE */
    closeCustomDialog() {
      this.dialogCustomizeVisible = false;
    },
    customizeNode() {
      this.dialogCustomizeVisible = true;
    },

    /* GROUP - TREE NODE OPERATIONS */
    addChild(node, form) {
      this.showAddNodeDialog = false;
      const loader = this.$loading(LOADER_INIT);
      if (form.description.length === 0) {
        form.description = ["General"];
      } else if (form.description[0] === "General") {
        form.description.splice(0, 1);
      }
      let sibling = null;
      if (this.childNodes[node.dialog_node] && this.childNodes[node.dialog_node].length > 0) {
        sibling =
          this.childNodes[node.dialog_node][this.childNodes[node.dialog_node].length - 1]
            .dialog_node;
      }

      const payload = createNodePayload({
        description: form.description.join(","),
        type: "standard",
        dialog_node: "node_" + uuid(),
        parent: node.dialog_node,
        parent_title: node.title && node.title.trim(),
        previous_sibling: sibling,
        title: form.title && form.title.trim(),
      });
      this.addWatsonDialog(payload, loader);
    },
    addAboveBelowOrFolder(node, form, addType) {
      // addType can be "above", "below" or "folder"
      const self = this;
      this.showAddNodeDialog = false;
      const loader = this.$loading(LOADER_INIT);
      if (form.description.length === 0) {
        form.description = ["General"];
      } else if (form.description[0] === "General") {
        form.description.splice(0, 1);
      }

      if (node.parent) {
        self.watsonDialogNodes.forEach((dialog) => {
          if (dialog.dialog_node == node.parent) {
            Object.assign(node, { parent_title: dialog.title });
          }
        });
      } else {
        Object.assign(node, { parent_title: null });
      }

      const type = addType === "folder" ? "folder" : "standard";
      const previous_sibling = addType !== "above" ? node.dialog_node : node.previous_sibling;

      const payload = createNodePayload({
        description: form.description.join(","),
        title: form.title,
        type,
        dialog_node: "node_" + uuid(),
        parent: node.parent || null,
        parent_title: node.parent_title,
        previous_sibling,
      });
      this.addWatsonDialog(payload, loader);
    },
    deleteNode(node, index) {
      const self = this;
      const loder = this.$loading(LOADER_INIT);
      if (node.parent) {
        self.watsonDialogNodes.forEach((dialog) => {
          if (dialog.dialog_node == node.parent) {
            Object.assign(node, { parent_title: dialog.title });
          }
        });
      } else {
        Object.assign(node, { parent_title: null });
      }

      let contextArray = [];
      if (node.context && Object.keys(node.context).length > 0) {
        Object.keys(node.context).forEach((ele) => {
          contextArray.push({
            key: ele,
            value: node.context[ele],
          });
        });
      }
      Object.assign(node, { contextArray: contextArray });

      let conditionArray = [];
      if (
        this.nodeForm.conditions &&
        (this.nodeForm.conditions.includes("&") || this.nodeForm.conditions.includes("|"))
      ) {
        let tmp = "",
          j = 0;
        for (let i = 0; i < this.nodeForm.conditions.length; i++) {
          if (
            (this.nodeForm.conditions[i] === "&" && this.nodeForm.conditions[i + 1] === "&") ||
            (this.nodeForm.conditions[i] === "|" && this.nodeForm.conditions[i + 1] === "|")
          ) {
            if (conditionArray.length === 0) {
              conditionArray.push({
                text: tmp.trim(),
                condition: null,
              });
            } else {
              conditionArray[j].text = tmp.trim();
            }
            j++;
            conditionArray.push({
              text: "",
              condition: this.nodeForm.conditions[i] + this.nodeForm.conditions[i],
            });
            tmp = "";
            i++;
            i++;
          }
          tmp += this.nodeForm.conditions[i];
        }
        conditionArray[j].text = tmp;
      } else if (this.nodeForm.conditions) {
        conditionArray.push({
          text: this.nodeForm.conditions,
          condition: null,
        });
      } else {
        // FIXME: should be removed?
        conditionArray.push({
          text: "",
          condition: null,
        });
      }
      Object.assign(node, { conditionArray: conditionArray });

      if (!_.includes(NODE_TYPES, node.type)) {
        node.title = self.nodeForm.title;
        if (node.type === "response_condition") {
          node["mcr_number"] = index + 1;
        } else {
          node["slot_number"] = index + 1;
        }
      }

      const payload = {
        old_values: node,
        dialog_node: node.dialog_node,
      };
      this.$store
        .dispatch("DELETE_WATSON_DIALOG_NODE", { payload })
        .then(() => {
          loder.close();
          self.$message({
            message: "Saved",
            type: "success",
          });
          self.getDialogNodes();
        })
        .catch((e) => {
          self.$notify.error({
            title: "Error",
            message: "Encountered error deleting watson dialog node",
          });
        });
    },
    // load right pane on left node click
    nodeClick(node) {
      const self = this;
      this.nodeForm = JSON.parse(JSON.stringify(node));
      // set description
      this.nodeForm.description = this.nodeForm.description
        ? this.nodeForm.description.split(",")
        : ["General"];
      this.currentNode = node;

      // set conditions to view format as conditionArray
      let conditionArray = [];
      if (
        this.nodeForm.conditions &&
        (this.nodeForm.conditions.includes("&") || this.nodeForm.conditions.includes("|"))
      ) {
        let tmp = "",
          j = 0;
        for (let i = 0; i < this.nodeForm.conditions.length; i++) {
          if (
            (this.nodeForm.conditions[i] === "&" && this.nodeForm.conditions[i + 1] === "&") ||
            (this.nodeForm.conditions[i] === "|" && this.nodeForm.conditions[i + 1] === "|")
          ) {
            if (conditionArray.length === 0) {
              conditionArray.push({
                text: tmp.trim(),
                condition: null,
              });
            } else {
              conditionArray[j].text = tmp.trim();
            }
            j++;
            conditionArray.push({
              text: "",
              condition: this.nodeForm.conditions[i] + this.nodeForm.conditions[i],
            });
            tmp = "";
            i++;
            i++;
          }
          tmp += this.nodeForm.conditions[i];
        }
        conditionArray[j].text = tmp;
      } else if (this.nodeForm.conditions) {
        conditionArray.push({
          text: this.nodeForm.conditions,
          condition: null,
        });
      } else {
        conditionArray.push({
          text: "",
          condition: null,
        });
      }

      // set response to view format as responseArray
      let genericArray = [];
      if (
        self.nodeForm.metadata &&
        self.nodeForm.metadata._customization &&
        self.nodeForm.metadata._customization.mcr
      ) {
        // handle mcr
        genericArray = [
          {
            response_type: "text",
            values: [
              {
                text: "",
              },
            ],
          },
        ];
        if (self.responseNodes[self.nodeForm.dialog_node]) {
          self.responseNodes[self.nodeForm.dialog_node].forEach((res) => {
            const outputTextsExist = _.get(res, "output.text.values.length", undefined) >= 0;

            if (res.output && !res.output.generic && !res.output.text) {
              self.$set(res, "output", { generic: _.clone(genericArray) });
            } else if (outputTextsExist) {
              const resObj = [
                {
                  response_type: "text",
                  values: [],
                },
              ];
              if (res.output.text.values.length === 0) {
                resObj[0].values.push({ text: "" });
              } else {
                res.output.text.values.forEach((val) => {
                  resObj[0].values.push({ text: val });
                });
              }
              self.$set(res, "output", { generic: resObj });
            }
            if (!res.conditions) {
              self.$set(res, "conditions", "");
            } else {
              self.$set(res, "conditions", res.conditions);
            }
          });
        } else {
          // self.$set(res, "output", { generic: genericArray });
        }
      } else {
        // handle response
        const genericArrayExists =
          this.nodeForm.output &&
          this.nodeForm.output.generic &&
          this.nodeForm.output.generic.length > 0;

        const outputTextsExist =
          this.nodeForm.output &&
          this.nodeForm.output.text &&
          this.nodeForm.output.text.values &&
          this.nodeForm.output.text.values.length > 0;

        if (genericArrayExists) {
          genericArray = this.nodeForm.output.generic;
          genericArray.forEach((ele) => {
            if (
              ele.values.length === 0 ||
              (ele.response_type === "text" && _.last(ele.values).text !== "")
            ) {
              ele.values.push({
                text: "",
              });
            } else if (ele.response_type == "option") {
              ele.values.push({
                option: "",
              });
            }
            ele.values.forEach((val) => {
              val["content"] = null;
              for (let con in self.$store.state.nodes.content) {
                if (con === val.text) {
                  val["content"] = self.$store.state.nodes.content[con].content;
                }
              }
            });
          });
        } else if (outputTextsExist) {
          genericArray = [
            {
              response_type: "text",
              values: [],
            },
          ];
          this.nodeForm.output.text.values.forEach((val) => {
            genericArray[0].values.push({ text: val });
          });
          genericArray.forEach((ele) => {
            if (
              ele.values.length === 0 ||
              (ele.response_type === "text" && ele.values[ele.values.length - 1].text !== "")
            ) {
              ele.values.push({
                text: "",
              });
            } else if (ele.response_type == "option") {
              ele.values.push({
                option: "",
              });
            }
            ele.values.forEach((val) => {
              val["content"] = null;
              for (let con in self.$store.state.nodes.content) {
                if (con === val.text) {
                  val["content"] = self.$store.state.nodes.content[con].content;
                }
              }
            });
          });
        }
      }

      // next_step settings
      if (this.nodeForm.next_step) {
        this.$set(this.nodeForm, "behavior", this.nodeForm.next_step.behavior);
      } else {
        this.$set(this.nodeForm, "behavior", "wait_for_user_input");
      }

      // intialise vue observable objeects
      this.$set(this.nodeForm, "conditionArray", conditionArray);
      this.$set(this.nodeForm, "output", { generic: genericArray });
      this.old_values = JSON.parse(JSON.stringify(this.nodeForm));
      this.displayNode = true;
    },
    openAddNodeDialog(type, node) {
      this.addNodeType = type;
      if (type.includes("folder")) {
        this.addNodeTitle = "Add folder node";
      } else if (type.includes("child")) {
        this.addNodeTitle = "Add child node";
      } else {
        this.addNodeTitle = "Add node";
      }
      this.showAddNodeDialog = true;
      this.addFromNode = node;
    },

    /* GROUP - NEXT STEP */
    updateNextStep(node) {
      // conditional
      this.updateNode(node);
    },
    setEdited(nodeVariableType, isEdited) {
      this.$set(this.$store.state.watson, "isEditedObject", {
        [nodeVariableType]: isEdited,
      });
    },

    /* GROUP - UPDATE NODE CALL COMMON FOR ALL */
    updateNode(node, type = null, loader = null) {
      // timeout to update for autocomplete
      setTimeout(() => {
        const self = this;
        // set title as parent in case of
        // node_type = (slot, response_condition, event_handler)
        if (node.parent) {
          self.watsonDialogNodes.forEach((dialog) => {
            if (dialog.dialog_node == node.parent && dialog.title) {
              Object.assign(node, { parent_title: dialog.title });
              Object.assign(self.old_values, {
                parent_title: dialog.title,
              });
            }
          });
        } else {
          Object.assign(node, { parent_title: null });
          Object.assign(self.old_values, { parent_title: null });
        }
        // handle response to updated
        // node_type = (slot, response_condition, event_handler)
        let nodeOutput;
        const onlyOneGeneric =
          node.output && node.output.generic && node.output.generic.length == 1;
        const moreThanOneGeneric =
          node.output && node.output.generic && node.output.generic.length > 1;
        if (onlyOneGeneric) {
          nodeOutput = {
            [node.output.generic[0].response_type]: {
              values: [],
            },
          };

          // will be generated by the Watson API, do not check for undefined.
          if (_.get(node, "output.generic[0].values")) {
            node.output.generic[0].values.forEach((val, index) => {
              const textEmpty = !val.text;
              if (textEmpty) {
                return;
              }
              nodeOutput.text.values.push(val.text);
            });
          }
        } else if (moreThanOneGeneric) {
          nodeOutput = _.cloneDeep(node.output);
          nodeOutput.generic.forEach((out) => {
            out.values.forEach((val, index) => {
              if (val.text === "" && index === out.values.length - 1) {
                out.values.splice(index, 1);
              }
            });
          });
        }

        let payload = {
          conditions: node.conditions || null,
          description: node.description,
          output: nodeOutput || null,
          context: node.context || null,
          title: (node.title && node.title.trim()) || null,
          next_step: node.next_step || null,
          event_name: node.event_name || null,
          digress_out_slots: node.digress_out_slots || null,
          user_label: node.user_label || null,
          variable: node.variable || null,
          digress_in: node.digress_in || null,
          digress_out: node.digress_out || null,
          type: type || node.type,
          dialog_node: node.dialog_node,
          metadata: node.metadata,
          parent: node.parent || null,
          parent_title: node.parent_title,
          previous_sibling: node.previous_sibling || null,
        };
        // adding old values for make-checker
        const needToIterateInVariable = !_.includes(NODE_TYPES, node.type);
        if (needToIterateInVariable) {
          node.description = self.nodeForm.description;
          self.watsonDialogNodes.forEach((dialog, index) => {
            if (dialog.dialog_node == node.dialog_node) {
              payload["old_values"] = dialog;
            }
          });

          if (node.type === "response_condition") {
            self.responseNodes[node.parent].forEach((dialog, index) => {
              if (dialog.dialog_node === node.dialog_node) {
                payload["mcr_number"] = index + 1;
                payload["old_values"].mcr_number = index + 1;
              }
            });
          }
          payload.old_values.description = payload.description;

          Object.assign(payload, {
            parent_title: null,
            title: self.nodeForm.title,
          });
          Object.assign(payload.old_values, {
            parent_title: null,
            title: self.nodeForm.title,
          });
        } else {
          payload["old_values"] = this.old_values;
        }

        // description array handling
        const noDescriptions = node.description.length === 0;
        const manyDescriptions = node.description && node.description.length > 1;
        const firstDescriptionIsGeneral = node.description[0] === "General";

        if (noDescriptions) {
          node.description = ["General"];
        } else if (manyDescriptions && firstDescriptionIsGeneral) {
          node.description.splice(0, 1);
        }

        // Stringify description
        payload.description = node.description.join(",");

        // payload.old_values.description = payload.description;
        if (node.behavior === "skip_user_input") {
          payload.next_step = { behavior: node.behavior };
        } else if (node.behavior === "jump_to") {
          payload.next_step = {
            behavior: node.behavior,
            selector: node.next_step.selector,
            dialog_node: node.next_step.dialog_node,
          };
        } else {
          payload.next_step = null;
        }

        // condition array to conditions
        if (node.conditionArray) {
          payload.conditions = "";
          node.conditionArray.forEach(function (cond, index) {
            if (index === 0 && cond.text !== "") {
              payload.conditions = cond.text + " ";
            } else if (cond.text !== "") {
              payload.conditions += cond.condition + cond.text + " ";
            } else {
              node.conditionArray.splice(index, 1);
            }
          });
          payload.conditionArray = node.conditionArray;
          payload.conditions = payload.conditions.trim();
        }

        // context array to context
        if (node.contextArray) {
          payload.context = {};
          node.contextArray.forEach(function (cont) {
            if (cont.key !== "") {
              payload.context[cont.key] = cont.value;
            }
          });
          payload.contextArray = node.contextArray;
          if (node.type === "folder") {
            payload.output = null;
            payload.context = null;
          }
        }

        const nothingChanged = Object.values(this.watsonisEditedObject).length === 0;
        if (nothingChanged) {
          this.$notify.info({
            title: "No Change Detected",
            message: "No update to watson is performed",
            position: "bottom-right",
          });
          return;
        }

        this.$store
          .dispatch("UPDATE_WATSON_DIALOG_NODE", { payload })
          .then(() => {
            this.$set(this.$store.state.watson, "isEditedObject", {});
            this.savedSuccess();
            this.getDialogNodes(loader);
          })
          .catch((e) => this.notifyError("updating watson dialog node"));
      }, 300);
    },

    /* GROUP - SLOT */
    addSlot(node) {
      const self = this;
      let slot_number = 1;
      const loder = this.$loading(LOADER_INIT);
      let sibling = null;
      if (this.slotNodes[node.dialog_node] && this.slotNodes[node.dialog_node].length > 0) {
        sibling =
          this.slotNodes[node.dialog_node][this.slotNodes[node.dialog_node].length - 1].dialog_node;
        slot_number = this.slotNodes[node.dialog_node].length + 1;
      }
      const slot = "slot_" + uuid();
      let payload = {
        description: self.nodeForm.description.join(","),
        title: self.nodeForm.title,
        type: "slot",
        slot_number: slot_number,
        dialog_node: slot,
        metadata: {},
        output: {},
        parent: node.dialog_node,
        parent_title: node.title && node.title.trim(),
        previous_sibling: sibling,
      };
      self.$store
        .dispatch("ADD_WATSON_DIALOG_NODE", { payload })
        .then(() => {
          const handler = "handler_" + uuid();
          payload = {
            description: self.nodeForm.description.join(","),
            title: self.nodeForm.title,
            slot_number: slot_number,
            type: "event_handler",
            dialog_node: handler,
            event_name: "input",
            metadata: {},
            parent: slot,
            parent_title: null,
            previous_sibling: null,
          };
          self.$store
            .dispatch("ADD_WATSON_DIALOG_NODE", { payload })
            .then(() => {
              payload = {
                description: self.nodeForm.description.join(","),
                title: self.nodeForm.title,
                slot_number: slot_number,
                type: "event_handler",
                event_name: "focus",
                dialog_node: "handler_" + uuid(),
                metadata: {},
                output: {},
                parent: slot,
                parent_title: null,
                previous_sibling: handler,
              };
              self.$store
                .dispatch("ADD_WATSON_DIALOG_NODE", { payload })
                .then(() => {
                  loder.close();
                  self.$message({
                    message: "Saved",
                    type: "success",
                  });
                  self.getDialogNodes();
                })
                .catch((e) => this.notifyError("adding watson dialog node"));
            })
            .catch((e) => this.notifyError("adding watson dialog node"));
        })
        .catch((e) => this.notifyError("adding watson dialog node"));
    },

    /* GROUP - MCR */
    addMcr(node, output) {
      const loader = this.$loading(LOADER_INIT);
      if (node.description.length === 0) {
        node.description = ["General"];
      } else if (
        node.description &&
        node.description.length > 1 &&
        node.description[0] === "General"
      ) {
        node.description.splice(0, 1);
      }
      let sibling = null;
      let mcr_number = 1;
      if (this.responseNodes[node.dialog_node] && this.responseNodes[node.dialog_node].length > 0) {
        sibling =
          this.responseNodes[node.dialog_node][this.responseNodes[node.dialog_node].length - 1]
            .dialog_node;
        mcr_number = this.responseNodes[node.dialog_node].length + 1;
      }

      const payload = createNodePayload({
        title: node.title,
        description: node.description.join(","),
        output: output || {},
        mcr_number: mcr_number,
        type: "response_condition",
        dialog_node: "node_" + uuid(),
        parent: node.dialog_node,
        parent_title: null,
        previous_sibling: sibling,
      });
      this.addWatsonDialog(payload, loader);
    },
  },
  components: {
    DialogNode,
    DialogCustomize,
    NodeConditions,
    SlotsConditions,
    Response,
  },
};
</script>
<style scoped>
>>> .el-tree-node__content {
  height: auto;
  padding: 5px 0 5px 0;
}

>>> .node-left-container {
  display: flex;
  width: 36px;
  position: relative;
}

>>> .border-right {
  border-right: 1px solid #ddd;
}

>>> .node-left {
  text-align: center;
  position: relative;
  display: inline-block;
  align-self: center;
  width: 100%;
}

>>> .node-item {
  display: inline-block;
  width: 75%;
  max-width: 280px;
  padding: 10px 20px;
}

>>> .node-item p {
  margin: 0;
}

>>> .node-item span {
  height: 10px;
}

>>> .node-right {
  position: relative;
  display: inline-block;
  align-self: center;
}

>>> .node-right i {
  font-size: 24px;
  transform: rotate(90deg);
}

>>> .time {
  font-size: 12px;
  color: #aaa;
}

>>> .dialog-content {
  overflow: auto;
}

>>> .node-tree {
  overflow: auto;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  padding-left: 40px;
}
>>> .node-tree .el-card {
  /* width: 100%; */
  width: 300px;
  margin-bottom: 15px;
}

>>> .node-tree .el-card__body {
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 0;
}

>>> .dialog-form .el-button.is-circle {
  padding: 3px;
}

>>> .dialog-form {
  padding: 40px 60px 20px 60px;
}
>>> .dialog-form .el-form-item__content {
  margin-left: 0 !important;
}

>>> .popover-item {
  padding: 8px 5px;
  font-size: 16px;
}

>>> .customize-dialog {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}

>>> .box-card {
  border-color: #e4392b;
}

>>> .response-container {
  padding: 10px 15px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

>>> .node-sub-heading {
  font-size: 18px;
  border-top: 3px solid #ddd;
  padding: 25px 0;
}

>>> .dialog-heading {
  font-size: 20px;
}

>>> .response-container .bubble-container {
  display: inline-block;
}
>>> .response-container .el-autocomplete,
.mcr-container .el-autocomplete {
  width: 100%;
}

>>> .mcr-autocomplete {
  width: 280px !important;
}

>>> .custom-dialog .el-dialog__body {
  margin-right: 0;
}
</style>
