<template>
  <section>
    <el-row type="flex" justify="space-between" style="flex-flow: row wrap">
      <div>
        <el-button
          type="primary"
          round
          plain
          size="mini"
          icon="el-icon-plus"
          @click="
            dialogMyEntityVisible = true;
            dialogtext = 'Add Entity';
            addValue = 'Add';
          "
          >Add Entity</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-delete"
          round
          size="mini"
          :disabled="multipleSelectionOfMyEntities.length <= 0"
          @click="deleteMyEntity"
          >Delete</el-button
        >
      </div>

      <el-input
        v-model="query"
        size="mini"
        style="max-width: 350px"
        placeholder="Search for entity.."
        prefix-icon="el-icon-search"
      />
    </el-row>
    <el-table
      v-loading="loading"
      element-loading-text="Loading..."
      :data="myEntitiesList"
      size="mini"
      style="min-height: 50vh; overflow-y: auto"
      @selection-change="handleSelectionChangeOfMyEntities"
    >
      <el-table-column :selectable="entitySelectable" type="selection" min-width="55" />
      <el-table-column label="Entity">
        <template slot-scope="scope">
          <label class="my_entity_name" @click="editEntity(scope.row)"
            >@{{ scope.row.entity }}</label
          >
        </template>
      </el-table-column>
      <el-table-column label="Values">
        <template slot-scope="scope" show-overflow-tooltip="true">
          <label>{{ getMyEntityValues(scope.row) }}</label>
        </template>
      </el-table-column>
      <el-table-column label="Permissions">
        <template slot-scope="scope">
          <label>{{ scope.row.description }}</label>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Modified">
            <template slot-scope="scope">
              <timeago :datetime="scope.row.updated" :auto-update="60"></timeago>
            </template>
        </el-table-column>-->
    </el-table>

    <!-- entity delete cofirm popup -->
    <el-dialog title="Tips" :visible.sync="dialogDeleteMyEntityVisible" width="30%">
      <span>Do you want to delete entity?</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogDeleteMyEntityVisible = false">Cancel</el-button>
        <el-button size="mini" type="primary" @click="deleteMyIntityConfirm">Confirm</el-button>
      </span>
    </el-dialog>

    <!-- example add entity popup -->
    <el-dialog
      :title="dialogtext"
      custom-class="loader-intent-add-edit"
      :visible.sync="dialogMyEntityVisible"
      @close="addEditDialogClose"
    >
      <el-row v-if="addedEntityName == ''">
        <el-col>
          <el-form
            ref="addEntityForm"
            :rules="rules2"
            :model="addEntityForm"
            label-width="120px"
            class="demo-dynamic"
          >
            <el-form-item prop="entityName" label="Entity">
              <el-input
                size="mini"
                v-model="addEntityForm.entityName"
                placeholder="Add Entity name"
              />
            </el-form-item>
            <el-form-item prop="description" label="Permissions">
              <el-select
                v-model="addEntityForm.description"
                style="width: 100%"
                size="mini"
                multiple
                filterable
                placeholder="Select Permissions"
              >
                <!-- <el-option key="general" label="General" value="General"></el-option> -->
                <el-option
                  v-for="department in formattedDepartments"
                  :key="department"
                  :label="department"
                  :value="department"
                >
                  <span style="float: left">{{ department }}</span>
                  <span style="float: right; width: 20px" />
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="mini" type="primary" @click="submitForm('addEntityForm')">
                {{ dialogtext }}
              </el-button>
              <!-- <el-button @click="resetForm('addEntityForm')">Reset</el-button> -->
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row v-if="addedEntityName != ''">
        <el-col>
          <el-form
            ref="addEntityForm"
            :rules="rules2"
            :model="addEntityForm"
            label-width="120px"
            class="demo-dynamic"
          >
            <el-form-item prop="entityName" label="Entity">
              <el-input
                v-model="addEntityForm.entityName"
                :disabled="!authorized(updateEntityDescription)"
                size="mini"
                placeholder="Update Entity name"
                @blur="updateEntity('addEntityForm')"
              />
            </el-form-item>
            <el-form-item prop="description" label="Permissions">
              <el-select
                v-model="addEntityForm.description"
                style="width: 100%"
                multiple
                filterable
                size="mini"
                :disabled="!authorized(updateEntityDescription)"
                placeholder="Select Permissions"
                @change="updateEntity('addEntityForm')"
              >
                <!-- <el-option key="general" label="General" value="General"></el-option> -->
                <el-option
                  v-for="department in formattedDepartments"
                  :key="department"
                  :label="department"
                  :value="department"
                >
                  <span style="float: left">{{ department }}</span>
                  <span style="float: right; width: 20px" />
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <el-divider />
          <el-form
            ref="dynamicValidateForm"
            :inline="true"
            :model="dynamicValidateForm"
            label-width="120px"
            class="demo-dynamic"
          >
            <el-row>
              <el-form-item
                prop="value"
                label="Value"
                :rules="[
                  {
                    required: true,
                    message: 'Please input value',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  size="mini"
                  v-model="dynamicValidateForm.value"
                  :disabled="!authorized(updateEntityDescription)"
                />
              </el-form-item>
              <el-form-item>
                <el-dropdown trigger="click" @command="switchValueType">
                  <span class="el-dropdown-link">
                    {{ dynamicValidateForm.entityValueType }}
                    <i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu
                    slot="dropdown"
                    :disabled="!authorized(updateEntityDescription)"
                  >
                    <el-dropdown-item command="synonyms">Synonyms</el-dropdown-item>
                    <el-dropdown-item command="patterns">Patterns</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-form-item>
              <template v-if="dynamicValidateForm.entityValueType === 'synonyms'">
                <el-form-item
                  v-for="(domain, index) in dynamicValidateForm.domains"
                  :key="domain.key"
                  :label="'Synonym ' + (index + 1)"
                  :prop="'domains.' + index + '.value'"
                >
                  <el-input
                    v-model="domain.value"
                    :disabled="!authorized(updateEntityDescription)"
                    size="mini"
                    style="vertical-align: middle"
                  >
                    <el-button
                      slot="append"
                      size="mini"
                      :disabled="!authorized(updateEntityDescription)"
                      @click.prevent="removeDomain(domain)"
                    >
                      <i class="el-icon-delete" />
                    </el-button>
                    <el-button slot="append" size="mini" @click="addDomain">
                      <i class="el-icon-plus" />
                    </el-button>
                  </el-input>
                </el-form-item>
              </template>
              <template v-if="dynamicValidateForm.entityValueType == 'patterns'">
                <el-form-item
                  v-for="(domain, index) in dynamicValidateForm.domains"
                  :key="domain.key"
                  :label="'Pattern ' + (index + 1)"
                  :prop="'domains.' + index + '.value'"
                >
                  <el-input v-model="domain.value" :disabled="!authorized(updateEntityDescription)">
                    <el-button
                      slot="append"
                      size="mini"
                      :disabled="!authorized(updateEntityDescription)"
                      @click.prevent="removeDomain(domain)"
                    >
                      <i class="el-icon-delete" />
                    </el-button>
                    <el-button
                      slot="append"
                      size="mini"
                      :disabled="!authorized(updateEntityDescription)"
                      @click="addDomain"
                    >
                      <i class="el-icon-plus" />
                    </el-button>
                  </el-input>
                </el-form-item>
              </template>
            </el-row>
            <el-form-item class="margin-inline-form">
              <el-button
                size="mini"
                type="primary"
                :disabled="dynamicValidateForm.value == '' || !authorized(updateEntityDescription)"
                @click="addValueForm('dynamicValidateForm')"
                >{{ addValue }} Value</el-button
              >
              <el-button
                size="mini"
                type="default"
                :disabled="dynamicValidateForm.value == '' || !authorized(updateEntityDescription)"
                @click="resetValueForm('dynamicValidateForm')"
                >Reset</el-button
              >
              <!-- <el-button v-if="addValue === 'Edit'" type="primary" :disabled="dynamicValidateForm.value == '' || !authorized(updateEntityDescription)" @click="deleteValueForm('dynamicValidateForm')"> <i class="el-icon-delete"></i> Delete</el-button> -->
            </el-form-item>
          </el-form>
          <el-row class="values-list">
            <el-col>
              <el-button
                v-if="multipleSelectionOfValues.length > 0"
                type="primary"
                icon="el-icon-delete"
                circle
                @click="dialogDeleteValueVisible = true"
              />
              <el-table
                :data="valuesList"
                class="edit-values-loader"
                height="350"
                size="mini"
                style="width: 100%"
                @row-click="loadValue"
                @selection-change="handleSelectionValues"
              >
                <el-table-column type="selection" min-width="20px" :selectable="valuesSelectable" />
                <el-table-column prop="value" min-width="40px" label="Entity values" />
                <el-table-column prop="type" min-width="30px" label="Entity Type" />
                <el-table-column label="List">
                  <template slot-scope="scope">{{ getSynonymsOrPatterns(scope.row) }}</template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="Tips" :visible.sync="dialogDeleteValueVisible" width="30%">
      <span>Do you want to delete values?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDeleteValueVisible = false">Cancel</el-button>
        <el-button type="primary" @click="deleteValueConfirm">Confirm</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    var checkEntityName = (rule, value, callback) => {
      let entityNamesArr = [...new Set(this.myEntitiesList.map((a) => a.entity))];
      if (value === "") {
        return callback(new Error("Please input entity name"));
      } else if (entityNamesArr.includes(value) && value !== this.updateEntityName) {
        callback(new Error("Entity already exists, please enter another name"));
      } else {
        callback();
      }
    };
    return {
      query: "",
      multipleSelectionOfMyEntities: [],
      dialogMyEntityVisible: false,
      loading: true,
      dialogDeleteMyEntityVisible: false,
      dialogDeleteValueVisible: false,
      dialogtext: "Add Entity",
      addEntityForm: {
        entityName: "",
        description: [],
      },
      rules2: {
        entityName: [{ validator: checkEntityName, trigger: "change" }],
      },
      addedEntityName: "",
      updateEntityName: "",
      updateEntityValue: "",
      updateEntityDescription: "",
      multipleSelectionOfValues: [],
      addValue: "Add",
      dynamicValidateForm: {
        domains: [
          {
            key: 1,
            value: "",
          },
        ],
        value: "",
        entityValueType: "synonyms",
      },
      valuesList: [],
    };
  },
  computed: {
    ...mapGetters(["watsonEntities", "formattedDepartments", "userDepartment"]),
    myEntitiesList() {
      let list = [];
      for (let i = 0; i < this.watsonEntities.length; i++) {
        if (
          !this.watsonEntities[i].hasOwnProperty("description") ||
          this.watsonEntities[i].description == ""
        ) {
          this.$set(this.$store.state.watson.entities[i], "description", "General");
        }

        if (this.query === "") {
          list.push(this.watsonEntities[i]);
        } else {
          if (
            JSON.stringify(this.watsonEntities[i]).toLowerCase().includes(this.query.toLowerCase())
          ) {
            list.push(this.watsonEntities[i]);
          }
        }
      }
      return list.filter((v, i) => {
        if (v.entity.indexOf("sys") === -1) {
          return v;
        }
      });
    },
  },
  mounted() {
    this.getMyEntities();
  },
  methods: {
    notifyError(message) {
      this.$notify.error({
        title: "Error",
        message,
      });
    },
    entitySelectable(row, index) {
      return this.authorized(row.description);
    },
    valuesSelectable(row, index) {
      return this.authorized(this.updateEntityDescription);
    },
    handleSelectionValues(val) {
      this.multipleSelectionOfValues = val;
    },
    deleteValueConfirm() {
      const formatOldValues = () => {
        let finalVersion = {
          value_names: [],
          types: [],
          synonyms: [],
          patterns: [],
        };
        this.multipleSelectionOfValues.map((value) => {
          finalVersion.value_names.push(value.value);
          finalVersion.types.push(value.type);
          finalVersion.synonyms.push(value.synonyms);
          finalVersion.patterns.push(value.patterns);
        });
        return finalVersion;
      };
      const self = this;
      this.dialogDeleteValueVisible = false;
      let currentEntity = _.find(this.watsonEntities, {
        entity: this.addedEntityName,
      });

      const payload = {
        old_values: {
          // TODO: @JOSH Check if pointing correctly
          ..._.assign(currentEntity, {
            values: this.multipleSelectionOfValues,
          }),
          entity: this.addedEntityName,
          description: currentEntity && currentEntity.description,
        },
        new_values: {
          entity: this.addedEntityName,
          values: this.multipleSelectionOfValues,
        },
      };
      var loader = this.$loading({
        target: ".edit-values-loader",
        fullscreen: false,
        text: "Loading...",
      });
      this.$store
        .dispatch("DELETE_WATSON_VALUE", payload)
        .then((response) => {
          loader.close();
          self.$message({
            message: "Saved",
            type: "success",
          });
          self.getMyEntities({ loading: false, loading2: true }).then(() => {
            self.myEntitiesList.forEach((ele) => {
              if (ele.entity == self.addedEntityName) {
                self.editEntity(ele);
              }
            });
          });
        })
        .catch((e) => {
          this.notifyError("Encountered error deleting watson value");
        });
    },
    authorized(description = "") {
      return (
        description.includes("General") ||
        _.intersection(this.userDepartment, description.toLowerCase().split(",")).length > 0
      );
    },
    getMyEntities(options) {
      if (options && options.loading) {
        this.loading = true;
      }
      var loader = null;
      if (options && options.loading2) {
        loader = this.$loading({
          target: ".loader-intent-add-edit .el-dialog__body",
          fullscreen: false,
          text: "Loading...",
        });
      }
      return this.$store
        .dispatch("FETCH_WATSON_MY_ENTITIES")
        .then(() => {
          this.loading = false;
          if (loader) {
            loader.close();
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Error",
            message: "Encountered error fetching watson entitites",
          });
        });
    },
    handleClick(tab, event) {
      this.getMyEntities({ loading: true });
    },
    handleSelectionChangeOfMyEntities(val) {
      this.multipleSelectionOfMyEntities = val;
    },
    getMyEntityValues(row) {
      let tempValuesArr = [];
      row.values.map((v, i) => {
        tempValuesArr.push(v.value);
      });
      return tempValuesArr.join(", ");
    },
    editEntity(entity) {
      this.dialogMyEntityVisible = true;
      this.dialogtext = "Edit Entity";
      this.addValue = "Add";
      this.updateEntityName = entity.entity;
      this.updateEntityDescription = entity.description;
      this.addEntityForm.entityName = entity.entity;
      this.addEntityForm.description = entity.description.split(",");
      this.addedEntityName = entity.entity;
      this.valuesList = entity.values;
      this.dynamicValidateForm.domains = [{ k: 1, value: "" }];
      this.dynamicValidateForm.value = "";
    },
    deleteMyEntity() {
      this.dialogDeleteMyEntityVisible = true;
    },
    deleteMyIntityConfirm() {
      this.dialogDeleteMyEntityVisible = false;

      this.$store
        .dispatch("DELETE_WATSON_ENTITIES", this.multipleSelectionOfMyEntities)
        .then((response) => {
          this.getMyEntities({ loading: true });
        })
        .catch((e) => {
          this.notifyError("Encountered error deleting watson entities");
        });
    },
    submitForm(formName) {
      const self = this;
      if (this.addEntityForm.description.length == 0) {
        this.addEntityForm.description = ["General"];
      }
      if (this.addEntityForm.entityName) {
        let payload = {
          entity: this.addEntityForm.entityName,
          description: this.addEntityForm.description.join(","),
        };
        this.$store
          .dispatch("ADD_WATSON_ENTITY", payload)
          .then((response) => {
            this.addedEntityName = this.addEntityForm.entityName;
            this.updateEntityName = this.addEntityForm.entityName;
            // this.dialogMyEntityVisible = false;
            // this.getMyEntities({ loading: true });
            self.getMyEntities({ loading: false, loading2: true }).then(() => {
              self.myEntitiesList.forEach((ele) => {
                if (ele.entity == self.addedEntityName) {
                  self.editEntity(ele);
                }
              });
            });
          })
          .catch((e) => {
            this.notifyError("Encountered error adding all watson entity");
          });
      }
    },
    addValueForm(formName) {
      const self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let payload = {
            description: "",
            value_type: "",
            value: "",
            old_value: "",
            synonyms: [],
            patterns: [],
            entity: "",
          };
          if (self.dynamicValidateForm.entityValueType === "synonyms") {
            let synList = self.getStringFromArray(self.dynamicValidateForm.domains);
            payload = {
              description: self.updateEntityDescription,
              value_type: self.dynamicValidateForm.entityValueType,
              value: self.dynamicValidateForm.value,
            };
            if (
              !(
                self.dynamicValidateForm.domains.length === 1 &&
                self.dynamicValidateForm.domains[0].value === ""
              )
            ) {
              payload.synonyms = synList;
            } else {
              payload.synonyms = [self.dynamicValidateForm.value];
            }
          } else if (self.dynamicValidateForm.entityValueType === "patterns") {
            let pattList = self.getStringFromArray(self.dynamicValidateForm.domains);
            payload = {
              description: self.updateEntityDescription,
              value_type: self.dynamicValidateForm.entityValueType,
              value: self.dynamicValidateForm.value,
            };
            if (
              !(
                self.dynamicValidateForm.domains.length === 1 &&
                self.dynamicValidateForm.domains[0].value === ""
              )
            ) {
              payload.patterns = pattList;
            } else {
              payload.patterns = [self.dynamicValidateForm.value];
            }
          }
          payload.entity = self.addedEntityName;
          if (self.addValue === "Edit") {
            let old_values_payload = self.getOldValueFromList(this.updateEntityValue);
            // @parmeshwar fix value number error and log issue
            // old_values_payload[
            //   self.dynamicValidateForm.entityValueType
            // ] = self.getKeyFromArray(self.dynamicValidateForm.domains);

            let payloadEdit = {
              ...payload,
              // @JOSH this is the new value and synonym alrdy
              new_type: payload.value_type,
              value: this.updateEntityValue,
              new_value: payload.value,
              new_synonyms: payload.synonyms || null,
              new_patterns: payload.patterns || null,
              entity: payload.entity,
              description: this.updateEntityDescription,
              old_values: _.assign(_.cloneDeep(payload), old_values_payload),
            };
            self.$store
              .dispatch("UPDATE_WATSON_VALUE", payloadEdit)
              .then((response) => {
                self.dynamicValidateForm.domains = [{ k: 1, value: "" }];
                self.dynamicValidateForm.value = "";
                // self.dialogMyEntityVisible = false;
                self.getMyEntities({ loading: false, loading2: true }).then(() => {
                  self.myEntitiesList.forEach((ele) => {
                    if (ele.entity == self.addedEntityName) {
                      self.editEntity(ele);
                    }
                  });
                });
              })
              .catch((e) => {
                self.notifyError("Encountered error updating watson value");
              });
            return;
          }
          self.$store
            .dispatch("ADD_WATSON_VALUE", payload)
            .then((response) => {
              self.dynamicValidateForm.domains = [{ k: 1, value: "" }];
              self.dynamicValidateForm.value = "";
              // self.dialogMyEntityVisible = false;
              self.getMyEntities({ loading: false, loading2: true }).then(() => {
                self.myEntitiesList.forEach((ele) => {
                  if (ele.entity == self.addedEntityName) {
                    self.editEntity(ele);
                  }
                });
              });
            })
            .catch((e) => {
              self.notifyError("Encountered error adding watson value");
            });
        } else {
          return false;
        }
      });
    },
    resetValueForm(form) {
      this.dynamicValidateForm.domains = [{ k: 1, value: "" }];
      this.dynamicValidateForm.value = "";
      this.addValue = "Add";
    },
    getStringFromArray(arrOfObj) {
      let arr = [];
      if (arrOfObj.length >= 1) {
        arrOfObj.map(function (v, i) {
          arr.push(v.value);
        });
      }
      return arr;
    },
    getKeyFromArray(arrOfObj) {
      let arr = [];
      if (arrOfObj.length >= 1) {
        arrOfObj.map(function (v, i) {
          arr.push(v.key);
        });
      }
      return arr;
    },
    getOldValueFromList(value) {
      const valuesList = this.valuesList;
      const result = valuesList.filter((val) => {
        return val.value === value;
      });
      return result[0];
    },
    switchValueType(type) {
      if (type) {
        this.dynamicValidateForm.entityValueType = type;
        this.dynamicValidateForm.domains = [{ key: 1, value: "" }];
      }
    },
    updateEntity(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addEntityForm.description.length == 0) {
            this.addEntityForm.description = ["General"];
          } else if (
            this.addEntityForm.description &&
            this.addEntityForm.description.length > 1 &&
            this.addEntityForm.description[0] === "General"
          ) {
            this.addEntityForm.description.splice(0, 1);
          }
          if (this.addEntityForm.entityName) {
            const currentEntity = _.find(this.watsonEntities, {
              entity: this.addedEntityName,
            });
            let payload = {
              old_values: {
                ...currentEntity,
                entity: this.addedEntityName,
                description: currentEntity && currentEntity.description, //TODO: @JOSH change link
              },
              new_values: {
                entity: this.addedEntityName,
                new_entity: this.addEntityForm.entityName,
                new_description: this.addEntityForm.description.join(","),
              },
            };
            this.$store
              .dispatch("UPDATE_WATSON_ENTITY", payload)
              .then((response) => {
                this.getMyEntities();
                this.addedEntityName = this.addEntityForm.entityName;
                this.$message({
                  message: "Saved",
                  type: "success",
                });
              })
              .catch((e) => {
                this.notifyError("Encountered error updating watson entity");
              });
          }
        }
      });
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        key: Date.now(),
        value: "",
      });
    },
    addEditDialogClose() {
      this.addedEntityName = "";
      this.addEntityForm.entityName = "";
    },
    getSynonymsOrPatterns(row) {
      let str = "";
      if (row.type == "synonyms") {
        if (row.synonyms && row.synonyms.length >= 1) {
          str = row.synonyms.join(", ");
        }
      } else if (row.type == "patterns") {
        if (row.patterns && row.patterns.length >= 1) {
          str = row.patterns.join(", ");
        }
      }
      return str;
    },
    loadValue(row) {
      this.addValue = "Edit";
      this.dynamicValidateForm.entityValueType = row.type;
      this.dynamicValidateForm.value = row.value;
      this.updateEntityValue = row.value;
      this.dynamicValidateForm.domains = [];
      if (row.type === "synonyms" && row.synonyms.length > 0) {
        row.synonyms.forEach((element) => {
          this.dynamicValidateForm.domains.push({
            key: element,
            value: element,
          });
        });
      } else if (row.type === "patterns" && row.patterns.length > 0) {
        row.patterns.forEach((element) => {
          this.dynamicValidateForm.domains.push({
            key: element,
            value: element,
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.my_entity_name {
  cursor: pointer;
  color: #e4392b;
}
.values-list {
  margin-top: 50px;
}
.margin-inline-form .el-form-item__content {
  margin-left: 120px;
}
</style>
