<template>
  <el-row :gutter="15">
    <el-col :span="8">
      <BroadcastList />
    </el-col>
    <el-col :span="16">
      <el-card v-if="!selectedBroadcast">
        <div slot="header">
          <strong>Create broadcast for all users</strong>
        </div>

        <!-- Broadcast card body -->
        <div>
          <div>
            <p>Select a content node you wish to broadcast!</p>

            <el-row>
              <el-autocomplete
                class="new input"
                placeholder="Select content node"
                v-model="newTempBroadcast.contentNodeId"
                :fetch-suggestions="querySearch"
              >
                <!-- @keyup.enter.native="findNode" -->
                <!-- @select="findNode()" -->
                <!-- <template slot="prepend">Node name</template> -->
              </el-autocomplete>
            </el-row>
            <el-row>
              <el-date-picker
                class="new input"
                v-model="newTempBroadcast.datetime"
                type="datetime"
                placeholder="Select date and time"
                format="dd-MM-yyyy HH:mm"
                :picker-options="pickerOptions"
              />
            </el-row>

            <el-row>
              <el-select
                v-model="newTempBroadcast.conditions"
                style="flex: 1"
                placeholder="Select a user segment"
                clearable
                filterable
              >
                <el-option
                  v-for="(conditionsArray, segmentLabel, index) in presetSegments"
                  :key="index"
                  multiple="false"
                  :label="segmentLabel"
                  :value="conditionsArray"
                >
                  {{ segmentLabel }}
                </el-option>
              </el-select>
            </el-row>
            <el-row>
              <el-button
                icon="el-icon-plus"
                class="button"
                @click="createBroadcast"
                :disabled="
                  !newTempBroadcast.datetime ||
                  !newTempBroadcast.contentNodeId ||
                  newTempBroadcast.conditions.length === 0
                "
              >
                Create a broadcast
              </el-button>
            </el-row>
          </div>
        </div>
      </el-card>
      <SelectedBroadcast v-else />
    </el-col>
  </el-row>
</template>
<script>
import BroadcastList from "@/components/BroadcastList";
import SelectedBroadcast from "@/components/Broadcast/SelectedBroadcast";
import { isPast, pickerOptions } from "@/helperMethods/broadcast/index";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  components: {
    BroadcastList,
    SelectedBroadcast,
  },
  data() {
    return {
      newTempBroadcast: { contentNodeId: "", datetime: "", conditions: [] },
      sending: false,
      deleting: false,
      updating: false,
      creating: false,
      showFindNodePopover: false,
      datetime: "",
      pickerOptions: pickerOptions,

      limit: [
        {
          type: "fromto",
          from: moment().subtract(1, "days").format("YYYY-MM-DD"),
          to: moment().add(2, "months").format("YYYY-MM-DD"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["selectedBroadcast", "selectedBroadcastId", "presetSegments"]),

    broadcast: {
      get() {
        const selectedBroadcast = this.selectedBroadcast;
        return selectedBroadcast;
      },
      set(value) {},
    },
    editable() {
      return this.selectedBroadcastId ? !isPast(this.selectedBroadcastId) : true;
    },
    // submitString() {
    //   return moment(this.datetime).isAfter(moment())
    //     ? `Send ${moment(this.datetime).fromNow()}`
    //     : "Send immediately";
    // },
    valid() {
      if (this.broadcast.length > 0) {
        return this.broadcast.every((card, index) => {
          let reference = this.$refs[`content-${index}`];
          if (reference && reference.length > 0) {
            return reference[0].valid;
          }
        });
      } else {
        console.log(this.broadcast.length);
        return false;
      }
    },
  },
  // watch: {
  //   $route() {
  //     this.getBroadcast();
  //   }
  // },

  mounted() {
    this.fetchBroadcasts();

    // Indicate the warning for user
    this.$notify.warning({
      showClose: true,
      message:
        "Creating a broadcast reaches all users, with no way to edit/revoke the message once sent.",
      duration: 5000,
    });
  },
  methods: {
    fetchBroadcasts() {
      this.$store
        .dispatch("FETCH_BROADCASTS")
        .then((isFetched) => {
          if (isFetched) {
            this.$message({
              type: "success",
              message: "Fetched broadcast successfully.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching broadcasts.",
          });
        });
    },
    querySearch(queryString, cb) {
      cb(this.$store.state.search.content.search(queryString || ""));
    },
    timebar(timestamp) {
      return isPast(timestamp) ? "" : "btn-success";
    },

    // FIXME: will remove once these features are not needed
    // addCard() {
    //   this.broadcast.push({
    //     image: "",
    //     text: "",
    //     subtext: "",
    //     url: "",
    //     buttons: [{ text: "", event: "", data: "" }]
    //   });
    // },
    // findNode() {
    //   const node = _.find(this.$store.state.nodes.content, (v, k) => {
    //     return k === this.newTempBroadcast.contentNodeId;
    //   });
    //   if (node) {
    //     this.broadcast.push(node.content);
    //   } else {
    //     this.$message({
    //       message: "Node not found",
    //       type: "error"
    //     });
    //   }

    //   // TODO: will be removed
    //   if (node.next) {
    //     this.newTempBroadcast.contentNodeId = node.next;
    //     this.findNode();
    //   }
    // },
    newNode() {
      this.broadcast.push("");
    },
    addNode(index) {
      let nodeId = this.broadcast[index];
      let node = this.$store.state.nodes.content[nodeId];
      if (node) {
        this.$set(this.broadcast, index, node.content);
      }
    },
    addBubble() {
      this.broadcast.push({ text: "" });
    },
    // getBroadcast() {
    //   if (this.$route.params.timestamp) {
    //     this.datetime = moment
    //       .unix(Number(this.$route.params.timestamp) / 1000)
    //       .format("YYYY-MM-DD HH:mm");

    //     this.broadcast = this.selectedBroadcast;
    //     // this.$store.state.broadcast.broadcasts[this.$route.params.timestamp]
    //     //   .payload || [];
    //   } else {
    //     this.datetime = "";
    //     // this.editable = true;
    //     this.broadcast = [];
    //   }
    // },

    // sendImmediately(event) {
    //   // if (this.valid) {
    //   let prev = this.$route.params.timestamp;
    //   let content = this.broadcast;

    //   if (!isPast(event.target.dataset.timestamp)) {
    //     this.$confirm(
    //       "This will be sent immediately. Continue?",
    //       "Are you sure?",
    //       {
    //         confirmButtonText: "Send now!",
    //         cancelButtonText: "Cancel",
    //         type: "warning"
    //       }
    //     )
    //       .then(() => {
    //         this.sending = true;
    //         let now = moment().format("x");
    //         this.$store
    //           .dispatch("EDIT_BROADCAST", {
    //             previous: prev,
    //             timestamp: now,
    //             broadcast: content
    //           })
    //           .then(() => {
    //             this.sending = false;

    //             this.$message({
    //               type: "success",
    //               message: "Broadcast Sent!"
    //             });

    //             this.$router.push("/broadcast/" + now);
    //           });
    //       })
    //       .catch(() => {
    //         this.$message({
    //           type: "info",
    //           message: "Sending Canceled"
    //         });
    //       });
    //   }
    // },

    createBroadcast(event) {
      // if (this.valid) {
      let dt = this.newTempBroadcast.datetime || moment(); // TODO: to be removed

      this.$confirm(
        "You are sending a broadcast that will reach all users. Continue?",
        "Are you sure?",
        {
          confirmButtonText: "Create",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.creating = true;
          let timestamp = moment(dt).format("x");
          this.$store
            .dispatch("CREATE_BROADCAST", {
              broadcast: this.newTempBroadcast,
              timestamp,
            })
            .then(() => {
              this.$message({
                type: "success",
                message: "Created broadcast successfully!",
              });

              this.creating = false;

              this.$router.push("/broadcast/" + timestamp);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Encountered error creating broadcast",
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Canceled the broadcast creation",
          });
        });
    },
    // editBroadcast(event) {
    //   if (this.valid) {
    //     let prev = this.$route.params.timestamp;
    //     let content = this.broadcast;
    //     let dt = this.datetime || moment();

    //     this.$confirm(
    //       "Confirm change in broadcast content. Continue?",
    //       "Are you sure?",
    //       {
    //         confirmButtonText: "Edit",
    //         cancelButtonText: "Cancel",
    //         type: "warning"
    //       }
    //     )
    //       .then(() => {
    //         let timestamp = moment(dt).format("x");
    //         this.ready = false;
    //         this.$store
    //           .dispatch("EDIT_BROADCAST", {
    //             previous: prev,
    //             timestamp,
    //             broadcast: content
    //           })
    //           .then(() => {
    //             this.updating = false;
    //             this.$message({
    //               type: "success",
    //               message:
    //                 "Broadcast updated! Your message will be broadcasted as scheduled."
    //             });

    //             this.$router.push("/broadcast/" + timestamp);
    //           })
    //           .catch(() => {
    //             this.$message({
    //               type: "error",
    //               message: "Failed to update broadcast."
    //             });
    //           });
    //       })
    //       .catch(() => {
    //         this.$message({
    //           type: "info",
    //           message: "Canceled"
    //         });
    //       });
    //   }
    // }
  },
};
</script>
<style scoped>
.row-fluid {
  white-space: nowrap;
  overflow: auto;
}

.button {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: #e5392b !important;
  color: white !important;
  border-color: #e5392b !important;
}

.button:hover {
  background: white !important;
  color: #e5392b !important;
  border-color: #e5392b !important;
}

.el-card.nodePreviewSection .el-card__body {
  padding: 5px !important;
}

.el-card.nodePreviewSection {
  border-radius: 0px;
  background-color: #fafafa;
  margin: 0px -20px 20px -20px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.text-option-sub-title {
  font-weight: bold;
  font-size: 1em;
}

.new.input {
  width: 300px;
}

.no-preview-section {
  text-align: center;
  color: rgb(112, 112, 112);
  font-size: 18px;
  vertical-align: middle;
  line-height: 50px;
  /* margin-left: 60px; */
}
</style>
