<template>
  <el-dialog :title="title" width="50%" :visible.sync="dialogVisible">
    <!-- Implementation in progress Do not delete -->
    <!-- <el-dialog :title="'View Diff'" width="80%" :visible.sync="diffVisible" append-to-body>
      <div style="display:flex;">
        <div v-if="diffOne" style="width: 50%">
          <ContentNode :node="diffOne" />
        </div>
        <div v-if="diffTwo" style="width: 50%">
          <ContentNode :node="diffTwo" />
        </div>
      </div>
    </el-dialog>-->
    <div style="margin-bottom: 10px">
      <el-input
        ref="text-filter"
        placeholder="Filter keyword"
        v-model="filterText"
        @input="debounceFilterTree"
      ></el-input>
      <div ref="changes-filters" v-if="mode === 'import'">
        <el-checkbox v-model="newFilter" @change="debounceFilterTree"> New </el-checkbox>
        <el-checkbox v-model="overwriteFilter" @change="debounceFilterTree">
          Overwrite
        </el-checkbox>
      </div>
    </div>

    <el-tree
      ref="exportTree"
      :data="data"
      show-checkbox
      node-key="id"
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
    >
      <span class="custom-tree-node" slot-scope="{ node }">
        <span>
          <span v-if="!node.isLeaf && node.data.modified" style="color: orange">
            {{ node.label }} (MODIFIED*)
          </span>
          <span v-else-if="node.data && node.data.new" style="color: darkseagreen">
            {{ node.label }}
            <span v-if="node.isLeaf">(NEW*)</span>
          </span>
          <span v-else-if="node.data && node.data.overwrite" style="color: red">
            {{ node.label }}
            <span v-if="node.isLeaf">(OVERWRITE*)</span>
          </span>
          <span v-else>{{ node.label }}</span>
        </span>

        <span>
          <!-- <el-button
            v-if="advancedFeaturesEnabled && node.isLeaf"
            type="text"
            size="mini"
            @click="viewDiff(node.data.oldData, node.data.newData)"
          >View</el-button>-->
          <el-button
            v-if="advancedFeaturesEnabled && !node.data.new"
            type="text"
            size="mini"
            @click="handleDeleteUndo(node, node.data.deleted)"
          >
            {{ node.data.deleted ? "Undo" : "Delete" }}
          </el-button>
        </span>
      </span>
    </el-tree>
    <div slot="footer">
      <div style="text-align: initial"></div>
      <div class="dialog-footer">
        <el-collapse v-if="advancedFeaturesEnabled">
          <el-collapse-item title="Transformations" name="1">
            <Transformations ref="transformations" :transformations="transformations" />
          </el-collapse-item>
        </el-collapse>
        <div style="margin-top: 10px">
          <el-button ref="debug" v-if="advancedFeaturesEnabled" type="primary" @click="debug">
            Debug
          </el-button>
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="confirm">Confirm</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import _ from "lodash";
import Transformations from "./Transformations.vue";

export default {
  components: {
    Transformations,
    // ContentNode
  },
  props: {
    title: String,
    data: Array,
    visible: {
      type: Boolean,
      default: false,
    },
    advancedFeaturesEnabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ["import", "export"].indexOf(value) !== -1;
      },
    },
  },
  name: "ImportExportDialog",
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(data) {
        this.$emit("update:visible", data);
      },
    },
  },
  data() {
    return {
      diffOne: null,
      diffTwo: null,
      diffVisible: false,
      newFilter: false,
      overwriteFilter: false,
      debouncedFilter: _.debounce((val) => {
        this.$refs.exportTree.filter(val);
      }, 300),
      filterText: "",
      transformations: [
        {
          enabled: false,
          displayName: "Content Text",
          type: "content",
          path: "content.text",
          expectedPattern: "",
          replacement: "",
        },
        {
          enabled: false,
          displayName: "Content Image",
          type: "content",
          path: "content.image",
          expectedPattern: "",
          replacement: "",
        },
        {
          enabled: false,
          displayName: "Button Text",
          type: "content",
          path: "content.buttons.text",
          expectedPattern: "",
          replacement: "",
        },
        {
          enabled: false,
          displayName: "Button Website URL",
          type: "content",
          path: "content.buttons.url",
          expectedPattern: "",
          replacement: "",
        },
        {
          enabled: false,
          displayName: "Button Phone Number",
          type: "content",
          path: "content.buttons.phone",
          expectedPattern: "",
          replacement: "",
        },
        {
          enabled: false,
          displayName: "Quick Reply Text",
          type: "content",
          path: "quickReplies.text",
          expectedPattern: "",
          replacement: "",
        },
      ],
    };
  },
  methods: {
    viewDiff(diffOne, diffTwo) {
      this.diffVisible = true;
      this.diffOne = diffOne;
      this.diffTwo = diffTwo;
    },
    doesImportNodeExist(node) {
      var childNodes = node.root ? node.root.childNodes : node.childNodes;

      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];
        if (childNode.data.modified || childNode.data.overwrite || childNode.data.new) {
          return true;
        }
        return this.doesImportNodeExist(childNode);
      }

      if (node.data.modified || node.data.overwrite || node.data.new) {
        return true;
      }
      return false;
    },
    handleDeleteUndo(node, isDeleted) {
      if (isDeleted) {
        this.$delete(node.data, "deleted");
        this.$delete(node.data, "partiallyDeleted");
        const shouldSetDisable = this.doesImportNodeExist(node);
        if (shouldSetDisable) {
          this.$set(node.data, "disabled", false);
        }
      } else {
        this.$set(node.data, "deleted", true);
        this.$set(node.data, "disabled", true);
      }

      this.recursivelySetChildrenDeleteFlag(node, isDeleted);
      this.recursivelySetParentsDeleteFlag(node, isDeleted);
      // this.$set(node.data, "deleted", !node.data.deleted);
    },
    recursivelySetParentsDeleteFlag(node, isDeleted) {
      if (node.level < 1) return;

      const parent = _.get(node, "parent", null);
      if (!parent) return;

      const parentsChildren = _.get(parent, "childNodes", []);

      if (parentsChildren.length === 0) return;

      const deletedOrPartiallyDeletedNodes = _.filter(parentsChildren, (node) => {
        if (node.data.deleted || node.data.partiallyDeleted) return true;
        return false;
      });

      if (deletedOrPartiallyDeletedNodes.length === parentsChildren.length) {
        this.$set(parent.data, "deleted", true);
        this.$delete(parent.data, "partiallyDeleted");
        this.$set(parent.data, "disabled", true);
      } else if (
        deletedOrPartiallyDeletedNodes.length < parentsChildren.length &&
        deletedOrPartiallyDeletedNodes.length > 0
      ) {
        this.$set(parent.data, "partiallyDeleted", true);
        this.$delete(parent.data, "deleted");
        const shouldSetDisable = this.doesImportNodeExist(node);
        if (shouldSetDisable) {
          this.$set(parent.data, "disabled", false);
        }
      } else {
        this.$delete(parent.data, "deleted");
        this.$delete(parent.data, "partiallyDeleted");
        const shouldSetDisable = this.doesImportNodeExist(node);
        if (shouldSetDisable) {
          this.$set(parent.data, "disabled", false);
        }
      }
      this.recursivelySetParentsDeleteFlag(parent, isDeleted);
    },
    recursivelySetChildrenDeleteFlag(node, isDeleted) {
      // Handle children
      const children = _.get(node, "childNodes", []);
      if (children.length >= 0) {
        children.forEach((childNode) => {
          if (childNode.data.new) return;

          if (isDeleted) {
            this.$set(childNode.data, "deleted", false);
            this.$set(childNode.data, "partiallyDeleted", false);
            if (childNode.data.modified || childNode.data.overwrite) {
              this.$set(childNode.data, "disabled", false);
            }
          } else {
            this.$set(childNode.data, "deleted", true);
            this.$set(childNode.data, "disabled", true);
          }
          this.recursivelySetChildrenDeleteFlag(childNode, isDeleted);
        });
      }
    },
    validateTransformations() {
      if (this.advancedFeaturesEnabled) {
        const isTransformationsValid = this.$refs.transformations.validate();
        if (!isTransformationsValid) {
          this.$message({
            message: "Please ensure that any transformation(s) enabled are valid!",
            type: "error",
          });
          return false;
        }
      }
      return true;
    },
    getNodes(nodes, leafOnly = false, includeHalfChecked = false, checkEligibleNodeFunction) {
      var checkedNodes = [];
      let checkEligibleNode = checkEligibleNodeFunction;
      if (!checkEligibleNode) {
        checkEligibleNode = function (child) {
          return (
            (child.checked || (includeHalfChecked && child.indeterminate)) &&
            (!leafOnly || (leafOnly && child.isLeaf))
          );
        };
      }
      var traverse = function traverse(node) {
        var childNodes = node.root ? node.root.childNodes : node.childNodes;

        childNodes.forEach(function (child) {
          const isEligible = checkEligibleNode(child);
          if (isEligible) {
            checkedNodes.push(child.data);
          }
          traverse(child);
        });
      };

      traverse(nodes);

      return checkedNodes;
    },
    validateSelectedNodes() {
      const selectedNodes = this.getNodes(this.$refs.exportTree, true, false, function (child) {
        return child.checked && child.isLeaf && !child.data.deleted;
      });

      const selectedLeafNodes = _.filter(
        selectedNodes,
        (node) => !node.children || (Array.isArray(node.children) && node.children.length === 0)
      );
      if (selectedLeafNodes.length === 0) {
        let noExportedNodesErrorMessage = "";
        if (this.mode === "import") {
          noExportedNodesErrorMessage = "Please select something to import!";
        } else {
          noExportedNodesErrorMessage = "Please select something to export!";
        }
        this.$message({
          message: noExportedNodesErrorMessage,
          type: "error",
        });
      }
      return selectedLeafNodes;
    },
    getDeletedNodes() {
      const deletedNodes = this.getNodes(this.$refs.exportTree, true, false, function (child) {
        return child.isLeaf && child.data.deleted;
      });
      return deletedNodes;
    },
    debug() {
      this.$refs.exportTree.setChecked({ overwrite: true }, true, true);
      const isTransformationsValid = this.validateTransformations();
      if (!isTransformationsValid) return;
      const selectedLeafNodes = this.validateSelectedNodes();
      if (selectedLeafNodes.length === 0) return;
      if (this.mode === "import") {
        this.$emit("debug", selectedLeafNodes, this.getDeletedNodes(), this.transformations);
      } else {
        this.$emit("debug", selectedLeafNodes);
      }
    },
    confirm() {
      const isTransformationsValid = this.validateTransformations();
      if (!isTransformationsValid) return;
      const selectedLeafNodes = this.validateSelectedNodes();
      if (selectedLeafNodes.length === 0) return;

      if (this.mode === "import") {
        this.$emit("confirm", selectedLeafNodes, this.getDeletedNodes(), this.transformations);
      } else {
        this.$emit("confirm", selectedLeafNodes);
      }
    },
    debounceFilterTree() {
      this.debouncedFilter(this.filterText);
    },
    filterNode(keyword, data) {
      // New / overwrite filter
      if (this.newFilter || this.overwriteFilter) {
        // If either filters are ticked, hide those that doesn't meet either
        const isNew = this.newFilter && data.new;
        const isOverwrite = this.overwriteFilter && data.overwrite;
        if (!isNew && !isOverwrite) return false;
      }

      // Keyword filter
      if (!keyword) return true;
      return data.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
