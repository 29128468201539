<template>
  <el-form-item prop="data">
    <el-autocomplete
      v-model="value.data"
      class="autocomplete-list autocomplete-list-target"
      style="margin-top: 0"
      placeholder="Target"
      :fetch-suggestions="getContentNodes"
    >
      <div slot="prepend" style="width: 40px">Data</div>
      <el-button
        slot="append"
        icon="el-icon-d-arrow-right"
        @click="selectNode({ type: 'content', id: value.data })"
      />
    </el-autocomplete>
  </el-form-item>
</template>

<script>
import _ from "lodash";

export default {
  name: "EventNodeTypeGoto",
  props: ["value"],
  methods: {
    /**
     * @description el-autocomplete on fetchSuggestions event handler
     * @param {string} value
     * @param {Function} cb
     * @return {void}
     */
    getContentNodes(value, cb) {
      if (value && this.$store.state.search.content) {
        cb(this.$store.state.search.content.search(value));
      } else {
        cb([]);
      }
    },

    /**
     * @description On select node event handler
     * @return {void}
     */
    selectNode({ type, id }) {
      const currentRoute = _.get(this, "$router.currentRoute.fullPath");
      if (currentRoute !== "/editor") {
        this.$router.push("/editor");
      }
      this.$store.dispatch("SELECT_NODE", { type, id });
    },
  },
};
</script>
