import _ from "lodash";

interface ChangesTreeLeaf {
  label: string;
  type: string;
  oldData: any;
  newData: any;
  overwrite?: boolean;
  new?: boolean;
  modified?: boolean;
}

interface ChangesTreeBranch {
  label: string;
  type: string;
  modified?: boolean;
  children: (ChangesTreeLeaf | ChangesTreeLeaf)[];
}

type ChangesTreeLeafOrBranch = ChangesTreeLeaf | ChangesTreeBranch;

interface GenericData {
  data: object;
  changes: ChangesTreeLeaf;
}
interface GenericDataObject {
  [identifier: string]: GenericData;
}

/**
 *
 * Checks if nodes has either a modified, new, or overwrite flag
 */

function checkForModificationsInNodes(nodes: (ChangesTreeLeaf | ChangesTreeBranch)[]) {
  return !!_.find(nodes, (obj) => {
    if (instanceOfChangesTreeLeaf(obj)) {
      return obj.new || obj.overwrite;
    } else {
      return obj.modified;
    }
  });
}
/**
 *
 * If the children property is not inside, it means that this object is a leaf.
 */
function instanceOfChangesTreeLeaf(
  leafOrBranch: ChangesTreeLeaf | ChangesTreeBranch
): leafOrBranch is ChangesTreeLeaf {
  return !("children" in leafOrBranch);
}
/**
 *
 * @param genericData Generic data in specified format
 * @param label String to use for root node label
 * @param maxLevel Max number of levels based on delimiter
 * @param type String used to identify each data object
 * @param delimiter Function to break down data into smaller categories
 */
export function constructTree(
  genericData: GenericDataObject,
  label: string,
  maxLevel: number,
  type: string,
  prefixDelimiter: (
    genericData: GenericDataObject,
    currentLevel: number,
    maxLevel: number
  ) => { [identifier: string]: GenericDataObject },
  isImport: boolean
) {
  const children = constructTreeChildren(genericData, 1, maxLevel, type, prefixDelimiter, isImport);
  const childrenContainsAnyModification = checkForModificationsInNodes(children);

  // If children has no changes for imports, disable.
  if (!childrenContainsAnyModification && isImport) {
    return {
      label,
      children,
      disabled: true,
    };
  }
  return {
    label,
    children,
  };
}

function constructTreeChildren(
  genericData: GenericDataObject,
  currentLevel: number,
  maxLevel: number,
  type: string,
  prefixDelimiter: (
    genericData: GenericDataObject,
    currentLevel: number,
    maxLevel: number
  ) => { [identifier: string]: GenericDataObject },
  isImport: boolean
): ChangesTreeLeafOrBranch[] {
  const contentPrefixes = prefixDelimiter(genericData, currentLevel, maxLevel);
  const keys = _.keys(contentPrefixes);
  if (currentLevel >= maxLevel) {
    const dataFilteredByPrefix = prefixDelimiter(genericData, currentLevel, maxLevel);
    const result = _.map(dataFilteredByPrefix, (data, prefix): ChangesTreeLeaf[] => {
      return _.map(
        data,
        (genericObj, identifier): ChangesTreeLeaf => {
          const changeInfo = _.get(genericObj, "changes", {});
          const data = _.get(genericObj, "data", {});
          const leaf = {
            label: "",
            type: "",
            oldData: {},
            newData: {},
          };
          const importDoesNotContainChange = _.isEmpty(changeInfo);

          let leafNodeWithChangeInfo = Object.assign({}, leaf, {
            label: identifier,
            type,
            ...changeInfo,
            ...data,
          });

          if(leafNodeWithChangeInfo.type == "object") {
            leafNodeWithChangeInfo = Object.assign(leafNodeWithChangeInfo, {type})
          }

          if (importDoesNotContainChange && isImport) {
            return Object.assign({}, leafNodeWithChangeInfo, {
              disabled: true,
            });
          }

          return leafNodeWithChangeInfo;
        }
      );
    });
    return _.flattenDeep(result);
  } else if (keys.length === 1) {
    const onlyKey = keys[0];
    const changeInfo = _.get(genericData, `${onlyKey}.changes`, {});
    const data = _.get(genericData, `${onlyKey}.data`, {});
    const leaf = {
      label: "",
      type: "",
      oldData: {},
      newData: {},
    };
    const importDoesNotContainChange = _.isEmpty(changeInfo);

    const leafNodeWithChangeInfo = Object.assign({}, leaf, {
      label: onlyKey,
      type,
      ...changeInfo,
      ...data,
    });

    if (importDoesNotContainChange && isImport) {
      return [Object.assign({}, leafNodeWithChangeInfo, { disabled: true })];
    }

    return [leafNodeWithChangeInfo];
  } else {
    const contentPrefixes = prefixDelimiter(genericData, currentLevel, maxLevel);
    return _.flattenDeep(
      _.map(
        contentPrefixes,
        (data, identifier): ChangesTreeLeafOrBranch => {
          const children = constructTreeChildren(
            data,
            currentLevel + 1,
            maxLevel,
            type,
            prefixDelimiter,
            isImport
          );
          const childrenContainsAnyModification = checkForModificationsInNodes(children);
          const branch = {
            label: "",
            type: "",
            modified: false,
            children: [],
          };
          const branchWithChildren = Object.assign(branch, {
            label: identifier,
            children,
            modified: childrenContainsAnyModification,
          });

          // If children has no changes for imports, disable.
          if (!childrenContainsAnyModification && isImport) {
            return Object.assign({}, branchWithChildren, { disabled: true });
          }
          return branchWithChildren;
        }
      )
    );
  }
}
