<template>
  <div style="margin-top: 15px">
    <template v-if="!section.questions || section.questions.length === 0">
      <p style="margin-top: 40px; text-align: center">
        <i style="color: #878d99">This flow don't have any section.</i>
      </p>
    </template>
    <masonry :cols="{ default: 3, 1024: 2, 600: 1 }" :gutter="10">
      <el-card
        v-for="(question, index) in section.questions"
        :key="`${section.sectionId}-question-${index}`"
        :body-style="{ padding: '0px' }"
        style="margin-bottom: 10px"
        class="box-card"
        shaddow="none"
      >
        <div style="padding: 14px">
          <p style="word-break: break-word">{{ question.name }}</p>
          <div class="bottom clearfix">
            <small>
              <read-more
                more-str="read more"
                less-str="read less"
                link="#"
                :text="escapeReadMore(question.question)"
                :max-chars="100"
                class="read-more"
              ></read-more>
            </small>
            <el-row
              style="margin: 10px 0 0 0"
              type="flex"
              align="middle"
              justify="space-between"
              :gutter="0"
            >
              <el-tag size="mini">{{ question.type | toTypeName }}</el-tag>
              <div>
                <el-button
                  type="text"
                  size="mini"
                  icon="el-icon-delete"
                  @click.prevent="onDeleteQuestion(question)"
                />
                <QuestionMove
                  @deleteQuestion="deleteQuestion(question)"
                  :flow-options="flowOptions"
                  :question="question"
                />
                <el-button
                  type="text"
                  size="mini"
                  icon="el-icon-edit-outline"
                  @click.prevent="onEdit(question)"
                />
              </div>
            </el-row>
          </div>
        </div>
      </el-card>
    </masonry>

    <JSONEditor v-if="$store.getters.isShowAdvanced" :value="activeFlow" />

    <QuestionEdit
      ref="question-edit-drawer"
      :question="selectedQuestion"
      :active-flow="activeFlow"
      @close="selectedQuestion = null"
      @navigate="navigate($event)"
    />
  </div>
</template>

<script>
import QuestionEdit from "./Edit";
import JSONEditor from "@/components/JSONEditor";
import _ from "lodash";
import QuestionMove from "./Move";
import { getSuccessOption } from "./Index.ts";

export default {
  name: "SectionQuestions",
  props: ["section", "activeFlow", "flowOptions"],
  components: {
    JSONEditor,
    QuestionEdit,
    QuestionMove,
  },
  data() {
    return {
      selectedQuestion: null,
    };
  },
  filters: {
    toTypeName: (text) => {
      const types = {
        string: "Short Answer",
        email: "Email",
        phone: "Phone Number",
        multipleChoice: "Multiple Choice",
        multiple_options: "Checkboxes",
      };
      const type = _.get(types, text, "");
      return type;
    },
  },
  methods: {
    navigate(event) {
      const item = getSuccessOption(event);
      const isFlowNode = item.flowId || item.sectionId || item.questionId;

      if (!item) {
        return;
      } else {
        this.$confirm(`Are you sure to navigate away from this question?`, "Warning", {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "info",
        })
          .then(() => {
            this.$set(this.$refs["question-edit-drawer"], "isDrawerOpened", false);
            this.selectedQuestion = null;
            this.$nextTick(() => {
              if (isFlowNode) {
                // flow node
                this.$eventBus.$emit("setHeaderFilter", [item.flowId, item.sectionId]);
                this.selectedQuestion = item;
              } else {
                // content node
                this.$eventBus.$emit("editorSwitchTab", "nodes");
                this.$store.dispatch("SELECT_NODE", {
                  type: "content",
                  id: item.id,
                });
                this.$router.push("/editor");
              }
            });
          })
          .catch(() => {});
      }
    },
    onEdit(question) {
      this.selectedQuestion = question;
    },
    onDeleteQuestion(question) {
      this.$confirm(`Are you sure to delete the question?`, "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "info",
      })
        .then(() => this.deleteQuestion(question))
        .catch(() => {});
    },
    deleteQuestion(question) {
      this.$store.dispatch("DELETE_FLOW_QUESTION", {
        flowId: this.activeFlow.flowId,
        sectionId: this.section.sectionId,
        questionId: question.questionId,
      });
      const index = this.section.questions.findIndex((q) => q.questionId === question.questionId);
      this.section.questions.splice(index, 1);
      this.$forceUpdate();
    },
    escapeReadMore(text) {
      return _.escape(text);
    },
  },
  mounted() {
    this.$eventBus.$on("flowEditorEdit", (section) => {
      const totalQuestions = _.keys(section.questions);
      this.selectedQuestion = section.questions[totalQuestions.length - 1];
    });

    this.$eventBus.$on("setSelectedQuestion", (id) => {
      const questionIndex = this.section.questions.findIndex(
        (question) => question.questionId === id
      );
      if (questionIndex > -1) {
        const question = this.section.questions[questionIndex];
        this.onEdit(question);
      }
    });
  },
};
</script>

<style scoped>
>>> .read-more p {
  margin: 0;
}

>>> .read-more #readmore {
  color: #e4392b;
}
</style>
