<template>
  <el-tabs value="flow" v-model="selectedTab">
    <el-tab-pane label="Speech Recognition" name="stt">
      <TabSpeechRecognition />
    </el-tab-pane>

    <el-tab-pane label="Clustering" name="cluster">
      <TabClustering />
    </el-tab-pane>

    <el-tab-pane label="Meeting" name="meet">
      <TabMeeting />
    </el-tab-pane>

    <el-tab-pane label="Robotic Process Automation" name="rpa">
      <TabRoboticProcessAutomation />
    </el-tab-pane>

    <el-tab-pane label="Graph node" name="nodes" style="height: 80vh">
      <TabGraphNode />
    </el-tab-pane>

    <el-tab-pane label="Animated Avatar" name="avatar">
      <TabAnimatedAvatar />
    </el-tab-pane>

    <el-tab-pane label="Conversation Flow" name="flow">
      <TabConversationFlow />
    </el-tab-pane>

    <el-tab-pane label="GPT-3" name="gpt3">
      <TabGPT3 />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import Vue from "vue";

import TabSpeechRecognition from "./SpeechRecognition/Index";
import TabClustering from "./Clustering/Index";
import TabMeeting from "./Meeting/Index";
import TabRoboticProcessAutomation from "./RoboticProcessAutomation/Index";
import TabGraphNode from "./GraphNode/Index";
import TabAnimatedAvatar from "./AnimatedAvatar/Index";
import TabConversationFlow from "./ConversationFlow/Index";
import TabGPT3 from "./GPT3/Index";

export default Vue.extend({
  data() {
    return {
      selectedTab: "flow",
    };
  },
  components: {
    TabSpeechRecognition,
    TabClustering,
    TabMeeting,
    TabRoboticProcessAutomation,
    TabGraphNode,
    TabAnimatedAvatar,
    TabConversationFlow,
    TabGPT3,
    // ConversationFlow
    // Rete
  },
});
</script>
