<template>
  <NotSecureContext v-if="!isSecureContext" />
  <div
    v-else
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingMessage"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <ImportExportDialog
      v-if="exportDialogVisible"
      :visible.sync="exportDialogVisible"
      :title="'Choose Export'"
      width="50%"
      :data="exportableData"
      @confirm="confirmExport"
      :mode="'export'"
    />
    <ImportExportDialog
      v-if="importDialogVisible"
      :visible.sync="importDialogVisible"
      :title="'Choose Import'"
      width="50%"
      :data="importableData"
      :advanced-features-enabled="isSuperAdminEnabled"
      :mode="'import'"
      @delete="remove"
      @confirm="confirmImport"
      @debug="debug"
    />
    <el-row :gutter="10">
      <el-col :sm="24" :lg="12">
        <el-card style="height: 100%">
          <h1>Export Bot</h1>

          <el-input
            @click="copyKey(keyExport)"
            disabled
            placeholder="Generated Password"
            :value="keyExport.k"
          >
            <div slot="prepend">Password</div>
            <el-tooltip slot="append" placement="top" content="Click to copy">
              <el-button @click="copyKey(keyExport)" icon="el-icon-copy-document" />
            </el-tooltip>
          </el-input>
          <div
            @click="exportBot()"
            class="download-upload highlight-border-hover"
            style="cursor: pointer"
          >
            <i class="el-icon-download"></i>
            <div style="color: #5a5e66; font-size: 14px; text-align: center">
              Click to download bot file
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :sm="24" :lg="12">
        <el-card style="height: 100%">
          <h1>Import Bot</h1>

          <el-input placeholder="Enter Password" v-model="keyImport" type="password" required>
            <div slot="prepend">Password</div>
          </el-input>

          <el-upload
            style="margin-top: 8px"
            action="#"
            drag
            :auto-upload="false"
            :on-change="handleChange"
            class="download-upload no-borders"
          >
            <i class="el-icon-upload2"></i>
            <div class="el-upload__text">
              Drop bot file here or
              <em>click to upload</em>
            </div>
          </el-upload>

          <el-button style="margin-top: 5px" size type="primary" @click="importBot()"
            >Upload</el-button
          >
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="$store.state.showAdvanced">
      <el-col :sm="24" :lg="12">
        <el-card style="height: 100%">
          <h1>Decrypt Exported Bot</h1>

          <el-input
            placeholder="Enter Password"
            v-model="advancedKeyImport"
            type="password"
            required
          >
            <div slot="prepend">Password</div>
          </el-input>
          <el-upload
            style="margin-top: 8px"
            action="#"
            drag
            :auto-upload="false"
            :on-change="handleDecryptBotFileChange"
            class="download-upload no-borders"
          >
            <i class="el-icon-upload2"></i>
            <div class="el-upload__text">
              Drop bot file here or
              <em>click to upload</em>
            </div>
          </el-upload>
          <el-button size type="primary" style="margin-top: 5px" @click="decryptBotToPlainText()"
            >Decrypt</el-button
          >
        </el-card>
      </el-col>
      <el-col :sm="24" :lg="12">
        <el-card style="height: 100%">
          <h1>Encrypt Bot</h1>

          <el-input
            @click="copyKey(advancedKeyExport)"
            disabled
            placeholder="Generated Password"
            :value="advancedKeyExport.k"
          >
            <div slot="prepend">Password</div>
            <el-tooltip slot="append" placement="top" content="Click to copy">
              <el-button @click="copyKey(advancedKeyExport)" icon="el-icon-copy-document" />
            </el-tooltip>
          </el-input>
          <el-upload
            style="margin-top: 8px"
            action="#"
            drag
            :auto-upload="false"
            :on-change="handleEncryptBotFileChange"
            class="download-upload no-borders"
          >
            <i class="el-icon-upload2"></i>
            <div class="el-upload__text">
              Drop bot file here or
              <em>click to upload</em>
            </div>
          </el-upload>
          <el-button size type="primary" style="margin-top: 5px" @click="encryptBot()"
            >Encrypt</el-button
          >
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from "lodash";
import gql from "graphql-tag";
import moment from "moment";
import ImportExportDialog from "@/components/ImportExport/ImportExportDialog.vue";
import { mapState } from "vuex";
import { constructTree } from "@/helperMethods/import_export/util";
import { transformBotData } from "@/helperMethods/import_export/transformations";
import secureContextMixin from "@/mixins/secureContext";

export default {
  mixins: [secureContextMixin],
  components: {
    ImportExportDialog,
  },
  data() {
    return {
      cloneBot: {},
      newBot: {},
      mode: "",
      filterText: "",
      exportDialogVisible: false,
      importDialogVisible: false,
      exportableData: [],
      importableData: [],
      algo: {
        name: "AES-GCM",
        iv: new TextEncoder("utf-8").encode("keyreply"),
      },
      keyExport: {},
      keyImport: "",
      advancedKeyExport: {},
      advancedKeyImport: "",
      bot: {},
      loadingFlows: true,
      uploadingBot: false,
      loadingApiSettings: true,
      loadingApiMappings: true,
    };
  },
  apollo: {
    bot() {
      return {
        query: gql`
          query {
            Bot {
              rawBotJSON
            }
          }
        `,
        fetchPolicy: "network-only",
        update: (data) => {
          const bot = _.get(data, "Bot.rawBotJSON");

          return bot;
        },
      };
    },
  },
  methods: {
    contentPrefixDelimiter(genericData, currentLevel, maxLevel) {
      if (currentLevel === maxLevel) {
        return _.reduce(
          genericData,
          (prefixCurrent, value, prefix) => {
            prefixCurrent[prefix] = { [prefix]: value };
            return prefixCurrent;
          },
          {}
        );
      } else {
        const uniquePrefixes = _.chain(genericData)
          .keys()
          .map((identifier) => {
            const splitIdentifiers = identifier.split("_");
            const prefix = splitIdentifiers.slice(0, currentLevel).join("_");
            return prefix;
          })
          .uniq()
          .value();
        return _.reduce(
          uniquePrefixes,
          (prefixCurrent, prefix) => {
            prefixCurrent[prefix] = _.reduce(
              genericData,
              (current, value, key) => {
                const prefixPosition = key.indexOf(`${prefix}_`);
                if (prefixPosition === 0 || key === prefix) {
                  current[key] = value;
                }
                return current;
              },
              {}
            );
            return prefixCurrent;
          },
          {}
        );
      }
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.childNodes;
      const index = children.findIndex((childrenNode) => childrenNode.id === node.id);
      children.splice(index, 1);
      if (node.data.type) {
        if (node.isLeaf) {
          if (node.data.type === "floweditor") {
            delete this.cloneBot["flows"][node.data.oldData.flow.flowId];
            this.cloneBot["flowContent"] = _.filter(this.cloneBot["flowContent"], (val, key) => {
              return val.flowId !== node.data.oldData.flow.flowId;
            });
            this.cloneBot["flowTrigger"] = _.filter(this.cloneBot["flowTrigger"], (val, key) => {
              return val.flowId !== node.data.oldData.flow.flowId;
            });
          } else {
            if (node.data.type === "content") {
              delete this.cloneBot["content_prod"][node.data.label];
            }
            delete this.cloneBot[node.data.type][node.data.label];
          }
        } else {
          if (node.data.type === "content") {
            this.cloneBot["content_prod"] = _.filter(
              this.cloneBot["content_prod"],
              (val, key) => !key.startsWith(node.data.label)
            );
          }
          this.cloneBot[node.data.type] = _.filter(
            this.cloneBot["content_prod"],
            (val, key) => !key.startsWith(node.data.label)
          );
        }
      }
    },
    async encrypt(plaintext) {
      const textEncoder = new TextEncoder("utf-8");
      const algoKeyGen = {
        name: "AES-GCM",
        length: 256,
      };

      const key = await window.crypto.subtle.generateKey(algoKeyGen, true, ["encrypt"]);

      const data = textEncoder.encode(plaintext);

      const cipherBuffer = await crypto.subtle.encrypt(this.algo, key, data);
      return { key, cipherBuffer };
    },
    async exportBot() {
      this.exportableData = [];
      const contentChildren = constructTree(
        this.$store.state.nodes.content,
        "Content",
        2,
        "content",
        this.contentPrefixDelimiter,
        false
      );
      const settingsChildren = constructTree(
        this.$store.state.modules,
        "Settings",
        1,
        "modules",
        this.contentPrefixDelimiter,
        false
      );
      const entitiesChildren = constructTree(
        this.$store.state.nodes.entity,
        "Entities",
        1,
        "entity",
        this.contentPrefixDelimiter,
        false
      );

      const triggersChildren = constructTree(
        this.$store.state.nodes.trigger,
        "Triggers",
        2,
        "trigger",
        this.contentPrefixDelimiter,
        false
      );

      if (this.isFlowEditorModuleEnabled) {
        const flowExportObj = this.convertFlowEditorToMapFormat(this.$store.state.floweditor);
        const flowExportObjWithData = _.reduce(
          flowExportObj,
          (current, value, identifier) => {
            current[identifier] = {
              data: value,
            };
            return current;
          },
          {}
        );
        const flowsChildren = constructTree(
          flowExportObjWithData,
          "Flows",
          1,
          "floweditor",
          this.contentPrefixDelimiter,
          false
        );
        this.exportableData.push(flowsChildren);
      }

      const apiChildren = constructTree(
        this.$store.state.apieditor.apis,
        "API",
        1,
        "apiSettings",
        this.contentPrefixDelimiter,
        false
      );

      const apiMappingChildren = constructTree(
        this.$store.state.apieditor.mappings,
        "API Mapping",
        1,
        "apiMappings",
        this.contentPrefixDelimiter,
        false
      );
      this.exportableData.push(
        ...[
          settingsChildren,
          entitiesChildren,
          contentChildren,
          triggersChildren,
          apiChildren,
          apiMappingChildren,
        ]
      );
      this.exportDialogVisible = true;
    },
    async confirmExport(selectedNodes) {
      this.exportDialogVisible = false;
      const formattedExport = this.formatExport(selectedNodes);
      const { key, cipherBuffer } = await this.encrypt(JSON.stringify(formattedExport));
      this.keyExport = await window.crypto.subtle.exportKey("jwk", key);
      this.copyKey(this.keyExport);
      this.downloadFile(cipherBuffer);
    },
    formatExport(selectedNodes) {
      const result = _.reduce(
        selectedNodes,
        (result, obj) => {
          if (obj.type) {
            let data;
            if (obj.type === "content") {
              data = this.$store.state.nodes.content[obj.label];
            } else if (obj.type === "trigger") {
              data = this.$store.state.nodes.trigger[obj.label];
            } else if (obj.type === "entity") {
              data = this.$store.state.nodes.entity[obj.label];
            } else if (obj.type === "modules") {
              data = this.$store.state.modules[obj.label];
            } else if (obj.type === "floweditor") {
              const flowId = obj.flow.flowId;
              const flow = this.bot.flows[flowId];
              const contents = _.pickBy(this.bot.flowContent, (val) => val.flowId === flowId);
              const triggers = _.pickBy(this.bot.flowTrigger, (val) => val.flowId === flowId);
              result.flowContent = {
                ...result.flowContent,
                ...contents,
              };
              result.flowTrigger = {
                ...result.flowTrigger,
                ...triggers,
              };
              result.flows = {
                ...result.flows,
                [flowId]: flow,
              };
              return result;
            } else if (obj.type === "apiSettings") {
              data = this.$store.state.apieditor.apis[obj.label];
            } else if (obj.type === "apiMappings") {
              data = this.$store.state.apieditor.mappings[obj.label];
            }

            if (obj.type === "content") {
              result[`${obj.type}_prod`][obj.label] = data;
            }
            result[obj.type][obj.label] = data;
          }
          return result;
        },
        {
          content: {},
          content_prod: {},
          trigger: {},
          entity: {},
          modules: {},
          flows: {},
          flowContent: {},
          flowTrigger: {},
          apiSettings: {},
          apiMappings: {},
        }
      );
      return result;
    },
    formatImport(selectedNodes) {
      const result = _.reduce(
        selectedNodes,
        (result, obj) => {
          if (obj.type) {
            let data;
            if (obj.type === "content") {
              data = this.newBot.content[obj.label];
            } else if (obj.type === "trigger") {
              data = this.newBot.trigger[obj.label];
            } else if (obj.type === "entity") {
              data = this.newBot.entity[obj.label];
            } else if (obj.type === "modules") {
              data = this.newBot.modules[obj.label];
            } else if (obj.type === "floweditor") {
              const flowId = obj.newData.flow.flowId;
              const flow = this.newBot.flows[flowId];
              const contents = _.pickBy(this.newBot.flowContent, (val) => val.flowId === flowId);
              const triggers = _.pickBy(this.newBot.flowTrigger, (val) => val.flowId === flowId);
              result.flowContent = {
                ...result.flowContent,
                ...contents,
              };
              result.flowTrigger = {
                ...result.flowTrigger,
                ...triggers,
              };
              result.flows = {
                ...result.flows,
                [flowId]: flow,
              };
              return result;
            } else if (obj.type === "apiSettings") {
              data = this.newBot.apiSettings[obj.label];
            } else if (obj.type === "apiMappings") {
              data = this.newBot.apiMappings[obj.label];
            }

            if (data) {
              if (obj.type === "content") {
                result[`${obj.type}_prod`][obj.label] = data;
              }
              result[obj.type][obj.label] = data;
            }
          }
          return result;
        },
        {
          content: {},
          content_prod: {},
          trigger: {},
          entity: {},
          modules: {},
          flows: {},
          flowContent: {},
          flowTrigger: {},
          apiSettings: {},
          apiMappings: {},
        }
      );

      return result;
    },
    copyKey(keyExportObj) {
      if (keyExportObj) {
        const clip = document.createElement("input");
        document.body.appendChild(clip);
        clip.setAttribute("value", keyExportObj.k);
        clip.select();
        document.execCommand("copy");
        document.body.removeChild(clip);

        this.$message({
          message: "Successfully copied export password to the clipboard.",
          type: "success",
        });
      } else {
        this.$message({
          message: "No key detected. Please export again.",
          type: "error",
        });
      }
    },
    async importPrivateKey(keyImportObj) {
      return await window.crypto.subtle.importKey(
        "jwk",
        {
          alg: "A256GCM",
          ext: true,
          k: keyImportObj,
          key_ops: ["decrypt"],
          kty: "oct",
        },
        {
          name: "AES-GCM",
          length: 256,
        },
        false,
        ["decrypt"]
      );
    },
    handleChange(data) {
      this.uploadedFile = data;
      return false;
    },
    handleDecryptBotFileChange(data) {
      this.decryptBotUploadedFile = data;
      return false;
    },
    handleEncryptBotFileChange(data) {
      this.encryptBotUploadedFile = data;
      return false;
    },
    debug(selectedNodes, deletedNodes, transformations) {
      const cloneBot = this.cloneBot;
      const formattedExport = this.formatImport(selectedNodes);
      const formattedNodesToDelete = this.formatDelete(deletedNodes);
      const transformedSelectedNodes = transformBotData(formattedExport, transformations);
      const combinedExport = Object.assign(
        {},
        {
          content: _.omitBy(
            {
              ...cloneBot.content,
              ...transformedSelectedNodes.content,
            },
            (value, key) => !!formattedNodesToDelete.content[key]
          ),
          content_prod: _.omitBy(
            {
              ...cloneBot.content_prod,
              ...transformedSelectedNodes.content_prod,
            },
            (value, key) => !!formattedNodesToDelete.content_prod[key]
          ),
          trigger: _.omitBy(
            {
              ...cloneBot.trigger,
              ...transformedSelectedNodes.trigger,
            },
            (value, key) => !!formattedNodesToDelete.trigger[key]
          ),
          entity: _.omitBy(
            {
              ...cloneBot.entity,
              ...transformedSelectedNodes.entity,
            },
            (value, key) => !!formattedNodesToDelete.entity[key]
          ),
          modules: _.omitBy(
            {
              ...cloneBot.modules,
              ...transformedSelectedNodes.modules,
            },
            (value, key) => !!formattedNodesToDelete.modules[key]
          ),
          flows: _.omitBy(
            {
              ...cloneBot.flows,
              ...transformedSelectedNodes.flows,
            },
            (value, key) => !!formattedNodesToDelete.flows[key]
          ),
          flowContent: _.omitBy(
            {
              ...cloneBot.flowContent,
              ...transformedSelectedNodes.flowContent,
            },
            (value, key) => !!formattedNodesToDelete.flowContent[key]
          ),
          flowTrigger: _.omitBy(
            {
              ...cloneBot.flowTrigger,
              ...transformedSelectedNodes.flowTrigger,
            },
            (value, key) => !!formattedNodesToDelete.flowTrigger[key]
          ),
          apiSettings: _.omitBy(
            {
              ...cloneBot.apiSettings,
              ...transformedSelectedNodes.apiSettings,
            },
            (value, key) => !!formattedNodesToDelete.apiSettings[key]
          ),
          apiMappings: _.omitBy(
            {
              ...cloneBot.apiMappings,
              ...transformedSelectedNodes.apiMappings,
            },
            (value, key) => !!formattedNodesToDelete.apiMappings[key]
          ),
        }
      );
      console.log("Existing data:", cloneBot);
      console.log("Data to import:", transformedSelectedNodes);
      console.log("Data to delete:", formattedNodesToDelete);
      console.log("Combined data:", combinedExport);
    },
    confirmImport(selectedNodes, deletedNodes, transformations) {
      this.$confirm("This will replace the current bot. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          const cloneBot = this.cloneBot;
          const formattedExport = this.formatImport(selectedNodes);
          const formattedNodesToDelete = this.formatDelete(deletedNodes);
          const transformedSelectedNodes = transformBotData(formattedExport, transformations);
          const combinedExport = Object.assign(
            {},
            {
              content: _.omitBy(
                {
                  ...cloneBot.content,
                  ...transformedSelectedNodes.content,
                },
                (value, key) => !!formattedNodesToDelete.content[key]
              ),
              content_prod: _.omitBy(
                {
                  ...cloneBot.content_prod,
                  ...transformedSelectedNodes.content_prod,
                },
                (value, key) => !!formattedNodesToDelete.content_prod[key]
              ),
              trigger: _.omitBy(
                {
                  ...cloneBot.trigger,
                  ...transformedSelectedNodes.trigger,
                },
                (value, key) => !!formattedNodesToDelete.trigger[key]
              ),
              entity: _.omitBy(
                {
                  ...cloneBot.entity,
                  ...transformedSelectedNodes.entity,
                },
                (value, key) => !!formattedNodesToDelete.entity[key]
              ),
              modules: _.omitBy(
                {
                  ...cloneBot.modules,
                  ...transformedSelectedNodes.modules,
                },
                (value, key) => !!formattedNodesToDelete.modules[key]
              ),
              flows: _.omitBy(
                {
                  ...cloneBot.flows,
                  ...transformedSelectedNodes.flows,
                },
                (value, key) => !!formattedNodesToDelete.flows[key]
              ),
              flowContent: _.omitBy(
                {
                  ...cloneBot.flowContent,
                  ...transformedSelectedNodes.flowContent,
                },
                (value, key) => !!formattedNodesToDelete.flowContent[key]
              ),
              flowTrigger: _.omitBy(
                {
                  ...cloneBot.flowTrigger,
                  ...transformedSelectedNodes.flowTrigger,
                },
                (value, key) => !!formattedNodesToDelete.flowTrigger[key]
              ),
              apiSettings: _.omitBy(
                {
                  ...cloneBot.apiSettings,
                  ...transformedSelectedNodes.apiSettings,
                },
                (value, key) => !!formattedNodesToDelete.apiSettings[key]
              ),
              apiMappings: _.omitBy(
                {
                  ...cloneBot.apiMappings,
                  ...transformedSelectedNodes.apiMappings,
                },
                (value, key) => !!formattedNodesToDelete.apiMappings[key]
              ),
            }
          );

          this.uploadingBot = true;
          this.saveBot(combinedExport)
            .then(() => {
              this.$notify.success({
                title: "Success!",
                position: "bottom-right",
                message: "Bot Uploaded",
              });
              this.uploadingBot = false;
              this.$alert("To see updates to the bot, please refresh.", "Import Successful", {
                center: true,
                confirmButtonText: "Refresh",
                callback: (action) => {
                  location.reload();
                },
              });
            })
            .catch((error) => {
              this.uploadingBot = false;
              this.$notify.error({
                title: "Upload Error",
                position: "bottom-right",
                message: "Failed to upload bot." + error.message,
              });
            });
        })
        .catch((error) => {
          this.$notify.info({
            title: "Import Canceled",
            position: "bottom-right",
            message: error.message,
          });
        });
    },
    convertFlowEditorToMapFormat(floweditor) {
      return _.reduce(
        floweditor.flows,
        (current, flowValue, flowId) => {
          current[`${flowValue.title} (${flowId})`] = {
            flow: flowValue,
            contents: _.pickBy(floweditor.contents, (val) => val.flowId === flowId),
            triggers: _.pickBy(floweditor.triggers, (val) => val.flowId === flowId),
          };
          return current;
        },
        {}
      );
    },
    constructChangesObject(newBot, currentBot) {
      const changes = {
        content: {},
        content_prod: {},
        modules: {},
        trigger: {},
        entity: {},
        apiSettings: {},
        apiMappings: {},
      };
      const cloneCurrentBot = _.cloneDeep(currentBot);
      if (cloneCurrentBot.flows) {
        cloneCurrentBot.floweditor = this.convertFlowEditorToMapFormat({
          flows: cloneCurrentBot.flows,
          triggers: cloneCurrentBot.flowTrigger,
          contents: cloneCurrentBot.flowContent,
        });
      }

      if (newBot.flows) {
        // Expected: { "123-abc": { flow: {}, contents: {}, triggers: {} } } Original: { contents: {}, flows: {}, triggers: {}}
        newBot.floweditor = this.convertFlowEditorToMapFormat({
          flows: newBot.flows,
          triggers: newBot.flowTrigger,
          contents: newBot.flowContent,
        });
      }
      /**
       * @objValue Value of content, modules, trigger, entity, floweditor to base from
       * @srcValue Value of content, modules, trigger, entity, floweditor to merge
       * @key content, content_prod, modules, trigger, entity, floweditor
       * @dataToImport Value of one particular content, trigger, entity, floweditor
       * @identifier Key used to uniquely identify a bot property, e.g conversation_start for content
       */
      // Loop through all the major objects and identify whether imported data is an overwrite or is new.
      _.mergeWith(changes, cloneCurrentBot, (objValue, srcValue, key, object, source) => {
        const configuration = _.reduce(
          srcValue,
          (current, dataToImport, identifier) => {
            current[identifier] = {
              data: {
                oldData: dataToImport,
                newData: {},
              },
              changes: {},
              disabled: true,
            };
            return current;
          },
          {}
        );
        return configuration;
      });
      _.mergeWith(changes, newBot, (objValue, srcValue, key, object, source) => {
        const configuration = _.reduce(
          srcValue,
          (current, dataToImport, identifier) => {
            const propertyAlreadyExists = !!current[identifier];
            if (propertyAlreadyExists) {
              current[identifier] = {
                data: {
                  oldData: objValue[identifier].data.oldData,
                  newData: dataToImport,
                },
                changes: {
                  overwrite: true,
                },
                disabled: false,
              };
            } else {
              current[identifier] = {
                data: {
                  oldData: {},
                  newData: dataToImport,
                },
                changes: {
                  new: true,
                },
                disabled: false,
              };
            }

            return current;
          },
          objValue
        );
        return configuration;
      });

      return changes;
    },
    async importBot() {
      if (!this.keyImport) {
        this.$notify.error({
          title: "Password Error",
          position: "bottom-right",
          message: "Invalid password",
        });

        return;
      }

      if (!this.uploadedFile) {
        this.$notify.error({
          title: "File Error",
          position: "bottom-right",
          message: "No file uploaded.",
        });

        return;
      }

      try {
        const key = await this.importPrivateKey(this.keyImport);
        var fr = new FileReader();

        fr.readAsArrayBuffer(this.uploadedFile.raw);
        const cipherBuffer = await new Promise((resolve) => {
          fr.onloadend = (event) => {
            resolve(event.target.result);
          };
        });

        const clearBuffer = await crypto.subtle.decrypt(this.algo, key, cipherBuffer);
        const clearJSON = new TextDecoder("utf-8").decode(clearBuffer);
        this.newBot = JSON.parse(clearJSON);
        this.cloneBot = _.cloneDeep(this.bot);
        const changes = this.constructChangesObject(this.newBot, this.cloneBot);
        this.importableData = [];

        const settingsChildren = constructTree(
          changes.modules,
          "Settings",
          1,
          "modules",
          this.contentPrefixDelimiter,
          true
        );
        const entitiesChildren = constructTree(
          changes.entity,
          "Entities",
          1,
          "entity",
          this.contentPrefixDelimiter,
          true
        );
        const contentChildren = constructTree(
          changes.content,
          "Content",
          2,
          "content",
          this.contentPrefixDelimiter,
          true
        );
        const triggersChildren = constructTree(
          changes.trigger,
          "Triggers",
          2,
          "trigger",
          this.contentPrefixDelimiter,
          true
        );

        if (this.isFlowEditorModuleEnabled) {
          const flowsChildren = constructTree(
            changes.floweditor,
            "Flows",
            1,
            "floweditor",
            this.contentPrefixDelimiter,
            true
          );
          this.importableData.push(flowsChildren);
        }

        const apiChildren = constructTree(
          changes.apiSettings,
          "API",
          1,
          "apiSettings",
          this.contentPrefixDelimiter,
          true
        );

        const apiMappingChildren = constructTree(
          changes.apiMappings,
          "API Mapping",
          1,
          "apiMappings",
          this.contentPrefixDelimiter,
          true
        );
        this.importableData.push(
          ...[
            settingsChildren,
            entitiesChildren,
            contentChildren,
            triggersChildren,
            apiChildren,
            apiMappingChildren,
          ]
        );
        this.importDialogVisible = true;
      } catch (error) {
        console.error(error.message);

        this.$notify.error({
          title: "Decryption Error",
          position: "bottom-right",
          message: "Please check the file and password.",
        });
      }
    },
    async decryptBotToPlainText() {
      if (!this.advancedKeyImport) {
        this.$notify.error({
          title: "Password Error",
          position: "bottom-right",
          message: "Invalid password",
        });

        return;
      }

      if (!this.decryptBotUploadedFile) {
        this.$notify.error({
          title: "File Error",
          position: "bottom-right",
          message: "No file uploaded.",
        });

        return;
      }

      try {
        const botJSON = await this.decryptBot();
        this.downloadFile(botJSON);
      } catch (err) {
        console.log(err);
      }
    },
    async encryptBot() {
      if (!this.encryptBotUploadedFile) {
        this.$notify.error({
          title: "File Error",
          position: "bottom-right",
          message: "No file uploaded.",
        });

        return;
      }

      try {
        var fr = new FileReader();

        fr.readAsText(this.encryptBotUploadedFile.raw);
        const botJSONAsText = await new Promise((resolve) => {
          fr.onloadend = (event) => {
            resolve(event.target.result);
          };
        });

        const { key, cipherBuffer } = await this.encrypt(botJSONAsText);
        this.advancedKeyExport = await window.crypto.subtle.exportKey("jwk", key);
        this.copyKey(this.advancedKeyExport);
        this.downloadFile(cipherBuffer);
      } catch (err) {
        console.log(err);
      }
    },
    async decryptBot() {
      const key = await this.importPrivateKey(this.advancedKeyImport);
      var fr = new FileReader();
      fr.readAsArrayBuffer(this.decryptBotUploadedFile.raw);
      const cipherBuffer = await new Promise((resolve) => {
        fr.onloadend = (event) => {
          resolve(event.target.result);
        };
      });

      const decryptedBuffer = await crypto.subtle.decrypt(this.algo, key, cipherBuffer);

      const decryptedJSON = new TextDecoder("utf-8").decode(decryptedBuffer);
      return decryptedJSON;
    },
    downloadFile(fileContent) {
      const blob = new Blob([fileContent], { type: "keyreply/bot" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const fileName = `${this.$store.state.brain}-${moment().format("YYYYMMDD")}`;
      link.download = fileName;
      link.click();
    },
    saveBot(bot) {
      return this.$apollo.mutate({
        mutation: gql`
          mutation ($bot: JSON!) {
            setBot(bot: $bot)
          }
        `,
        variables: {
          bot,
        },
      });
    },
    formatDelete(selectedNodes) {
      const result = _.reduce(
        selectedNodes,
        (result, obj) => {
          if (obj.type) {
            if (obj.type === "content") {
              result[obj.type][obj.label] = true;
              result[`${obj.type}_prod`][obj.label] = true;
            } else if (obj.type === "floweditor") {
              const flowId = obj.newData.flow.flowId;
              const flow = this.newBot.flows[flowId];
              const contents = _.pickBy(this.newBot.flowContent, (val) => val.flowId === flowId);
              const triggers = _.pickBy(this.newBot.flowTrigger, (val) => val.flowId === flowId);
              result.flowContent = {
                ...result.flowContent,
                ...contents,
              };
              result.flowTrigger = {
                ...result.flowTrigger,
                ...triggers,
              };
              result.flows = {
                ...result.flows,
                [flowId]: flow,
              };
              return result;
            } else {
              result[obj.type][obj.label] = true;
            }
          }
          return result;
        },
        {
          content: {},
          content_prod: {},
          trigger: {},
          entity: {},
          modules: {},
          flows: {},
          flowContent: {},
          flowTrigger: {},
          apiSettings: {},
          apiMappings: {},
        }
      );

      return result;
    },
  },
  computed: {
    ...mapState({
      modules: "modules",
    }),
    /**
     * @description Floweditor module enabled
     * @return {boolean}
     */
    isSuperAdminEnabled() {
      return this.$store.state.showAdvanced;
    },
    isFlowEditorModuleEnabled() {
      return this.modules.floweditor && this.modules.floweditor.enabled;
    },
    loading() {
      return (
        this.loadingFlows || this.uploadingBot || this.loadingApiSettings || this.loadingApiMappings
      );
    },
    loadingMessage() {
      if (this.loadingFlows) {
        return "Loading Flows...";
      } else if (this.uploadingBot) {
        return "Uploading bot...";
      } else if (this.loadingApiSettings) {
        return "Loading APIs...";
      } else if (this.loadingApiMappings) {
        return "Loading API Mappings...";
      } else {
        return "Loading other resources...";
      }
    },
  },
  mounted() {
    if (this.isFlowEditorModuleEnabled) {
      this.$store.dispatch("FETCH_FLOWS").then(() => {
        this.loadingFlows = false;
      });
    } else {
      this.loadingFlows = false;
    }

    const fetchAllApisPromise = this.$store
      .dispatch("FETCH_ALL_APIS", {
        brain: this.$store.state.brain,
      })
      .catch(() => {
        this.$message({
          message: "Failed to load APIs",
          type: "error",
        });
      })
      .finally(() => {
        this.loadingApiSettings = false;
      });
    const fetchAllMappingsPromise = this.$store
      .dispatch("FETCH_ALL_MAPPINGS", {
        brain: this.$store.state.brain,
      })
      .catch(() => {
        this.$message({
          message: "Failed to load API Mappings",
          type: "error",
        });
      })
      .finally(() => {
        this.loadingApiMappings = false;
      });
  },
};
</script>
<style scoped>
>>> .el-upload.el-upload--text {
  height: 100%;
}

>>> .el-upload.el-upload--text > .el-upload-dragger {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

>>> .el-upload.el-upload--text > .el-upload-dragger:hover,
.highlight-border-hover:hover {
  border-color: #e4392b;
}
.download-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-top: 8px;
}

.no-borders {
  border: initial;
  border-radius: initial;
}
</style>
<style lang="scss" scoped>
.el-icon-upload2,
.el-icon-download {
  font-size: 67px;
  color: rgb(180, 188, 204);
  margin: 40px 0px 16px;
  line-height: 50px;
}
</style>
