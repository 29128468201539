<template>
  <el-table
    :border="true"
    :show-header="false"
    :data="arrayApis"
    highlight-current-row
    ref="api-settings-table"
    size="mini"
    style="width: 100%; height: 77vh; overflow: auto"
  >
    <el-table-column prop="name" label="Name">
      <template slot-scope="scope">
        <a style="display: block; cursor: pointer" @click.prevent="showActiveApi(scope.row.name)">
          <strong
            style="margin-right: 10px"
            :style="{
              color: getMethodColor(scope.row.method),
            }"
          >
            {{ scope.row.method }}
          </strong>
          {{ scope.row.name }}
        </a>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  components: {},
  props: ["apiLoading", "arrayApis"],
  data() {
    return {
      dialogVisible: false,
      selectedDeleteApiName: "",
    };
  },
  methods: {
    getMethodColor(method) {
      switch (method) {
        case "PUT":
          return "#409EFF";
        case "DELETE":
          return "#E4392B";
        case "POST":
          return "#eb9e05";
        default:
          return "#67c23a";
      }
    },
    showDeleteApiDialog(props) {
      this.$emit("show-delete-api-dialog", props);
    },
    showActiveApi(apiName) {
      this.$emit("show-api-editor", apiName);
    },
  },
};
</script>
