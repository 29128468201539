import _ from "lodash";

const TRIGGER_HEADERS = [
  "Trigger ID",
  "Event",
  "Data",
  "Modified",
  "Pattern",
  "Type",
  "Context",
  "Priority",
  "Positive Test",
  "Negative Test",
];

const CONTENT_HEADERS = [
  "Content ID",
  "Version ID",
  "Answer 1",
  "Answer 2",
  "Answer 3",
  "Answer 4",
  "Answer 5",
  "Delay",
  "Next Event",
  "Next Data",
  "Button 1 Text",
  "Button 1 Event",
  "Button 1 Data",
  "Button 2 Text",
  "Button 2 Event",
  "Button 2 Data",
  "Button 3 Text",
  "Button 3 Event",
  "Button 3 Data",
  "Quick Reply 1 Text",
  "Quick Reply 1 Event",
  "Quick Reply 1 Data",
  "Quick Reply 2 Text",
  "Quick Reply 2 Event",
  "Quick Reply 2 Data",
  "Quick Reply 3 Text",
  "Quick Reply 3 Event",
  "Quick Reply 3 Data",
  "Quick Reply 4 Text",
  "Quick Reply 4 Event",
  "Quick Reply 4 Data",
  "Quick Reply 5 Text",
  "Quick Reply 5 Event",
  "Quick Reply 5 Data",
  "Quick Reply 6 Text",
  "Quick Reply 6 Event",
  "Quick Reply 6 Data",
  "Quick Reply 7 Text",
  "Quick Reply 7 Event",
  "Quick Reply 7 Data",
  "Quick Reply 8 Text",
  "Quick Reply 8 Event",
  "Quick Reply 8 Data",
  "Quick Reply 9 Text",
  "Quick Reply 9 Event",
  "Quick Reply 9 Data",
  "Quick Reply 10 Text",
  "Quick Reply 10 Event",
  "Quick Reply 10 Data",
  "Condition 1 Property",
  "Condition 1 Operator",
  "Condition 1 Value",
  "Condition 2 Property",
  "Condition 2 Operator",
  "Condition 2 Value",
  "Condition 3 Property",
  "Condition 3 Operator",
  "Condition 3 Value",
  "Condition 4 Property",
  "Condition 4 Operator",
  "Condition 4 Value",
  "Condition 5 Property",
  "Condition 5 Operator",
  "Condition 5 Value",
  "Condition 6 Property",
  "Condition 6 Operator",
  "Condition 6 Value",
  "Condition 7 Property",
  "Condition 7 Operator",
  "Condition 7 Value",
  "Condition 8 Property",
  "Condition 8 Operator",
  "Condition 8 Value",
  "Condition 9 Property",
  "Condition 9 Operator",
  "Condition 9 Value",
  "Condition 10 Property",
  "Condition 10 Operator",
  "Condition 10 Value",
]; // Headers

const transformAnswer = (answer) => {
  if (typeof answer === "string") {
    return [answer, ...Array(4).fill("")];
  }

  if (!answer || !Array.isArray(answer)) {
    return Array(5).fill("");
  }

  if (answer.length < 5) {
    const toFillLength = 5 - answer.length;
    return [...answer, ...Array(toFillLength).fill("")];
  } else if (answer.length > 5) {
    return answer.slice(0, 5);
  }
  return answer;
};

const processConditions = (conditions) => {
  const processedConditions: any = [];
  for (let i = 0; i < 10; i++) {
    const condition = conditions[i];
    if (condition) {
      const { property = "", value = "", operator = "" } = condition;
      processedConditions.push(property, operator, value);
    } else {
      processedConditions.push("", "", "");
    }
  }
  return processedConditions;
};

const processActions = (buttonsOrQuickReplies, noOfActions) => {
  const processedActions: any = [];
  for (let i = 0; i < noOfActions; i++) {
    const action = buttonsOrQuickReplies[i];
    if (action) {
      const { text = "", event = "", data = "" } = action;
      processedActions.push(text, event, data);
    } else {
      processedActions.push("", "", "");
    }
  }
  return processedActions;
};

const processNext = (next) => {
  if (!next) {
    return ["", ""];
  }

  const { event = "", data = "" } = next;
  return [event, data];
};

const transformNext = (next) => {
  if (typeof next === "string") {
    return {
      type: "event",
      event: "goto",
      data: next,
    };
  } else if (!next) {
    return {};
  }
  return next;
};

const getContentExportData = (data) => {
  // Content
  const exportData = _.map(data, (contentNode, id) => {
    const versionArr = id.split(":");
    const baseId = versionArr[0];
    const versionId = versionArr.slice(1).join(":");
    const delay = contentNode.delay || "";
    const texts = _.get(contentNode, "content.text", []);
    const answers = transformAnswer(texts);
    const next = transformNext(contentNode.next);
    const buttons = _.get(contentNode, "content.buttons", []);
    const quickReplies = contentNode.quickReplies || [];
    const conditions = contentNode.conditions || [];
    const processedNext = processNext(next);
    const processedButtons = processActions(buttons, 3);
    const processedQuickReplies = processActions(quickReplies, 10);
    const processedConditions = processConditions(conditions);
    const toReturn = [
      baseId,
      versionId,
      ...answers,
      delay,
      ...processedNext,
      ...processedButtons,
      ...processedQuickReplies,
      ...processedConditions,
    ];
    return toReturn;
  });
  return [CONTENT_HEADERS, ...exportData];
};

const getTriggerExportData = (data) => {
  const toExport: any = [];
  toExport.push(TRIGGER_HEADERS);
  const transformTestToString = (tests) => {
    if (tests.length < 1) {
      return "";
    }
    const testsCombined = _.chain(tests)
      .map((test) => {
        return test.text;
      })
      .join(",")
      .value();
    return testsCombined;
  };
  Object.keys(data).forEach((id) => {
    const node = data[id];
    const positiveTests = _.get(node, "tests.positive", []);
    const negativeTests = _.get(node, "tests.negative", []);
    const transformedPositiveTests = transformTestToString(positiveTests);
    const transformedNegativeTests = transformTestToString(negativeTests);
    toExport.push([
      id,
      node.event,
      node.data,
      node.modified,
      node.pattern,
      node.type ? node.type : "text",
      node.context,
      node.priority,
      transformedPositiveTests,
      transformedNegativeTests,
    ]);
  });
};

export { getContentExportData, getTriggerExportData };
