<template>
  <div class="header-container">
    <b v-if="format === 'TEXT'" class="message-template-text">
      {{ previewText }}
    </b>
    <div v-else-if="format === 'DOCUMENT'" class="message-template-document"></div>
    <div v-else-if="format === 'IMAGE'" class="message-template-image"></div>
    <div v-else-if="format === 'VIDEO'" class="message-template-video"></div>
  </div>
</template>

<script>
export default {
  name: "WhatsAppMessageTemplateHeader",
  components: {},
  props: {
    format: {
      type: String,
      required: true,
      validator: function (value) {
        return ["TEXT", "DOCUMENT", "IMAGE", "VIDEO"].includes(value);
      },
    },
    previewText: {
      type: String,
      default: "No Preview Available",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.header-container {
  padding: 10px 10px 10px 10px;
}
.message-template-document {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yG/r/cgs1ZMy80l4.png);
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 240px;
  min-height: 240px;
}

.message-template-image {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yI/r/SJlMxmv94iQ.png);
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 240px;
  min-height: 240px;
}

.message-template-video {
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yF/r/7KVbQIXtKe_.png);
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 240px;
  min-height: 240px;
}

.message-template-text {
  word-break: break-all;
}
</style>
