<template>
  <div class="box">
    <h3>{{ label }}: {{ data }}</h3>
  </div>
</template>
<script>
export default {
  props: ["data", "label"],
};
</script>

<style scoped>
.box {
  font-size: 20px;
  text-align: center;
  padding-top: 50px;
  border: 2px solid #e4392b;
  background: white;
  color: #e4392b;
  height: 100px;
  width: 100%;

  transition: border 0.5s, background 0.5s;
}

.box:hover {
  background: #e4392b;
  color: white;
}
</style>
