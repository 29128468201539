<template>
  <div>
    <template v-if="node">
      <!-- Versioning -->
      <el-alert
        style="margin-bottom: 10px"
        :title="`There are ${siblings.length + 1} versions of this content`"
        type="info"
        :closable="false"
      >
        <span style="display: flex; align-items: flex-start; flex-flow: row wrap">
          <el-button
            round
            :type="id === value ? 'primary' : 'default'"
            size="mini"
            style="margin: 0 5px 5px 0; padding: 5px 10px"
            @click="selectNode(baseId)"
            >{{ baseId }}</el-button
          >
          <el-button
            round
            size="mini"
            style="margin: 0 5px 5px 0; padding: 5px 10px"
            v-for="id in siblings"
            :key="id"
            :type="id === value ? 'primary' : 'default'"
            @click="selectNode(id)"
            >{{ getVersionId(id) }}</el-button
          >

          <!-- Creating new version -->
          <el-popover
            v-model="showNewVersionPopover"
            :disabled="!isAuthorisedForChange"
            placement="bottom"
          >
            <el-input
              v-model="newVersionId"
              autofocus
              @keyup.enter.native="addVersion"
              @keyup.esc.native="showNewVersionPopover = false"
            >
              <span slot="prepend">Version ID</span>
              <el-button slot="append" icon="el-icon-check" @click="addVersion" />
            </el-input>
            <el-button
              round
              size="mini"
              type="primary"
              plain
              slot="reference"
              class="item"
              style="margin: 0 5px 5px 0; padding: 5px 10px"
            >
              <i class="el-icon-plus"></i>
              Add version
            </el-button>
          </el-popover>
        </span>
      </el-alert>

      <!-- Main card -->
      <el-card>
        <!-- Header -->
        <div slot="header">
          <el-row type="flex" justify="space-between">
            <div>
              <ContentNodeEditorBackButton :node="node" />
            </div>

            <div>
              <el-select
                v-model="node.department"
                :disabled="!isAuthorisedForChange"
                multiple
                filterable
                placeholder="Department"
                size="small"
                style="margin-right: 1em"
              >
                <el-option
                  v-for="department in formattedDepartments"
                  :key="department"
                  :label="department"
                  :value="department.toLowerCase()"
                >
                  <span style="float: left">{{ department }}</span>
                  <span style="float: right; width: 20px" />
                </el-option>
              </el-select>

              <el-tooltip effect="dark" content="Save node" placement="bottom">
                <el-button
                  :disabled="!valid || !isAuthorisedForChange"
                  :loading="saving"
                  icon="el-icon-check"
                  :plain="true"
                  size="small"
                  type="success"
                  circle
                  @click="saveChanges"
              /></el-tooltip>
              <el-dropdown @command="add">
                <el-tooltip effect="dark" content="Add new.." placement="top">
                  <el-button
                    class="el-dropdown-link"
                    icon="el-icon-plus"
                    type="primary"
                    plain
                    size="small"
                    circle
                  />
                </el-tooltip>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="textVariation">
                    Text Variation
                  </el-dropdown-item>
                  <!-- <el-dropdown-item command="button">Button</el-dropdown-item> -->
                  <!-- <el-dropdown-item command="quickReply">Quick reply</el-dropdown-item> -->
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="card">
                    Card
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="trigger">
                    Trigger
                  </el-dropdown-item>
                  <el-dropdown-item command="duplicate">Duplicate content</el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="keyword">
                    Keyword
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="gifimage">
                    GIF/Image
                  </el-dropdown-item>
                  <el-dropdown-item :disabled="!isAuthorisedForChange" command="list">
                    List
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                v-if="hasContentHistory"
                :disabled="!isAuthorisedForChange"
                :hide-on-click="false"
              >
                <el-tooltip effect="dark" content="View history" placement="bottom">
                  <el-button :plain="true" icon="el-icon-time" circle />
                </el-tooltip>
                <el-dropdown-menu
                  slot="dropdown"
                  :disabled="!isAuthorisedForChange"
                  style="max-height: 500px; overflow-y: auto"
                >
                  <el-dropdown-item
                    v-for="(obj, index) in changes[id]"
                    :key="index"
                    @click.native="selectVersion(obj.snapshotId)"
                    >{{ obj.formattedSnapshotId }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                v-else
                :plain="true"
                icon="el-icon-time"
                type="info"
                circle
                disabled
                size="small"
              />
              <template v-if="isSystemNode">
                <el-tooltip
                  effect="dark"
                  content="This is a system generated node, rename and delete actions are not available."
                  placement="bottom"
                >
                  <el-button
                    style="margin-left: 0px"
                    icon="el-icon-lock"
                    type="warning"
                    plain
                    circle
                    size="small"
                  />
                </el-tooltip>
              </template>
              <template v-else>
                <el-tooltip effect="dark" content="Delete node" placement="bottom">
                  <el-button
                    type="danger"
                    :disabled="currentNotSelected || !isAuthorisedForChange || isSystemNode"
                    style="margin-left: 0px"
                    :loading="deleting"
                    icon="el-icon-delete"
                    circle
                    size="small"
                    @click="deleteNode"
                  />
                </el-tooltip>
              </template>
            </div>
          </el-row>

          <div>
            <el-input
              v-if="editBaseId"
              v-model="newBaseId"
              style="width: 100%"
              ref="nodeIdEditor"
              @keyup.esc.native="editBaseId = false"
              @keyup.enter.native="renameNode"
              :disabled="node.is_default"
              @blur="renameNode"
            >
              <el-button slot="append" icon="el-icon-check" :loading="saving" @click="renameNode" />
            </el-input>

            <el-tooltip
              v-else
              effect="dark"
              :content="
                node.is_default
                  ? 'System generated node are not able to be renamed.'
                  : 'Change node ID.'
              "
              placement="top"
            >
              <h2
                class="nodeId"
                @click="
                  () => {
                    if (!node.is_default) {
                      editBaseId = true;
                    }
                  }
                "
              >
                {{ baseId }}
              </h2> </el-tooltip
            >&nbsp;
            <span v-if="versionId">
              <el-input
                v-if="editVersionId"
                v-model="newVersionId"
                style="width: 200px"
                @keyup.esc.native="editBaseId = false"
                @keyup.enter.native="renameNode"
              >
                <el-button slot="append" icon="el-icon-check" @click="renameNode" />
              </el-input>

              <el-tooltip v-else effect="dark" content="Change version ID" placement="top">
                <el-tag class="versionTag" type="primary" @click.native="editVersionId = true">{{
                  versionId
                }}</el-tag> </el-tooltip
              >&nbsp;
            </span>
          </div>

          <br />

          <!-- CONDITIONS -->
          <div style="padding: 5px" v-if="node.conditions && node.conditions.length > 0">
            <el-divider style="font-size: 0.7em">Conditions</el-divider>
            <div v-if="identicalSiblings.length" class="duplicate">
              <el-tooltip
                style="margin-right: 5px"
                :content="`This trigger has the same text pattern and same context`"
                placement="top"
              >
                <i class="el-icon-warning" /> </el-tooltip
              >Duplicated condition :
              <strong
                v-for="(identicalSibling, index) in identicalSiblings"
                :key="`siblings-${index}`"
                @click="$emit('select', { type: 'content', id: identicalSibling })"
                >{{ getIdenticalSiblingsDisplay(identicalSibling, index) }}
              </strong>
            </div>
            <el-row
              v-for="(condition, index) in node.conditions"
              :key="index"
              type="flex"
              style="align-items: center; flex-direction: row"
            >
              <el-select
                v-model="condition.property"
                filterable
                allow-create
                default-first-option
                size="small"
                placeholder="Choose a property"
                style="width: 35%; margin-right: 5px"
              >
                <!-- this options list from contentNode.ts // contentNodeOptionsMixin -->
                <el-option-group
                  v-for="(conditionPropertyOption, index) in conditionPropertyOptionsDynamic"
                  :key="`condition-property-group-option-${index}`"
                  :label="conditionPropertyOption.name"
                >
                  <el-option
                    v-for="(property, propertyIndex) in conditionPropertyOption.options"
                    :key="`condition-property-option-${propertyIndex}`"
                    :label="property.name"
                    :value="property.value"
                  ></el-option>
                </el-option-group>
              </el-select>
              <el-select
                placeholder="Operator"
                filterable
                :value="condition.operator"
                @change="changeOperator(condition, $event)"
                size="small"
                style="width: 15%; margin-right: 5px"
              >
                <el-option
                  :key="`condition-operator-option-${index}`"
                  v-for="(conditionOperatorOption, index) in filterValidOperator(
                    condition.valueType
                  )"
                  :value="conditionOperatorOption.value"
                  :label="conditionOperatorOption.name"
                />
              </el-select>

              <el-select
                :key="
                  ['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)
                    ? 'select-multiple'
                    : 'select-single'
                "
                v-if="showEntitySelector(condition)"
                size="small"
                filterable
                :multiple="['INCLUDED IN', 'NOT INCLUDED IN'].includes(condition.operator)"
                v-model="condition.value"
                placeholder="Select Entity"
              >
                <el-option
                  v-for="item in entityValues(condition)"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div
                style="display: flex; align-items: center; width: 45%"
                v-else-if="!['EXISTS', 'NOT EXISTS'].includes(condition.operator)"
              >
                <el-select
                  class="value-type"
                  placeholder="Type"
                  :value="condition.valueType || 'JSON'"
                  @change="changeValueType(condition, $event)"
                  filterable
                  size="small"
                  style="width: 30%; margin-right: -1px"
                >
                  <el-option
                    :key="`condition-operator-option-${index}`"
                    v-for="(conditionOperatorOption, index) in conditionValueTypeOptions"
                    :value="conditionOperatorOption.value"
                    :label="conditionOperatorOption.name"
                  />
                </el-select>
                <el-input-number
                  class="value-field"
                  v-if="condition.valueType === 'NUMBER'"
                  style="width: 65%; margin-right: 5px"
                  v-model="condition.value"
                  size="small"
                  controls-position="right"
                />
                <el-select
                  class="value-field"
                  v-else-if="condition.valueType === 'BOOLEAN'"
                  placeholder="Value Type"
                  v-model="condition.value"
                  filterable
                  size="small"
                  style="width: 65%; margin-right: 5px"
                >
                  <el-option :value="false" label="FALSE" />
                  <el-option :value="true" label="TRUE" />
                </el-select>
                <el-autocomplete
                  class="value-field"
                  v-else-if="condition.valueType === 'JSON'"
                  style="width: 65%; margin-right: 5px"
                  v-model="condition.value"
                  type="textarea"
                  rows="1"
                  size="small"
                  :fetch-suggestions="querySearch(condition)"
                />
                <el-autocomplete
                  v-else
                  class="value-field"
                  style="width: 65%; margin-right: 5px"
                  v-model="condition.value"
                  size="small"
                  :fetch-suggestions="querySearch(condition)"
                />
              </div>
              <el-button
                type="text"
                icon="el-icon-delete"
                size="mini"
                circle
                @click="removeCondition(index)"
              />
            </el-row>
          </div>

          <el-row>
            <el-button round @click.prevent="addCondition" size="mini" type="primary" plain>
              <i class="el-icon-plus"></i>
              Add Condition
            </el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="Valid operators depends on value type selected"
              placement="right"
            >
              <i class="el-icon-info" style="color: grey; margin-left: 5px"></i>
            </el-tooltip>
          </el-row>

          <!-- KEYWORDS -->
          <div
            v-if="(node.keywords && node.keywords.length > 0) || editKeyword"
            style="padding: 5px"
          >
            <span style="font-weight: bold; font-size: 0.7em">Keywords</span>
            <el-row>
              <el-col>
                <el-tag
                  v-for="(keyword, index) in node.keywords"
                  :key="index"
                  type="primary"
                  class="keywordTag"
                  :closable="true"
                  @click="editKeyword = true"
                  @close="deleteKeyword(index)"
                  >{{ keyword.value }}</el-tag
                >
                <el-input
                  v-if="editKeyword"
                  v-model="newKeyword.value"
                  size="mini"
                  style="width: 200px"
                  @keyup.esc.native="editKeyword = false"
                  @keyup.enter.native="newKeywordConfirm(newKeyword)"
                  @blur="newKeywordConfirm(newKeyword)"
                />
                <el-button
                  v-else
                  class="button-new-keyword"
                  size="small"
                  @click="editKeyword = true"
                  >+ New Keyword</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>

        <!-- Content -->
        <ContentNode :node="node" />

        <br />

        <!-- Quick Reply Display Mode Options for Webchat (will overwrite global config in webchat module) -->
        <div
          v-if="$store.state.showAdvanced"
          style="width: 100%; text-align: center; margin-top: 10px; margin-bottom: 10px"
        >
          <QuickReplyDisplayMode @updateNode="updateNode" :node="node" />
        </div>

        <JSONEditor
          v-if="$store.state.showAdvanced"
          ref="jsonEditor"
          v-model="node"
          @valid="valid = true"
          @invalid="valid = false"
        />
      </el-card>

      <!-- Select User Feedback Mode for Webchat -->
      <div
        v-if="feedbackCollectionOnContentNode"
        style="width: 100%; text-align: center; margin-top: 10px"
      >
        <UserFeedbackMode @updateNode="updateNode" :node="node" />
      </div>

      <!-- Others -->
      <div v-if="node && node.next !== undefined && depth() < 5">
        <div style="width: 100px; margin: auto; text-align: center">
          <div>
            <el-slider
              v-if="node.delay"
              v-model="node.delay"
              label="delay"
              :min="1"
              :max="60"
              :format-tooltip="(i) => `${i} seconds`"
            />

            <el-button v-if="node.delay" type="text" icon="el-icon-close" @click="removeDelay"
              >Delay</el-button
            >
            <el-button v-else type="text" icon="el-icon-plus" @click="addDelay">Delay</el-button>
          </div>

          <el-steps
            direction="vertical"
            :active="2"
            style="width: 24px; height: 100px; margin: auto"
          >
            <el-step />
            <el-step />
          </el-steps>
          <el-button type="text" icon="el-icon-close" @click="removeNext">Link</el-button>
        </div>

        <div v-if="node.next">
          <el-card style="margin: 20px auto; width: 500px">
            <EventNodeSelector v-model="node.next" />
            <!-- @save-side-effect="$emit('save-side-effect')"  -->
          </el-card>

          <ContentNodeEditor v-if="typeof node.next === 'string'" v-model="node.next" />
          <ContentNodeEditor v-else-if="node.next.event === 'goto'" v-model="node.next.data" />
        </div>
      </div>

      <div v-else style="width: 100%; text-align: center; margin-top: 10px">
        <!-- Toggle User Input for Webchat -->
        <el-checkbox @change="toggleUserInput" :value="!node.options.disableTextInput"
          >Allow user input</el-checkbox
        >

        <br />

        <el-button type="text" icon="el-icon-plus" @click="addNext">Next step</el-button>
      </div>
    </template>
    <template v-else>
      <el-card style="max-width: 50%; margin: 20px auto">
        <div v-if="id">
          <p>
            Content
            <el-tag>{{ id }}</el-tag
            >does not exist.
          </p>
          <p>Do you want to create it?</p>
          <el-button type="primary" :plain="true" @click="id = ''">Cancel</el-button>

          <el-button type="primary" @click="createContentNode()">Create</el-button>
        </div>
        <div v-else>
          <el-autocomplete
            v-model="tempNode.id"
            style="width: 70%"
            placeholder="New name"
            :fetch-suggestions="getContentNodes"
          >
            <div slot="prepend">Name</div>
          </el-autocomplete>
          <el-button v-if="nodeExist" type="primary" @click="id = tempNode.id">Load</el-button>
          <el-button v-else type="primary" @click="createContentNode()">Create</el-button>
        </div>
      </el-card>
    </template>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import Component from "vue-class-component";
import JSONEditor from "@/components/JSONEditor";
import EventNodeSelector from "@/components/EventNodeSelector/Index";
import {
  contentNodeConditionMethodMixin,
  contentNodeConditionOptionsDataMixin,
  contentNodeConditionOptionsComputedMixin,
} from "@/mixins/contentNode";
import ContentNode from "@/components/ContentNode";
import QuickReplyDisplayMode from "@/components/QuickReplyDisplayMode";
import UserFeedbackMode from "@/components/UserFeedbackMode";
import { getDefaultPlaceholderImage } from "@/helperMethods/util";
import ContentNodeEditorBackButton from "@/components/ContentNodeEditorBackButton.vue";

//if we use class component then no need to import same component
//import ContentNodeEditor from "@/components/ContentNodeEditor";
import * as moment from "moment";

@Component({
  name: "ContentNodeEditor",
  mixins: [
    contentNodeConditionOptionsDataMixin,
    contentNodeConditionMethodMixin,
    contentNodeConditionOptionsComputedMixin,
  ],
  components: {
    QuickReplies: () => import("@/components/QuickReplies"),
    JSONEditor,
    EventNodeSelector,
    ContentNodeEditor: () => import("@/components/ContentNodeEditor"),
    ContentNode,
    QuickReplyDisplayMode,
    UserFeedbackMode,
    ContentNodeEditorBackButton,
  },
  props: ["value"],
  methods: {
    changeOperator(condition, value) {
      condition.operator = value;
      if (value.includes("EXISTS")) {
        condition.valueType = "JSON";
        condition.value = "";
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    filterValidOperator(valueType) {
      return this.conditionOperatorOptions.filter(
        (item) => !valueType || item.valid.includes(valueType)
      );
    },
    changeValueType(condition, value) {
      condition.valueType = value;
      const operatorStillValid = this.conditionOperatorOptions.find(
        (item) => item.value === condition.operator && item.valid.includes(value)
      );
      if (!operatorStillValid) {
        condition.operator = "";
      }
      if (value === "STRING" || value === "JSON") {
        condition.value = "";
      }
      if (value === "NUMBER") {
        condition.value = 0;
      }
      if (value === "BOOLEAN") {
        condition.value = false;
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    updateNode(newNode) {
      this.node = newNode;
      this.$forceUpdate();
    },
    toggleUserInput(value) {
      _.set(this.node, "options.disableTextInput", !value);
      this.$forceUpdate();
    },
    getIdenticalSiblingsDisplay(siblingId, i) {
      return `${siblingId}${i !== this.identicalSiblings.length - 1 ? ", " : ""}`;
    },
  },
  computed: {
    departments() {
      return this.$store.getters.userDepartment;
    },
    isSystemNode() {
      return this.node.is_default && !this.newVersionId;
    },
    formattedDepartments() {
      const departments = this.departments;
      let formattedDepartments = [];
      if (departments) {
        formattedDepartments = _.map(departments, (dept) => {
          return dept
            .split(" ")
            .map((word) => _.capitalize(word))
            .join(" ")
            .trim();
        });

        formattedDepartments = _.uniq(formattedDepartments);
      }
      return formattedDepartments;
    },

    nodeHistoryType: {
      get() {
        if (!this.$store.state.activeNodeHistoryType) {
          this.$set(this.$store.state, "activeNodeHistoryType", "Current");
        }
        return this.$store.state.activeNodeHistoryType;
      },
      set(value) {
        this.$store.state.activeNodeHistoryType = value;
      },
    },
    id: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.value = value;
        this.$emit("input", value);
      },
    },
    node: {
      get() {
        this.newNodeId = this.id;
        this.editNodeId = false;
        // if (this.origId == "" || this.origId != this.id) {
        //   this.origId = this.id;
        //   this.origNode = this.$store.state.nodes.content[this.id];
        // }
        this.newBaseId = this.baseId;
        this.newVersionId = this.versionId;

        this.editBaseId = false;
        this.editVersionId = false;

        const existingNode = this.$store.state.nodes.content[this.id];
        if (existingNode) {
          if (this.old_node_id !== this.id && !this.renameContentId) {
            this.old_node_id = this.id;
            this.old_node = _.cloneDeep(existingNode);
          }
          if (!existingNode.department || existingNode.department.length === 0)
            existingNode.department = ["general"];

          if (
            existingNode.department &&
            existingNode.department.length > 1 &&
            existingNode.department[0] === "general"
          ) {
            existingNode.department.splice(0, 1);
          }

          if (!existingNode.options) {
            existingNode.options = {
              disableTextInput: false,
              feedbackCollectionMode: "default",
            };
          }

          if (typeof existingNode.next === "string") {
            existingNode.next = {
              event: "goto",
              data: existingNode.next,
            };
          }

          if (existingNode.type === "card" && Array.isArray(existingNode.content)) {
            // Add default options to cards
            existingNode.content = _.map(existingNode.content, (item) => {
              const itemIsString = typeof item === "string";
              if (!itemIsString && !item.options) {
                return {
                  ...item,
                  options: {
                    padded: false,
                  },
                };
              }
              return item;
            });
          }

          if (!existingNode.id) {
            existingNode.id = this.id;
          }

          return existingNode;
        } else {
          this.tempNode.id = this.id;
          return null;
        }
      },
      set(value) {
        this.$store.state.nodes.content[this.id] = value;
      },
    },
    isAuthorisedForChange: {
      get() {
        const result =
          this.departments &&
          (_.intersection(this.departments, this.node.department).length > 0 ||
            this.node.department.includes("general"));
        return result;
      },
    },
    identicalSiblings() {
      const existingNode = this.$store.state.nodes.content[this.id];
      const existingNodeConditions = _.get(existingNode, "conditions");
      const allVersions = [this.baseId, ...this.siblings];
      const identicalSiblings = _.chain(allVersions)
        .filter((v) => v !== this.id)
        .map((nodeId) => ({
          conditions: _.get(this.$store.state.nodes.content, `[${nodeId}].conditions`, []),
          nodeId,
        }))
        .filter((nodeConditions) =>
          _.chain(nodeConditions)
            .cloneDeep()
            .omit("nodeId")
            .get("conditions", [])
            .isEqual(existingNodeConditions)
            .value()
        )
        .map("nodeId")
        .value();
      return identicalSiblings;
    },
  },
})
export default class ContentNodeEditor extends Vue {
  nodeType = "content";
  selectedCardIndex = 0;
  editBaseId = false;
  editVersionId = false;
  editKeyword = false;
  newBaseId = "";
  newVersionId = "";
  old_node_id = "";
  old_node = {};
  renameContentId = false;
  saving = false;
  deleting = false;
  valid = true;
  tempNode = {
    id: "",
    content: {},
  };
  origNode = "";
  origId = "";
  newKeyword = {};
  showNewVersionPopover = false;

  // FIXME: doesn't work anymore. Does Snapshots still work in SQL Server?
  get hasContentHistory() {
    return this.changes && this.changes[this.id] && this.changes[this.id].length > 1;
  }
  get currentNotSelected() {
    return this.nodeHistoryType !== "Current" || !this.nodeHistoryType;
  }

  // FIXME: doesn't work anymore. Does Snapshots still work in SQL Server?
  get changes() {
    if (!this.$store.state.nodes.changes) {
      return {};
    } else {
      const clone = _.cloneDeep(this.$store.state.nodes.changes);
      _.each(Object.keys(clone), (content) => {
        const arr = clone[content];
        if (arr) {
          _.each(arr, (obj) => {
            obj.formattedSnapshotId =
              obj.snapshotId === "Current"
                ? "Current"
                : `${moment(obj.snapshotId).format("Do MMM YY, h:mm:ss a")} (${moment().to(
                    moment(obj.snapshotId)
                  )})`;
          });
        }
      });
      return clone;
    }
  }
  get idParts() {
    if (this.value && typeof this.value === "string") {
      return this.value.split(":");
    }
    return this.value;
  }
  get baseId() {
    return _.get(this, "idParts[0]", "");
  }
  get versionId() {
    return _.get(this, "idParts[1]", "");
  }
  get siblings() {
    return _.keys(this.$store.state.nodes.content).filter(
      (nodeId) => nodeId.indexOf(this.baseId + ":") === 0
    );
  }
  get nodeExist() {
    return this.$store.state.nodes.content[this.tempNode.id];
  }
  get feedbackCollectionOnContentNode() {
    return _.get(this, "$store.state.modules.faq.feedbackCollection.enabledForContentNode", false);
  }

  getVersionId(id) {
    let parts = id.split(":");
    if (parts.length > 1) {
      return parts[1];
    } else {
      return id;
    }
  }

  removeCondition(index) {
    this.node.conditions.splice(index, 1);
  }
  selectNode(id) {
    this.$store.dispatch("SELECT_NODE", { type: "content", id });
  }
  back() {
    this.$store.dispatch("SELECT_PREVIOUS_NODE");
  }
  depth() {
    let count = 1;
    let parent = this.$parent.$parent;
    while (parent.id) {
      count++;
      parent = parent.$parent.$parent;
    }

    return count;
  }
  getContentNodes(value, cb) {
    cb(this.$store.state.search.content.search(value || ""));
  }

  add(command) {
    // console.log("Extensible " + Object.isExtensible(this.node));
    switch (command) {
      case "image":
        this.addImage();
        break;

      case "textVariation":
        this.addTextVariation();
        break;

      case "quickReply":
        this.addQuickReply();
        break;

      case "trigger":
        this.addTrigger();
        break;

      case "button":
        this.addButton();
        break;

      case "card":
        this.node.type = "card";

        if (Array.isArray(this.node.content)) {
          this.node.content.push({
            image: getDefaultPlaceholderImage(),
            text: "",
            subtext: "",
            buttons: [],
            options: {
              padded: false,
            },
          });
        } else {
          if (Array.isArray(this.node.content.text)) {
            let replaceText = "";
            this.node.content.text.forEach((text, index) => {
              if (index === 0) {
                replaceText += text;
              } else {
                replaceText += `\n${text}`;
              }
            });
            this.node.content.text = replaceText;
          }
          this.node.content = [
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
              options: {
                padded: false,
              },
              ...this.node.content,
            },
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [
                {
                  text: "",
                  event: "goto",
                  data: "menu",
                },
              ],
              options: {
                padded: false,
              },
            },
          ];
        }
        break;

      case "duplicate":
        this.duplicateNode();
        break;

      case "keyword":
        this.addKeyword();
        break;

      case "gifimage":
        this.node.content = {
          image: getDefaultPlaceholderImage(),
        };
        break;

      case "list":
        if (!this.node.buttons) {
          this.$set(this.node, "buttons", []);
        }
        this.node.type = "list";
        this.node.listStyle = "compact";
        if (Array.isArray(this.node.content)) {
          this.node.content.push({
            image: getDefaultPlaceholderImage(),
            text: "",
            subtext: "",
            buttons: [],
          });
        } else {
          if (Array.isArray(this.node.content.text)) {
            let replaceText = "";
            this.node.content.text.forEach((text, index) => {
              if (index === 0) {
                replaceText += text;
              } else {
                replaceText += `\n${text}`;
              }
            });
            this.node.content.text = replaceText;
          }
          this.node.content = [
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [],
              ...this.node.content,
            },
            {
              image: getDefaultPlaceholderImage(),
              text: "",
              subtext: "",
              buttons: [
                {
                  text: "",
                  event: "goto",
                  data: "menu",
                },
              ],
            },
          ];
        }
        break;
    }
  }

  deleteNode() {
    this.$confirm("This will delete the node. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "info",
    }).then(() => {
      this.deleting = true;
      let payload = {
        type: this.nodeType,
        id: this.id,
        old_values: {
          id: this.old_node_id,
          type: this.nodeType,
          node: this.old_node,
        },
      };
      return this.$store
        .dispatch("DELETE_NODE", payload)
        .then(() => {
          this.deleting = false;
          this.$message({
            type: "success",
            message: "Node deleted",
          });
          this.$store.commit("SET_REFETCH_CONTENT_NODE", true);
        })
        .catch(() => {
          this.deleting = false;
          this.$message({
            type: "error",
            message: "Failed to delete node from server",
          });
        });
    });
  }
  renameNode() {
    const newId = _.compact([this.newBaseId, this.newVersionId]).join(":");
    const oldId = this.id;

    const idChanged = oldId !== newId;
    if (idChanged) {
      this.renameContentId = true;
      this.old_node_id = oldId;
      this.$store.commit("RENAME_NODE", {
        type: this.nodeType,
        oldId,
        newId,
      });

      this.$store.dispatch("SELECT_NODE", { type: this.nodeType, id: newId });
      this.$nextTick(() => {
        this.old_node_id = oldId;
        this.saveChanges();

        this.$store.dispatch("DELETE_RENAMED_OLD_NODE", {
          type: this.nodeType,
          id: oldId,
        });
      });
    }

    this.editBaseId = false;
    this.editVersionId = false;
  }
  saveChanges() {
    if (this.nodeHistoryType !== "Current") {
      this.$confirm(
        "Are you sure you want to overwrite the current node with one from an older snapshot?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          this.saveContentNode();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Overwrite cancelled",
          });
        });
    } else {
      this.saveContentNode();
    }
  }
  flagOutMakerCheckerWarning() {
    const makerCheckerEnabled = _.get(this, "$store.state.modules.makerChecker.enabled");
    const contentNodeIsEqual = _.isEqual(this.node, this.old_node);
    const IdIsEqual = _.isEqual(this.id, this.old_node_id);
    if (contentNodeIsEqual && IdIsEqual && makerCheckerEnabled) {
      this.$notify.warning({
        title: "No Change Detected",
        message: "No change is created",
        position: "bottom-right",
      });
    }
  }
  saveContentNode() {
    this.saving = true;
    this.flagOutMakerCheckerWarning();

    const contentButtons = this.node.content.buttons;
    if (!_.isEmpty(contentButtons)) {
      for (let i = 0; i < this.node.content.buttons.length; i++) {
        delete this.node.content.buttons[i].active;
      }
    }

    this.$store
      .dispatch("EDIT_NODE", {
        type: this.nodeType,
        id: this.id,
        node: this.node,
        old_values: {
          id: this.old_node_id,
          type: this.nodeType,
          node: this.old_node,
        },
        new_values: {
          type: this.nodeType,
          id: this.id,
          node: this.node,
        },
        isRename: this.renameContentId,
      })
      .then(() => {
        this.$store.commit("SET_CHANGE_CONTENT_NODE", true);
        // this.$store.dispatch("FETCH_CONTENT_SNAPSHOTS", {
        //   brain: this.$store.state.brain
        // });
        this.saving = false;
        this.$message({
          type: "success",
          message: "Content Saved",
        });

        // updating node to be old_node after save

        this.$nextTick(function () {
          const theNewOld = _.cloneDeep(this.node);
          this.old_node = theNewOld;
        });
      })
      .catch((err) => {
        this.saving = false;
        this.$message({
          type: "error",
          message: "Error Saving content",
        });
      })
      .then(() => {
        this.renameContentId = false;
      });
  }
  addImage() {
    if (!this.node.content.image) {
      this.$set(this.node.content, "image", "");
    }

    this.node.content.image = getDefaultPlaceholderImage();
  }
  addVersion() {
    const newId = this.baseId + ":" + this.newVersionId;
    let newNode = _.cloneDeep(this.node);

    this.$store.commit("CREATE_CONTENT_NODE", {
      id: newId,
      node: newNode,
    });

    this.$message({
      type: "success",
      message: `Created new version`,
    });

    this.$emit("select", { type: "content", id: newId });

    this.newVersionId = "";
    this.showNewVersionPopover = false;
  }
  addTextVariation() {
    if (!Array.isArray(this.node.content)) {
      if (Array.isArray(this.node.content.text)) {
        this.node.content.text.push("");
      } else {
        this.node.content.text = [this.node.content.text, ""];
      }
    } else {
      console.warn("Cannot add variation");
    }
  }
  addButton() {
    if (Array.isArray(this.node.content)) {
      let index = this.selectedCardIndex || 0;
      let card = this.node.content[index];
      if (!card.buttons) {
        this.$set(card, "buttons", []);
      }

      card.buttons.push({
        text: "",
        type: "event",
        active: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    } else {
      if (!this.node.content.buttons) {
        this.$set(this.node.content, "buttons", []);
      }

      this.node.content.buttons.push({
        text: "",
        type: "event",
        active: "event",
        event: null,
        data: null,
        url: null,
        phone: null,
        webview_height_ratio: null,
      });
    }
  }
  addQuickReply() {
    if (!this.node.quickReplies) {
      this.$set(this.node, "quickReplies", []);
    }

    this.node.quickReplies.push({
      text: "Quick Reply",
      event: null,
      data: null,
    });
  }
  addTrigger() {
    this.$store.dispatch("CREATE_TRIGGER_NODE", {
      id: this.id,
      gotoTarget: this.id,
    });
    this.$store.state.activeNodeType = "trigger";
  }
  addNext() {
    this.$set(this.node, "next", {
      type: "event",
      event: null,
      data: null,
    });
  }
  removeNext() {
    this.$delete(this.node, "next");
  }
  addDelay() {
    this.$set(this.node, "delay", 1);
  }
  removeDelay() {
    this.$delete(this.node, "delay");
  }
  duplicateNode() {
    let newName = this.id + "_copy";

    this.$message({
      message: `Created ${newName}`,
    });

    this.$store.commit("CREATE_CONTENT_NODE", {
      id: newName,
      node: _.cloneDeep(this.node),
    });
    this.$emit("select", { type: "content", id: newName });
  }
  getEventOptions() {
    let options = {};
    this.$store.state.nodes.event.forEach(function (el) {
      options[el] = el;
    });
    return options;
  }
  createContentNode() {
    this.$store.commit("CREATE_CONTENT_NODE", { id: this.tempNode.id });
    this.id = this.tempNode.id;
    this.tempNode.id = "";
    this.$emit("select", { type: "content", id: this.tempNode.id });
    this.selectNode(this.id);
  }
  addCondition() {
    if (!this.node.conditions) {
      this.$set(this.node, "conditions", []);
    }
    this.node.conditions.push({
      property: "",
      value: "",
      operator: "",
      valueType: "JSON",
    });
  }
  selectVersion(version) {
    this.nodeHistoryType = version;
    // if (version === "Current") {
    //   this.node = this.origNode;
    // } else {
    //   this.node = this.changes[this.id][version];
    // }
    this.node = _.cloneDeep(_.find(this.changes[this.id], (o) => o.snapshotId === version));
  }
  addKeyword() {
    if (!this.node.keywords) {
      this.$set(this.node, "keywords", []);
    }

    this.editKeyword = true;
  }
  deleteKeyword(index) {
    this.node.keywords.splice(index, 1);
    this.editKeyword = false;
    this.newKeyword = {};
  }
  newKeywordConfirm(confirmedKeyword) {
    const allKeywords = this.$store.state.nodes.keywords;

    if (!confirmedKeyword) {
      this.newKeyword = {};
      this.editKeyword = false;
    }

    if (confirmedKeyword.value) {
      if (
        (_.get(this, "node.keywords") &&
          _.find(this.node.keywords, { value: confirmedKeyword.value })) ||
        allKeywords[confirmedKeyword.value]
      ) {
        this.$message.error(
          `Please type unique keyword only. Same keyword has been applied to [${
            allKeywords[confirmedKeyword.value]
          }]`
        );
        this.newKeyword = {};
        return;
      }

      confirmedKeyword.value = confirmedKeyword.value.toLowerCase();
      confirmedKeyword.edit = false;
      this.node.keywords = [...this.node.keywords, confirmedKeyword];
      this.newKeyword = {};
      this.editKeyword = false;
    }
  }
}
</script>

<style lang="scss">
.nodeId {
  cursor: pointer;
  line-height: 36px;
  word-break: break-all;
}

.nodeId:hover {
  color: deepskyblue;
}

.keyreply-conditions-row button {
  opacity: 0;
  margin-left: 5px;
}
.keyreply-conditions-row:hover button {
  opacity: 1;
}

.el-card__header .versionTag,
.el-card__header span .el-input {
  position: relative;
  bottom: 3px;
}

.el-card__header .keywordTag {
  margin-right: 3px;
}

.image-media {
  text-align: center;
}
.value-type .el-input__inner {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.value-field .el-input__inner,
.value-field .el-textarea__inner {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.el-select.value-type .el-input.is-focus .el-input__inner {
  border-color: #d8dce5;
}
.el-select.value-type:hover .el-input__inner {
  border-color: #d8dce5;
}
.el-select.value-type .el-input__inner:focus {
  border-color: #d8dce5;
}
</style>

<style scoped>
.duplicate {
  margin: 0 0 20px 0;
  color: #e4392b;
}
.duplicate strong {
  cursor: pointer;
}
</style>
