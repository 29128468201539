// Currently only use in translate -> languages
<template>
  <div>
    <span
      v-if="mode !== 'form'"
      style="margin-left: 1.5em; margin-right: 3.5em; color: #909399; fontsize: 14px"
    >
      {{ setting.label || name }}
    </span>
    <el-select v-model="value[name]" multiple filterable>
      <el-option
        v-for="item in setting.options"
        :key="getItemKey(item)"
        :label="getItemLabel(item)"
        :value="getItemValue(item)"
      >
        <span :style="{ float: 'left' }">{{ getItemLabel(item) }}</span>
        <span
          v-if="setting.displayValue"
          :style="{
            float: 'right',
            color: item.color ? item.color : '#909399',
            fontSize: 13 + 'px',
            marginLeft: '1.5em',
          }"
          >{{ item.code ? item.code : item.name }}</span
        >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: ["value", "setting", "name", "mode"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
  methods: {
    getItemLabel(item) {
      return item.name || item.label;
    },
    getItemKey(item) {
      return item.code || item.value;
    },
    getItemValue(item) {
      return item.code || item.value ? this.getItemKey(item) : this.getItemLabel(item);
    },
  },
};
</script>
