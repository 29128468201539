import marked from "marked";
import dompurify from "dompurify";

const renderer = new marked.Renderer();

dompurify.addHook("afterSanitizeAttributes", function (node) {
  if (node.hasAttribute("xlink:href") || node.hasAttribute("href")) {
    node.setAttribute("target", "_blank");
  }
});

export const isHTML = (text: string): boolean => {
  const isHtmlRegex = /<\/?[a-z][\s\S]*>|(<\/[a-z][\s\S]*>|.+\/>)/i;
  return isHtmlRegex.test(text);
};

export const mdToHtml = (content: string, agentTextColor: string = "black") => {
  renderer.link = function (href, title, text) {
    return `<a href='${href}' title='${title}' style='color: ${agentTextColor};' target='_blank' on-click="event.stopPropagation();">${text}</a>`;
  };
  const html = marked(content, {
    renderer,
    gfm: true,
    breaks: true,
  });
  return dompurify.sanitize(html);
};
