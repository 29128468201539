<template>
  <el-popover
    @after-enter="afterEnter"
    @after-leave="afterLeave"
    placement="bottom-start"
    width="200"
    trigger="hover"
  >
    <el-button
      @click="back"
      :plain="true"
      icon="el-icon-back"
      circle
      size="mini"
      slot="reference"
    />
    <div>
      <div class="section-container" v-if="$store.state.nodeNavigationStack.length > 0">
        <small class="section-title">History</small>
        <small
          v-for="(breadcrumb, index) in $store.state.nodeNavigationStack"
          :key="index"
          style="cursor: pointer"
          @click="onContentClick(breadcrumb.id)"
        >
          {{ breadcrumb.id }}
        </small>
      </div>
      <div class="section-container" v-if="intents.length > 0">
        <small class="section-title">FAQs</small>
        <small
          @click="onIntentClick(intent.value)"
          v-for="(intent, index) in intents"
          style="cursor: pointer"
          :key="`intent-${index}`"
        >
          {{ intent.label }}
        </small>
      </div>
      <div class="section-container" v-if="triggerNodes.length > 0">
        <small class="section-title">Trigger Nodes</small>
        <small
          @click="onTriggerClick(trigger.value)"
          v-for="(trigger, index) in triggerNodes"
          style="cursor: pointer"
          :key="`trigger-${index}`"
        >
          {{ trigger.label }}
        </small>
      </div>
      <div class="section-container" v-if="contentNodes.length > 0">
        <small class="section-title">Content Nodes</small>
        <small
          @click="onContentClick(content.value)"
          style="cursor: pointer"
          v-for="(content, index) in contentNodes"
          :key="`content-${index}`"
        >
          {{ content.label }}
        </small>
      </div>
      <div class="section-container" v-if="apis.length > 0">
        <small class="section-title">API</small>
        <small
          @click="onApiClick(api.value)"
          style="cursor: pointer"
          v-for="(api, index) in apis"
          :key="`api-${index}`"
        >
          {{ api.label }}
        </small>
      </div>
    </div>
  </el-popover>
</template>

<script>
import _ from "lodash";

export default {
  props: ["node"],
  computed: {
    intents() {
      return _.get(this.$store.getters, "connectedNodes.data.intents") || [];
    },
    triggerNodes() {
      return _.get(this.$store.getters, "connectedNodes.data.triggers") || [];
    },
    contentNodes() {
      return _.get(this.$store.getters, "connectedNodes.data.contents") || [];
    },
    apis() {
      return _.get(this.$store.getters, "connectedNodes.data.apis") || [];
    },
  },
  methods: {
    afterLeave() {
      this.$store.commit("SET_CONNECTED_NODES_DATA", {});
    },
    afterEnter() {
      this.$store.dispatch("GET_CONNECTED_NODES", this.node.id);
    },
    back() {
      if (this.$store.state.nodeNavigationStack.length > 0) {
        this.$store.dispatch("SELECT_PREVIOUS_NODE");
      }
    },
    onTriggerClick(id) {
      this.$store.dispatch("SELECT_NODE", { type: "trigger", id });
    },
    onContentClick(id) {
      this.$store.dispatch("SELECT_NODE", { type: "content", id });
    },
    onIntentClick(id) {
      this.$router.push(`/faq2?search=${id}`);
    },
    onApiClick(id) {
      this.$router.push(`/editor/integrations?search=${id}`);
      this.$eventBus.$emit("editorSwitchTab", "apieditor");
    },
  },
  mounted() {},
};
</script>

<style scoped>
small {
  display: block;
}
.section-title {
  font-weight: bold;
  font-style: italic;
  margin-bottom: 5px;
  color: #bdbdbd;
}
.section-container {
  margin-bottom: 15px;
}
</style>
