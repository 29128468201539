<template>
  <div>
    <el-popover trigger="hover" placement="bottom">
      <div style="margin-bottom: 10px">
        <h3 style="display: block; margin: 5px 0">Move example to improve accuracy</h3>
        <small style="color: #grey"
          >Please choose one resolution to apply and reduce the conflict</small
        >
      </div>

      <fieldset>
        <legend style="color: #e4392b">selected example</legend>
        <i>"{{ scope.row.text }}"</i>
      </fieldset>
      <table class="intent-info">
        <tr>
          <td>Current intent:</td>
          <td>
            <el-tag type="danger" effect="plain" style="border: none">
              {{ selectedIntent.intentName }}
            </el-tag>
          </td>
        </tr>
        <tr>
          <td>Target intent:</td>
          <td>
            <el-tag v-if="destinationIntent" type="danger" effect="plain">
              {{ destinationIntent.name }}
            </el-tag>
          </td>
          <td style="text-align: right">
            <el-button
              plain
              type="primary"
              size="mini"
              @click.native="$emit('moveExample', scope.row)"
              >Apply this resolution</el-button
            >
          </td>
        </tr>
      </table>
      <template v-if="destinationIntent">
        <fieldset>
          <legend style="color: #e4392b">conflict example</legend>
          <i>"{{ destinationIntent.text }}"</i>
        </fieldset>
        <table class="intent-info">
          <tr>
            <td>Current intent:</td>
            <td>
              <el-tag type="danger" effect="plain" style="border: none">
                {{ destinationIntent.name }}
              </el-tag>
            </td>
          </tr>
          <tr>
            <td>Target intent:</td>
            <td>
              <el-tag type="danger" effect="plain">
                {{ selectedIntent.intentName }}
              </el-tag>
            </td>
            <td style="text-align: right">
              <el-button
                plain
                type="primary"
                size="mini"
                :disabled="!destinationIntentVariation || !destinationIntent"
                @click.native="
                  $emit('moveExample', destinationIntentVariation, destinationIntent.name)
                "
                >Apply this resolution</el-button
              >
            </td>
          </tr>
        </table>
      </template>
      <el-button size="mini" icon="el-icon-rank" slot="reference">
        Move <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
    </el-popover>
    <el-button
      type="primary"
      icon="el-icon-delete"
      size="mini"
      style="margin-left: 10px"
      @click="$emit('deleteVariation', selectedIntent, scope.row.text)"
    >
      Delete
    </el-button>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { faqDatasetMixin, faqConflictedMixin } from "@/mixins/faq";

export default Vue.extend({
  name: "FaqConflictedButtonAction",
  props: ["scope", "selectedIntent"],
  mixins: [faqDatasetMixin, faqConflictedMixin],
  computed: {
    destinationIntent() {
      const { intentName } = this.selectedIntent;
      const {
        row: { intent_ranking },
      } = this.scope;
      if (intentName !== intent_ranking[0].name) {
        // Go to first intent if its already not equals to current intent
        return {
          name: intent_ranking[0].name,
          text: intent_ranking[0].example,
        };
      }

      if (this.isPotentiallyConflicted(intent_ranking)) {
        // Go to second intent if it has a potential conflict
        return {
          name: intent_ranking[1].name,
          text: intent_ranking[1].example,
        };
      }
      return null;
    },
    destinationIntentVariation() {
      if (!this.destinationIntent) return null;
      const { name, text } = this.destinationIntent;
      const { pair } = this.getIntentVariation({ intentIdentity: name, text });
      return pair;
    },
  },
});
</script>

<style scoped>
.move-example-table tr td {
  padding: 7px;
}
.el-divider__text {
  color: #e4392b;
}
fieldset {
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 15px;
  font-size: 16px;
}
.intent-info {
  width: 100%;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 12px;
}
</style>
