<template>
  <div>
    <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addHeader">
      Add
    </el-button>
    <br />
    <br />
    <el-row type="flex" v-for="(hdr, index) in headers" :key="index">
      <el-autocomplete
        filterable
        v-model="hdr.key"
        placeholder="Select Header Key"
        size="mini"
        style="width: 40%; margin-right: 5px"
        :fetch-suggestions="querySearch"
      >
      </el-autocomplete>

      <el-input
        v-model="hdr.value"
        type="text"
        style="width: 40%; margin-right: 5px"
        placeholder="Input Header Value"
        size="mini"
      />
      <el-button
        style="float: right"
        size="mini"
        type="text"
        plain
        icon="el-icon-delete"
        circle
        @click="removeHeader(index)"
      />
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["headers"],
  data() {
    return {};
  },
  methods: {
    querySearch(queryString, cb) {
      var items = [
        "Accept",
        "Accept-Charset",
        "Accept-Encoding",
        "Accept-Language",
        "Access-Control-Request-Method",
        "Access-Control-Request-Headers",
        "Authorization",
        "Cache-Control",
        "Connection",
        "Content-Length",
        "Content-Type",
        "X-Client-Id",
        "x-Gateway-APIKey",
        "X-Requested-With",
        "x-api-key",
      ];
      const results = queryString
        ? items.filter((i) => i.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        : items;
      cb(results.map((i) => ({ label: i, value: i })));
    },
    addHeader() {
      this.$emit("addHeader");
    },
    removeHeader(index) {
      this.$emit("removeHeader", index);
    },
  },
};
</script>

<style></style>
