<template>
  <div>
    <el-autocomplete
      v-model="value[name]"
      :fetch-suggestions="setting.suggestions"
      style="max-width: 720px; margin-bottom: 1em"
    >
      <div slot="prepend" style="min-width: 140px">
        {{ setting.label || name }}
        <Tooltip :label="setting.label || name" :content="setting.detail" />
      </div>
    </el-autocomplete>
  </div>
</template>

<script>
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: ["value", "setting", "name"],
  mounted() {
    if (this.value[this.name] == null) {
      if (this.setting.default != null) {
        this.$set(this.value, this.name, this.setting.default);
      } else {
        this.$set(this.value, this.name, null);
      }
    }
  },
};
</script>
