<template>
  <div class="predefined-filter--wrapper" v-show="enabled && filters.length">
    <el-select
      v-model="selectedFilter"
      placeholder="Select predefined filter"
      filterable
      clearable
      size="mini"
      class="predefined-filter--select"
      :popper-append-to-body="false"
    >
      <el-tooltip
        v-for="filter in filters"
        :key="filter.name"
        :content="filter.description"
        effect="light"
        placement="right"
        popper-class="lighter-tooltip mw-240"
      >
        <el-option
          class="filter"
          :label="filter.name"
          :value="filter.name"
          :style="getFilterStyles(filter)"
        >
        </el-option>
      </el-tooltip>
    </el-select>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
  name: "PredefinedFilter",
  props: {
    type: String,
  },
  data() {
    return {
      selectedFilter: "",
    };
  },
  computed: {
    enabled() {
      return this.liveChatFilters?.enabled === true;
    },
    liveChatFilters() {
      const liveChatFiltersSettings = this.$store.state.modules?.core?.liveChatFilters;
      return _.cloneDeep(liveChatFiltersSettings);
    },
    filters() {
      return _.get(this, `liveChatFilters.${this.type}`, []);
    },
  },
  methods: {
    reset() {
      this.selectedFilter = "";
    },
    getFilterStyles(filter) {
      return {
        color: this.getFilterColor(filter),
        backgroundColor: this.getFilterBackgroundColor(filter),
        ...filter?.extras,
      };
    },
    getFilterColor(filter) {
      return filter?.styles?.color || "#444444";
    },
    getFilterBackgroundColor(filter) {
      return filter?.styles?.backgroundColor || "#ffffff";
    },
    parseFilterValue(filter) {
      const filterObj = {};

      _.forEach(filter.conditions, (condition) => (filterObj[condition.key] = condition.value));

      // parse date range
      let startDate, endDate;

      // FIXME: very simple parser
      if (filter?.dateRange?.type === "text") {
        let dateDiff;
        if (filter.dateRange.value.toLowerCase() === "yesterday") {
          dateDiff = 1;
        } else if (filter.dateRange.value.match(/last \d+ day?.$/gi)) {
          dateDiff = filter.dateRange.value.match(/\d+/g)[0];
        }
        startDate = moment().subtract(dateDiff, "days").startOf("day");
        endDate = moment().endOf("day");
      }

      return {
        ...filterObj,
        startDate,
        endDate,
      };
    },
    onFilterSelected(e, filter) {
      const parsedFilter = this.parseFilterValue(filter);
      this.$emit("selectedFilter", parsedFilter);
    },
  },
  watch: {
    selectedFilter(val) {
      if (_.isEmpty(val)) {
        this.$emit("resetFilter");
        return;
      }
      const filter = this.filters.filter((item) => item.name === val);
      this.onFilterSelected(null, filter[0]);
    },
  },
};
</script>

<style scoped lang="scss">
.predefined-filter--wrapper {
  margin: 0;
  position: relative;

  h4 {
    font-size: 10px;
    font-weight: normal;
    color: #666;
    margin-bottom: 5px;
  }

  ::v-deep .filter {
    font-size: 12px;
    border: 1px solid #e6ebf5;
    display: -ms-inline-flexbox;
    border-radius: 3px;
    cursor: pointer;
    margin: 5px;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover {
      border-color: #e4392b !important;
      color: #fff !important;
      background: #e4392b !important;
    }
  }

  .predefined-filter--select {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  ::v-deep .popper__arrow {
    left: 50px !important;
  }
}
</style>
