<template>
  <div>
    <el-row>
      <el-col>
        <el-input
          :loading="searchLoading"
          v-model="searchText"
          :placeholder="placeholder"
          class="search-bar"
          prefix-icon="el-icon-search"
          @keyup.enter.native="queryRelatedMessages"
          clearable
          @clear="handleClose"
        >
          <el-button
            slot="append"
            :loading="searchLoading"
            icon="el-icon-search"
            @click="queryRelatedMessages"
          >
            Search
          </el-button>
        </el-input>
      </el-col>
    </el-row>

    <div v-if="searchedText">
      <label
        style="
          vertical-align: middle;
          margin-left: 0.4em;
          margin-bottom: 2em;
          margin-right: 0.5em;
          color: #909399;
          fontsize: 14px;
        "
      >
        Current search term:
        <el-tooltip effect="dark" content="Filter currently applied" placement="top">
          <i class="el-icon-question" style="margin-left: 0.2em; color: #909399"></i>
        </el-tooltip>
      </label>

      <el-tag
        v-for="tag in [{ name: searchedText, type: 'warning' }]"
        :key="tag.name"
        closable
        @close="handleClose"
        :type="tag.type"
      >
        {{ tag.name }}
      </el-tag>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import { setSelectedIntent } from "@/helperMethods/faq";

export default Vue.extend({
  props: ["placeholder"],
  data() {
    return {
      searchText: "",
      searchedText: "",
    };
  },
  computed: {
    searchLoading: {
      get() {
        return _.get(this, "$store.state.faq.backendSearchLoading", false);
      },
      set(value) {
        this.$set(this.$store.state.faq, "backendSearchLoading", value);
      },
    },
    searchDataExist() {
      return _.get(this, "$store.state.training.searched.length", 0) > 0;
    },
  },
  methods: {
    handleClose() {
      this.clearRequiredData();
      // fetch latest training data agian
      this.$store.dispatch("FETCH_FAQ_UNLABELED").then(() => {
        this.$message({ type: "success", message: "Fetched latest messages" });
      });
    },
    queryRelatedMessages() {
      if (this.searchText.length > 0) {
        // search term has to be at least a character
        this.searchLoading = true;
        this.searchedText = this.searchText; // show searched text to user
        this.$set(this.$store.state.faq, "backendSearch", this.searchText); // SearchText sent = point of reference

        this.$store
          .dispatch("FAQ_GET_UNLABELED_MESSAGES_BY_SEARCH", {
            searchTerm: this.searchText,
          })
          .then((isUpdated) => {
            setSelectedIntent();
            this.searchLoading = false;
            this.$message({
              type: "success",
              message: "Messages fetched successfully.",
            });
          })
          .catch((err) => {
            this.searchLoading = false;
            this.$message({
              type: "error",
              message: "Encountered error fetching messages.",
            });
          });
      } else {
        // if searchTerm is empty, user expects search to be cancelled
        this.clearRequiredData();
      }
    },
    clearRequiredData() {
      this.searchText = "";
      this.searchedText = "";
      // clear training.searched
      this.$set(this.$store.state.faq, "backendSearch", "");
      this.$set(this.$store.state.training, "searched", []);
      this.$set(this.$store.state.faq, "selectedConversationalDataIntent", "");
    },
  },
});
</script>

<style scoped>
.search-bar {
  transition-property: width;
  transition-duration: 0.5s;
}
</style>
