<template>
  <section v-if="groupByTopicIsEnabled">
    <el-row type="flex" justify="space-between">
      <div>
        <!-- back button start-->
        <el-button type="text" icon="el-icon-arrow-left" @click="selectTopic('')" />
        <!-- back button end -->

        <!-- quick nav between topic start -->
        <el-dropdown class="cursor-pointer" style="margin-left: 30px">
          <span class="el-dropdown-link text-capitalize">
            {{ selectedTopic }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="selectTopic(topic)"
              :disabled="topic === selectedTopic"
              class="text-capitalize"
              :key="`faq-items-topics-dropdown-option-${index}`"
              v-for="(topic, index) in topics"
            >
              {{ topic }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- quick nav between topic end -->
      </div>

      <div>
        <!-- add new intent button start -->
        <el-popover placement="bottom" title="Add Intent" width="320" trigger="click">
          <template slot="default">
            <el-row type="flex">
              <el-input
                v-model="addNewIntentName"
                size="small"
                :placeholder="`Enter a new intent name for ${selectedTopic}...`"
              >
                <template slot="append">
                  <el-button
                    @click.native="onAddIntentButtonClicked"
                    :disabled="!addNewIntentName"
                    type="primary"
                    size="small"
                  >
                    <i class="el-icon-check"></i>
                  </el-button>
                </template>
              </el-input>
            </el-row>
          </template>
          <el-button style="margin-right: 10px" type="primary" size="small" slot="reference">
            Add Intent
            <i class="el-icon-plus"></i>
          </el-button>
        </el-popover>
        <!-- add new intent button end -->

        <!-- move selected button start -->
        <el-dropdown
          v-if="selectedRowIsNotEmpty"
          size="small"
          split-button
          type="primary"
          class="cursor-pointer"
          style="margin-right: 10px"
        >
          <span class="text-capitalize">Move Selected</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="moveSelected(topic)"
              :disabled="topic === selectedTopic"
              class="text-capitalize"
              :key="`faq-items-move-selected-topics-dropdown-option-${index}`"
              v-for="(topic, index) in topics"
            >
              {{ topic }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- move selected button end -->

        <el-popover placement="bottom" width="160" trigger="click">
          <div style="text-align: center; margin: 0">
            <!-- train topic button start -->
            <el-tooltip content="Train intents in current topic" placement="left">
              <div>
                <el-button
                  @click="saveAndTrainByTopic"
                  :disabled="isBusy"
                  :loading="isBusy"
                  style="width: 100%; margin-top: 5px; margin-bottom: 5px"
                  size="small"
                  type="primary"
                  plain
                >
                  Train Topic
                </el-button>
              </div>
            </el-tooltip>
            <!-- train topic button end -->

            <!-- check conflicts button start -->
            <el-tooltip
              content="Check conflicts with published topic model"
              placement="left"
              v-if="hasPublishedModel"
            >
              <div>
                <el-button
                  @click="deconflict"
                  :disabled="isBusy"
                  :loading="isBusy"
                  style="width: 100%; margin-top: 5px; margin-bottom: 5px"
                  size="small"
                  type="primary"
                  plain
                >
                  Check Conflicts
                </el-button>
              </div>
            </el-tooltip>

            <el-tooltip content="Publish model before checking conflicts" placement="left" v-else>
              <div>
                <el-button
                  :disabled="true"
                  style="width: 100%; margin-top: 5px; margin-bottom: 5px"
                  size="small"
                  type="primary"
                  plain
                >
                  Check Conflicts
                </el-button>
              </div>
            </el-tooltip>
            <!-- check conflicts button end -->

            <!-- view models button start -->
            <el-tooltip content="View and publish topic models" placement="left">
              <div>
                <el-button
                  @click="toggleTopicFaqModelListDialogVisible(true)"
                  :disabled="isBusy"
                  :loading="isBusy"
                  style="width: 100%; margin-top: 5px; margin-bottom: 5px"
                  size="small"
                  type="primary"
                  plain
                >
                  View Models
                </el-button>
              </div>
            </el-tooltip>
            <!-- view models button end -->

            <!-- delete topic button start -->
            <el-tooltip content="Delete intents and examples of this topic" placement="left">
              <div>
                <el-button
                  @click="toggleTopicDeleteDialogVisible(true)"
                  style="width: 100%; margin-top: 5px; margin-bottom: 5px"
                  size="small"
                  type="primary"
                  icon="el-icon-delete"
                  plain
                  >Delete Topic</el-button
                >
              </div>
            </el-tooltip>
            <!-- delete topic button end -->
          </div>

          <el-button slot="reference" type="primary" size="small" style="margin-right: 10px">
            Actions
          </el-button>
        </el-popover>
      </div>
    </el-row>
    <br />

    <el-pagination
      @current-change="pageChanged"
      small
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="selectedTopicDatasets.length"
    ></el-pagination>
    <br />

    <div v-if="isReloadingFaqs" v-loading="true" style="padding: 50px 30px"></div>

    <!-- topic faqs table start -->
    <el-table
      ref="dataset"
      style="width: 100%"
      v-if="!isReloadingFaqs"
      :data="paginatedSelectedTopicDatasets"
      size="mini"
      :row-key="(row, index) => index"
      :expand-row-keys="expandedRows"
      :cell-style="lowVariationStyle"
      :row-style="customRowStyle"
      @cell-click="handleCellClick"
      @sort-change="$emit('onSortChange', $event)"
    >
      <el-table-column header-align="center" label="#" width="40px">
        <template slot-scope="scope">
          <el-checkbox v-model="selectedRows[scope.row.id]"></el-checkbox>
        </template>
      </el-table-column>

      <!-- Intent name -->
      <el-table-column header-align="center" label="Intent Name" column-key="intentName" sortable>
        <template slot-scope="scope">
          <!-- new question -->
          <el-badge v-if="badgeForQustions[scope.row.id]" value="new" class="badge_item">
            <div style="margin-left: 1em">{{ scope.row.intentName }}</div>
          </el-badge>
          <!-- display textbox when editing and row is selected -->
          <el-input
            :disabled="!isAuthorisedForChange(scope.row.department)"
            v-else-if="scope.row.edit && scope.row.id === $store.state.pages.faq.selectedIntentId"
            v-model="scope.row.intentName"
            placeholder="Input a name for your intent"
            @keyup.enter.native="$emit('handleBlur', scope.row)"
            @change="$emit('isDirty')"
            @blur="$emit('handleBlur', scope.row)"
          />

          <!-- display default intent name-->
          <div v-else-if="!scope.row.id || scope.row.id === ''" class="table-cell">
            <div style="margin-left: 1em">{{ defaultIntentNameValue }}</div>
          </div>

          <!-- just basic intentName -->
          <div v-else style="margin-left: 1em">{{ scope.row.intentName }}</div>
        </template>
      </el-table-column>

      <!-- Variation length -->
      <el-table-column
        sortable
        header-align="center"
        align="center"
        label="Examples"
        width="120"
        column-key="examples"
      >
        <template slot-scope="props">
          <span>
            <!-- Intents with 0 variations should have an el-tooltip -->
            <el-tooltip
              v-if="variationsByLanguage(props.row) === 0"
              content="Please click to add examples"
              placement="top"
            >
              <span class="list-wording">{{ variationsByLanguage(props.row) }}</span>
            </el-tooltip>
            <span v-else class="list-wording">{{ variationsByLanguage(props.row) + " " }}</span>

            <el-tooltip
              v-if="containsUntestedVariations(props.row)"
              content="This intent has unevaluated examples 📙"
              placement="top"
            >
              <!-- orange, font-size:21px as it looks smaller with the same size -->
              <i class="el-icon-question list-icon" style="color: #e6a23c; font-size: 21px" />
            </el-tooltip>
            <el-tooltip
              v-else-if="isConflicted(props.row)"
              content="This intent has conflicting examples ❌"
              placement="top"
            >
              <!-- keyreply red -->
              <i class="el-icon-warning list-icon" style="color: #e4392b" />
            </el-tooltip>
            <el-tooltip
              v-else-if="
                props.row.variations && props.row.variations.length > 0 && !isConflicted(props.row)
              "
              content="This intent has no conflicting examples 👍🏻"
              placement="top"
            >
              <!-- green -->
              <i class="el-icon-success list-icon" style="color: #67c23a" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <!-- Enable -->
      <el-table-column header-align="center" label="Enabled" width="80" align="center">
        <template slot-scope="props">
          <!-- For questions that cannot be enabled -->
          <el-tooltip
            v-if="props.row.intentName.trim().length === 0"
            content="Either question or answer cannot be blank"
            placement="top"
          >
            <el-switch
              v-model="props.row.enabled"
              :disabled="
                props.row.intentName.trim().length === 0 ||
                !isAuthorisedForChange(props.row.department)
              "
              active-color="#409EFF"
              inactive-color="#CCC9C9"
            ></el-switch>
          </el-tooltip>

          <!-- For questions that can be enabled -->
          <el-switch
            v-model="props.row.enabled"
            :disabled="!isAuthorisedForChange(props.row.department)"
            v-else
            active-color="#409EFF"
            inactive-color="#CCC9C9"
            @change="$emit('handleEnable', props.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column header-align="center" width="48" align="center">
        <template slot-scope="props">
          <div>
            <el-tooltip content="Delete this question" placement="right">
              <el-button
                :disabled="!isAuthorisedForChange(props.row.department)"
                size="mini"
                type="danger"
                icon="el-icon-delete"
                circle
                @click="toggleDeleteDialogVisible(true, props.row)"
              />
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- topic faqs table end -->

    <DeleteDialog
      v-if="deleteDialogVisible === true && !selectedIntentIsEmpty"
      :delete-dialog-visible="deleteDialogVisible"
      :selected-intent="selectedIntent"
      @deleteQuestion="deleteQuestion"
      @toggleDeleteDialogVisible="toggleDeleteDialogVisible"
    />

    <DeleteTopicDialog
      v-if="deleteTopicDialogVisible === true"
      :delete-topic-dialog-visible="deleteTopicDialogVisible"
      :selected-topic="selectedTopic"
      @deleteTopic="deleteTopic"
      @toggleTopicDeleteDialogVisible="toggleTopicDeleteDialogVisible"
    />

    <TopicFaqModelListDialog
      v-if="topicModelDialogVisible === true"
      :topic-model-dialog-visible="topicModelDialogVisible"
      :models="models"
      @toggleTopicFaqModelListDialogVisible="toggleTopicFaqModelListDialogVisible"
    />
  </section>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import DeleteDialog from "@/components/Faq/DeleteDialog";
import DeleteTopicDialog from "@/components/Faq/DeleteTopicDialog";
import TopicFaqModelListDialog from "@/components/Faq/TopicFaqModelListDialog";
import { prepareDatasetToSave } from "@/helperMethods/faq/util";
import gql from "graphql-tag";
import { faqConflictedMixin } from "@/mixins/faq";

export default Vue.extend({
  mixins: [faqConflictedMixin],
  data() {
    return {
      defaultQuestionValue: "<This is a blank question>",
      defaultIntentNameValue: "<Empty intent name>",
      deleteDialogVisible: false,
      deleteTopicDialogVisible: false,
      topicModelDialogVisible: false,
      selectedIntent: {},
      selectedRows: {},
      isSaving: false,
      isReloadingFaqs: false,

      // new intent
      addNewIntentName: null,

      // pagination
      currentPage: 0,
      pageSize: 20,
    };
  },
  computed: {
    ...mapGetters(["elsaEnabled", "rasaEnabled"]),
    isBusy() {
      return this.isSaving || this.trainingInProgress;
    },
    trainingInProgress() {
      return this.$store.getters.getTrainingInProgress;
    },
    groupByTopicIsEnabled() {
      return this.$store.getters.groupByTopicIsEnabled;
    },
    selectedIntentIsEmpty() {
      return _.isEmpty(this.selectedIntent);
    },
    selectedRowIsNotEmpty() {
      return !_.chain(this.selectedRows)
        .pickBy((isSelected) => isSelected)
        .isEmpty()
        .value();
    },
    selectedRowsIntent() {
      const selectedRowsIntentIds = _.chain(this.selectedRows)
        .pickBy((isSelected) => isSelected)
        .keys()
        .value();

      const selectedRowsWithIntents = this.selectedTopicDatasets.filter(
        (dataset) => selectedRowsIntentIds.indexOf(dataset.id) > -1
      );

      return selectedRowsWithIntents;
    },
    paginatedSelectedTopicDatasets() {
      let fromIndex = this.currentPage * this.pageSize;
      let toIndex = (this.currentPage + 1) * this.pageSize;
      if (this.currentPage < 1) {
        // if its first page
        fromIndex = 0;
        toIndex = 19;
      }
      return this.selectedTopicDatasets.slice(fromIndex, toIndex);
    },

    hasPublishedModel() {
      const currentViewingTopic = _.get(this, "$store.state.faq.currentViewingTopic", "");

      if (this.rasaEnabled) {
        const publishedModel = _.get(
          this,
          `$store.state.modules.faq.Rasa.topicModelIds.${currentViewingTopic}`,
          ""
        );

        if (!publishedModel) {
          return false;
        }
      } else if (this.elsaEnabled) {
        const publishedModel = _.get(
          this,
          `$store.state.modules.faq.Elsa.publishedModelIds.${currentViewingTopic}`,
          ""
        );

        if (!publishedModel) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    setTrainingInProgress(inProgress) {
      this.$store.commit("SET_TRAINING_IN_PROGRESS", inProgress);
    },

    selectTopic(topic) {
      this.$emit("selectTopic", topic);
      this.$store.commit("SET_CURRENT_VIEWING_TOPIC", topic);
    },

    trainByTopic() {
      this.setTrainingInProgress(true);
      this.$store
        .dispatch("FAQ_TRAIN_BY_TOPICS", {
          selectedTopics: [this.selectedTopic],
        })
        .then((isSuccessful) => {
          if (_.get(this, "$apollo.queries.models")) {
            this.$apollo.queries.models.refresh();
          }
          this.$notify.success({
            title: "Train Success",
            position: "bottom-right",
            message: `Model per topic has been trained successfully`,
          });
        })
        .catch((e) => {
          this.$notify.error({
            title: "Train Error",
            position: "bottom-right",
            message: "Training by topics failed.",
          });
        })
        .finally(() => this.setTrainingInProgress(false));
    },

    async deconflict() {
      this.$nextTick(() => {
        if (this.rasaEnabled) {
          this.rasaEvaluateIntentsByTopic(this.selectedTopic);
        } else if (this.elsaEnabled) {
          this.elsaEvaluateIntentsByTopic(this.selectedTopic);
        }
      });
    },

    async rasaEvaluateIntentsByTopic(selectedTopic) {
      const { topicModelIds } = _.get(this, "$store.state.modules.faq.Rasa");
      let latestModelId = _.get(topicModelIds, selectedTopic, "");
      if (!latestModelId) {
        latestModelId = _.get(this, "$store.state.modules.faq.Rasa.modelId");
      }

      const enabledData = _.get(this, "$store.state.training.dataset", [{ enabled: false }]).filter(
        (intent) => intent.enabled
      );
      const dataByTopic = _.filter(enabledData, (intent) => {
        const topics = _.get(intent, "topics", []);
        return topics.includes(selectedTopic);
      });

      this.setTrainingInProgress(true);

      const chunks = _.chunk(dataByTopic, 100);
      const chunkPromises = chunks.map((intents) => {
        // latestModelId is for train and deconflicting
        return this.$store.dispatch("FAQ_TEST_TRAINING_DATA", {
          intents,
          latestModelId: latestModelId,
          isIgnoreFallbackOthersIntent: true,
        });
      });

      Promise.all(chunkPromises)
        .then((updatedDataset) => {
          if (updatedDataset) {
            this.$notify.success({
              title: "Evaluate Intents",
              position: "bottom-right",
              message: `Successfully evaluated all FAQ intents with ${latestModelId}`,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Evaluation Error",
            position: "bottom-right",
            message: "Encountered error testing FAQ.",
          });
        })
        .finally(() => this.setTrainingInProgress(false));
    },

    async elsaEvaluateIntentsByTopic(selectedTopic) {
      const { publishedModelIds, modelNameToUUID } = _.get(this, "$store.state.modules.faq.Elsa");

      const latestModelId = _.get(publishedModelIds, selectedTopic, "");
      if (!latestModelId) {
        this.$notify.error({
          title: "Deconflicting Error",
          position: "bottom-right",
          message: "No published model",
        });

        throw new Error(`No published model for ${selectedTopic} when checking conflicts`);
      }

      let modelUUID = _.get(modelNameToUUID, selectedTopic, "");
      if (!modelUUID) {
        const publishedModelId = _.get(publishedModelIds, selectedTopic, "");
        if (!publishedModelId) {
          throw new Error(`No model UUID for ${selectedTopic}`);
        }

        modelUUID = publishedModelId.split[0];
      }

      const enabledData = _.get(this, "$store.state.training.dataset", [{ enabled: false }]).filter(
        (intent) => intent.enabled
      );

      const dataByTopic = _.filter(enabledData, (intent) => {
        const topics = _.get(intent, "topics", []);
        return topics.includes(selectedTopic);
      });

      this.setTrainingInProgress(true);

      const chunks = _.chunk(dataByTopic, 100);
      const chunkPromises = chunks.map((intents) => {
        // latestModelId is for train and deconflicting
        return this.$store.dispatch("FAQ_TEST_TRAINING_DATA", {
          intents,
          latestModelId: latestModelId,
          isIgnoreFallbackOthersIntent: true,
        });
      });

      Promise.all(chunkPromises)
        .then((updatedDataset) => {
          if (updatedDataset) {
            this.$notify.success({
              title: "Evaluate Intents",
              position: "bottom-right",
              message: `Successfully evaluated all FAQ intents with ${latestModelId}`,
            });
          }
        })
        .catch((err) => {
          this.$notify.error({
            title: "Evaluation Error",
            position: "bottom-right",
            message: "Encountered error testing FAQ.",
          });
        })
        .finally(() => this.setTrainingInProgress(false));
    },

    async saveAndTrainByTopic() {
      let datasetToSave = prepareDatasetToSave(this.$store.state.training.dataset);
      this.isSaving = true;
      const isSaved = await this.$apollo
        .mutate({
          mutation: gql`
            mutation($dataset: JSON!) {
              faqAPI {
                saveDataset(dataset: $dataset)
              }
            }
          `,
          variables: {
            dataset: datasetToSave,
          },
        })
        .catch(() => {
          this.isSaving = true;
          this.$notify.error({
            title: "Error",
            message: "Failed to save FAQ dataset.",
            position: "bottom-right",
          });
          return false;
        });

      if (isSaved) {
        this.isSaving = false;
        this.trainByTopic();
      }
    },
    onAddIntentButtonClicked() {
      const lowerCasedTopic = this.selectedTopic.toLowerCase();
      const topics = [lowerCasedTopic];
      this.$emit(
        "handleBlur",
        {
          intentName: this.addNewIntentName,
          topics,
        },
        true
      );
      this.$nextTick(() => (this.intentName = null));
    },
    pageChanged(updatedPage) {
      this.currentPage = updatedPage - 1; // -1 because pagination work with array index
    },
    moveSelected(topic) {
      const selectedRowsIntent = _.clone(this.selectedRowsIntent);
      selectedRowsIntent.forEach((row) => {
        const currentTopicIndex = row.topics.findIndex((topic) => topic === this.selectedTopic);

        if (currentTopicIndex > -1) {
          row.topics.splice(currentTopicIndex, 1, topic);
          this.$emit("handleBlur", row);
        }
      });
      this.reloadingFaqs();
    },
    /**
     * @description The el-checkbox somehow stay checked even removed v-model
     * @description Fake a loading force component remount
     * @return {void}
     */
    reloadingFaqs() {
      this.isReloadingFaqs = true;
      this.selectedRows = {};
      setTimeout(() => (this.isReloadingFaqs = false), 300);
    },
    handleCellClick(row, column, cell, event) {
      this.$emit("handleCellClick", {
        row,
        column,
        cell,
        event,
      });
    },
    deleteQuestion(row) {
      this.$emit("deleteQuestion", row);
      // Reset the delete dialog variables
      this.selectedIntent = {};
      this.deleteDialogVisible = false;
    },
    deleteTopic() {
      _.forEach(this.selectedTopicDatasets, (row) => {
        this.$store.commit("DELETE_QUESTION", row.id);
      });

      this.$forceUpdate();

      this.deleteTopicDialogVisible = false;
      this.selectTopic("");
    },
    toggleDeleteDialogVisible(isVisible = true, selectedIntent = {}) {
      this.selectedIntent = selectedIntent;
      this.deleteDialogVisible = isVisible;
    },
    toggleTopicDeleteDialogVisible(isVisible = true) {
      this.deleteTopicDialogVisible = isVisible;
    },
    toggleTopicFaqModelListDialogVisible(isVisible) {
      this.topicModelDialogVisible = isVisible;
    },
    lowVariationStyle({ row, columnIndex }) {
      if (columnIndex === 1 && row && row.variations && row.variations.length < 2) {
        return { color: "red", fontWeight: "bold" };
      }
    },
    customRowStyle(row, rowIndex) {
      const style = { cursor: "pointer" };
      const currentRowId = _.get(row, "row.intentName");
      if (this.$store.state.pages.faq.selectedIntentId === currentRowId) {
        style.background = "#f3f6f9";
      }
      const isInvalid = _.get(row, "row.invalid");
      if (isInvalid) {
        style.background = "#fee";
      }
      return style;
    },
    isAuthorisedForChange(intentDepartment = ["general"]) {
      const userDepartment = this.$store.getters.userDepartment;

      const userIsAuthorised = _.intersection(userDepartment, intentDepartment).length > 0;
      const intentIsGeneral = intentDepartment.includes("general");

      const isAuthorised = userIsAuthorised || intentIsGeneral;
      return isAuthorised;
    },
    variationsByLanguage(row) {
      const variations = row.variations;
      if (variations) {
        const variationsCount = variations.filter((variation) => {
          const selectedLanguages = this.selectedLanguages;
          if (selectedLanguages.length > 0) {
            return selectedLanguages.includes(variation.language);
          } else {
            return true;
          }
        }).length;
        return variationsCount;
      }
    },
    containsUntestedVariations(intent) {
      const variations = intent.variations;
      let untestedVariationExist = false;
      if (variations) {
        variations.map((variation) => {
          const intent_ranking = variation.intent_ranking;
          if (intent_ranking && intent_ranking.length === 0) {
            untestedVariationExist = true;
          }
          if (!intent_ranking) {
            untestedVariationExist = true;
          }
        });
      }
      return untestedVariationExist;
    },
  },
  apollo: {
    models() {
      return {
        query: gql`
          query($modelName: String) {
            faqAPI {
              models: getModels(modelName: $modelName)
            }
          }
        `,
        variables() {
          return {
            modelName: _.get(this, "$store.state.faq.currentViewingTopic", ""),
          };
        },
        fetchPolicy: "cache-and-network",
        update: (data) => {
          let models = _.get(data, "faqAPI.models", []);
          return models;
        },
      };
    },
  },
  watch: {
    selectedTopic: {
      deep: true,
      handler(event, old) {
        if (event !== old) {
          this.reloadingFaqs();
        }
      },
    },
  },
  props: {
    selectedTopicDatasets: {
      default: Array,
    },
    expandedRows: {
      default: Array,
    },
    badgeForQustions: {
      default: Object,
    },
    selectedLanguages: {
      default: Array,
    },
    selectedTopic: {},
    topics: {
      default: Array,
    },
  },
  components: {
    DeleteDialog,
    DeleteTopicDialog,
    TopicFaqModelListDialog,
  },
});
</script>

<style scoped>
.el-switch {
  height: auto;
}
</style>
