<template>
  <el-container>
    <el-main>
      <el-row v-if="showAdvanced">
        <el-col :sm="24">
          <el-tooltip
            v-if="showAdvanced"
            effect="dark"
            content="Deploy Content into production"
            placement="bottom"
          >
            <el-button
              style="margin-left: 1em; float: right"
              type="success"
              :disabled="pendingCounter === 0"
              :loading="adminLoading"
              :plain="true"
              @click="completeAllPublications"
            >
              <i class="el-icon-check" />
              Complete all pending publications ({{ pendingCounter }})
            </el-button>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <!-- Publish List -->
        <el-col :sm="8" style="min-height: 300px">
          <el-row>
            <el-col :span="24">
              <el-input v-model="searchTerm" placeholder="Search" />
            </el-col>
          </el-row>
          <el-card style="min-height: 600px" :body-style="{ padding: '0px 0px 0px 0px' }">
            <div slot="header" class="clearfix">
              <h3 style="line-height: 36px">
                Content Publisher
                <el-tooltip content="Refresh to get latest publications" placement="top">
                  <el-button
                    :disabled="pendingLoading"
                    plain
                    icon="el-icon-refresh"
                    style="float: right"
                    @click="
                      fetchPendingPublish();
                      fetchCompletedPublish();
                    "
                  />
                </el-tooltip>
              </h3>
            </div>
            <el-tabs
              v-model="activePublishTab"
              type="border-card"
              style="margin-right: 0; border: 0px"
              @tab-click="resetPublishSelection"
            >
              <el-tab-pane
                :lazy="true"
                :label="`Pending (${pendingCounter})`"
                name="pending"
                style="height: 460px; overflow-y: scroll"
              >
                <el-table
                  ref="pendingPublishTable"
                  v-loading="pendingLoading"
                  :data="finalizedPendingPublishLeftList"
                  element-loading-text="Loading pending publish..."
                  style="width: 100%"
                  empty-text="No pending publications"
                  highlight-current-row
                  @current-change="handleCurrentPendingPublish"
                >
                  <el-table-column>
                    <template slot-scope="slot">
                      <h2>{{ slot.row.publishName || slot.row.publish_id }}</h2>
                      <p>{{ slot.row.publish_date }}</p>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>

              <el-tab-pane
                :lazy="true"
                :label="`Completed (${completedCounter})`"
                name="completed"
                style="height: 460px; overflow-y: scroll"
              >
                <el-table
                  ref="completedPublishTable"
                  v-loading="completedLoading"
                  :data="finalizedCompletedPublishLeftList"
                  element-loading-text="Loading completed publish..."
                  style="width: 100%"
                  empty-text="No completed publications"
                  highlight-current-row
                  @current-change="handleCurrentCompletedPublish"
                >
                  <el-table-column>
                    <template slot-scope="slot">
                      <h2>{{ slot.row.publishName || slot.row.publish_id }}</h2>
                      <p>{{ slot.row.publish_date }}</p>
                    </template>
                  </el-table-column>
                </el-table>
                <div v-if="!completedPublishFetched" style="text-align: center">
                  <el-button
                    type="text"
                    class="completed-view-more"
                    @click="fetchNextHundredCompletedPublish()"
                    >-- View more --
                  </el-button>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>

        <!-- Individual Publish Content -->
        <el-col :sm="16" style="min-height: 300px">
          <el-card
            v-if="selectedPublishSet.publish_id"
            v-loading="pendingLoading || completedLoading"
            style="min-height: 600px"
            element-loading-text="Loading publish..."
          >
            <!-- Header -->
            <div slot="header" class="clearfix">
              <!-- Preview mode -->
              <el-row v-if="!editSelectedPublish">
                <el-col :sm="16" @click="editSelectedPublish = true">
                  <el-form label-width="140px">
                    <el-form-item v-if="showAdvanced" label="Publish ID:" prop="publish_id">
                      <h2>{{ selectedPublishSet.publish_id }}</h2>
                    </el-form-item>
                    <el-form-item label="Publish Name:" prop="publishName">
                      <h2>{{ selectedPublishSet.publishName }}</h2>
                    </el-form-item>

                    <el-form-item label="Publish Date:" prop="publish_date"
                      >{{ selectedPublishSet.publish_date }}
                    </el-form-item>

                    <el-form-item label="Content added by:" prop="modified_by">
                      <span>
                        <b>{{ selectedPublishSet.modified_by }}</b>
                      </span>
                    </el-form-item>

                    <el-form-item label="Approved by:" prop="approved_by">
                      <span>
                        <b>{{ selectedPublishSet.approved_by }}</b>
                      </span>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col v-if="!selectedPublishSet.isCompleted" :sm="8">
                  <!-- <el-row>
                    <el-button
                      class="delete button"
                      style="float: right"
                      plain
                      @click="showDeleteConfirmation"
                      :loading="deleteLoading"
                    >
                      <i class="el-icon-delete"></i>
                      Delete Publish
                    </el-button>
                  </el-row>-->
                  <el-row>
                    <el-button style="float: right" plain @click="editSelectedPublish = true">
                      <i class="el-icon-edit-outline" /> Edit Publish
                    </el-button>
                  </el-row>
                </el-col>
              </el-row>

              <!-- Edit mode -->
              <el-row
                v-else
                v-loading="editPublishLoading"
                element-loading-text="Currently updating publish..."
              >
                <el-col :sm="12">
                  <el-form
                    ref="editSelectedPublishForm"
                    :model="selectedPublishSet"
                    label-width="120px"
                    :rules="editPublishConfirmationRule"
                  >
                    <el-form-item v-if="showAdvanced" label="Publish ID:" prop="publish_id">
                      <h2>{{ selectedPublishSet.publish_id }}</h2>
                    </el-form-item>
                    <el-form-item label="Publish Name:" prop="publishName">
                      <el-input v-model="selectedPublishSet.publishName" type="text" />
                    </el-form-item>

                    <el-form-item label="Publish Date:" prop="publish_date">
                      <el-date-picker
                        v-model="selectedPublishSet.publish_date"
                        format="dd-MM-yyyy HH:mm"
                        value-format="dd-MM-yyyy HH:mm:ss"
                        type="datetime"
                        placeholder="Select date and time"
                        :picker-options="dateTimePicker"
                      />
                    </el-form-item>

                    <el-form-item label="Modified by:" prop="modified_by">
                      <span>
                        <b>{{ selectedPublishSet.modified_by }}</b>
                      </span>
                    </el-form-item>

                    <el-form-item label="Approved by:" prop="approved_by">
                      <span>
                        <b>{{ selectedPublishSet.approved_by }}</b>
                      </span>
                    </el-form-item>
                  </el-form>
                </el-col>
                <el-col v-if="!selectedPublishSet.isCompleted" :sm="12">
                  <el-row>
                    <el-button
                      class="delete button"
                      style="float: right"
                      plain
                      @click="showDeleteConfirmation"
                      :loading="deleteLoading"
                    >
                      <i class="el-icon-delete"></i>
                      Delete Publish
                    </el-button>
                  </el-row>
                  <el-row>
                    <el-button
                      style="float: right"
                      plain
                      type="success"
                      @click="submitPublishUpdateForm('editSelectedPublishForm')"
                    >
                      <i class="el-icon-check" /> Save Publish
                    </el-button>
                  </el-row>
                  <el-row>
                    <el-button style="float: right" plain @click="editSelectedPublish = false">
                      <i class="el-icon-edit-close" /> Cancel edit
                    </el-button>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!-- Body -->
            <el-row>
              <h4>
                Content to be published
                <!-- <el-button-group style="float:right">
                                  <el-button class="button" plain @click="collapseAll()">Collpase all</el-button>
                                  <el-button class="button" plain @click="expandAll()">Expand all</el-button>
                </el-button-group>-->
              </h4>
            </el-row>
            <el-collapse
              v-model="publishContentName"
              class="publish-collapse"
              :value="activeContentArray"
            >
              <el-collapse-item v-if="selectedPublishSet.editor" name="editor">
                <template slot="title">
                  Editors
                  <i class="header-icon el-icon-info" />
                </template>
                <!-- BUBBLE CONTENT -->
                <table class="PublishTable" border="1">
                  <tr>
                    <th class="table-id">Content Node ID</th>
                    <th class="table-status">Status</th>
                    <th>New Values</th>
                  </tr>
                  <tr v-for="contentNode in selectedPublishSet.editor" :key="contentNode.RowKey">
                    <td class="table-id">{{ contentNode.payload_id }}</td>
                    <td v-if="contentNode.isDelete" class="table-status" style="color: red">
                      Delete
                    </td>
                    <td v-else class="table-status" style="color: Green">Add</td>
                    <td v-if="contentNode.payload" style="text-align: center">
                      <Bubble
                        v-if="contentNode.payload.content"
                        v-model="contentNode.payload.content"
                      />
                      <QuickReplies
                        v-if="contentNode.payload.quickReplies"
                        v-model="contentNode.payload.quickReplies"
                      />
                    </td>
                    <td v-else style="text-align: center; color: grey">NA</td>
                  </tr>
                </table>
              </el-collapse-item>
              <el-collapse-item v-if="selectedPublishSet.intent" name="intent">
                <template slot="title">
                  Intents
                  <i class="header-icon el-icon-info" />
                </template>
                <table class="PublishTable" border="1">
                  <tr>
                    <th class="table-id">Intent ID</th>
                    <th class="table-status">Status</th>
                    <th>New Values</th>
                  </tr>
                  <tr v-for="intent in selectedPublishSet.intent" :key="intent.RowKey">
                    <td class="table-id">{{ intent.payload_id }}</td>
                    <td v-if="intent.isDelete" class="table-status" style="color: red">Delete</td>
                    <td v-else class="table-status" style="color: Green">Add</td>
                    <td v-if="intent.payload" style="text-align: left">
                      <b>Intent Name:</b>
                      {{ intent.payload.intent }}
                      <br />
                      <b>Description:</b>
                      {{ intent.payload.description }}
                      <br />
                      <template v-if="intent.payload.examples">
                        <div
                          v-for="(example, index) in intent.payload.examples"
                          :key="example.text"
                        >
                          <b>Example #{{ index + 1 }}:</b>
                          {{ example.text }}
                        </div>
                      </template>
                    </td>
                    <td v-else style="text-align: center; color: grey">NA</td>
                  </tr>
                </table>
              </el-collapse-item>
              <el-collapse-item v-if="selectedPublishSet.entity" name="entity">
                <template slot="title">
                  Entities
                  <i class="header-icon el-icon-info" />
                </template>
                <table class="PublishTable" border="1">
                  <tr>
                    <th class="table-id">Entity ID</th>
                    <th class="table-status">Status</th>
                    <th>New Values</th>
                  </tr>
                  <tr v-for="entity in selectedPublishSet.entity" :key="entity.RowKey">
                    <td class="table-id">{{ entity.payload_id }}</td>
                    <td v-if="entity.isDelete" class="table-status" style="color: red">Delete</td>
                    <td v-else class="table-status" style="color: Green">Add</td>
                    <td v-if="entity.payload" style="text-align: left">
                      <b>Entity Name:</b>
                      {{ entity.payload.entity }}
                      <br />
                      <b>Description:</b>
                      {{ entity.payload.description }}
                      <br />
                      <table
                        v-if="entity.payload.values && entity.payload.values.length > 0"
                        id="entity-table"
                        border="1"
                      >
                        <tr>
                          <!-- <th class="index">#</th> -->
                          <th class="value">Values</th>
                          <th class="value-type">Type</th>
                          <th class="synonyms">Synonyms</th>
                          <th class="patterns">Patterns</th>
                        </tr>
                        <tr v-for="(value, index) in entity.payload.values" :key="index">
                          <!-- <td class="index">{{index + 1}}</td> -->
                          <td class="value">{{ value.value }}</td>
                          <td class="value-type">{{ value.type }}</td>
                          <td v-if="value.synonyms" class="synonyms">
                            {{ value.synonyms }}
                          </td>
                          <td v-else />
                          <td v-if="value.patterns" class="patterns">
                            {{ value.patterns }}
                          </td>
                          <td v-else />
                        </tr>
                      </table>

                      <JSONEditor v-if="showAdvanced" v-model="entity.payload" />
                    </td>
                    <td v-else style="text-align: center; color: grey">NA</td>
                  </tr>
                </table>
              </el-collapse-item>

              <el-collapse-item v-if="selectedPublishSet.dialog" name="dialog">
                <template slot="title">
                  Dialogs
                  <i class="header-icon el-icon-info" />
                </template>
                <table class="PublishTable" border="1">
                  <tr>
                    <th class="table-id">Dialog ID</th>
                    <th class="table-status">Status</th>
                    <th>New Values</th>
                  </tr>
                  <tr v-for="dialog in selectedPublishSet.dialog" :key="dialog.payload_id">
                    <td class="table-id">
                      {{
                        (dialog.new_values && jsonParse(dialog.new_values).title) ||
                        (dialog.old_values && jsonParse(dialog.old_values).title) ||
                        dialog.payload_id
                      }}
                    </td>
                    <td v-if="dialog.isDelete" class="table-status" style="color: red">Delete</td>
                    <td v-else class="table-status" style="color: Green">Add</td>
                    <td
                      v-if="dialog.new_values && !dialog.isDelete && dialog.payload"
                      style="text-align: left"
                    >
                      <div>
                        <b v-if="jsonParse(dialog.new_values).type !== 'folder'">Dialog Node:</b>
                        <b v-else>Folder Name:</b>
                        {{ dialog.payload.title }}
                        <br />
                      </div>
                      <div v-if="jsonParse(dialog.new_values).type">
                        <b>Node Type:</b>
                        {{ jsonParse(dialog.new_values).type }}
                        <br />
                      </div>
                      <div v-if="jsonParse(dialog.new_values).mcr_number">
                        <b>Conditional Response #:</b>
                        {{ jsonParse(dialog.new_values).mcr_number }}
                        <br />
                      </div>
                      <div v-if="jsonParse(dialog.new_values).parent_title">
                        <b>Parent Dialog Node:</b>
                        {{ jsonParse(dialog.new_values).parent_title }}
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).variable &&
                          jsonParse(dialog.new_values).type === 'slot'
                        "
                      >
                        <b>Save It As:</b>
                        {{ jsonParse(dialog.new_values).variable }}
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).context &&
                          jsonParse(dialog.new_values).type === 'event_handler'
                        "
                      >
                        <b>Check For:</b>
                        {{
                          Object.keys(jsonParse(dialog.new_values).context)[0]
                            ? Object.keys(jsonParse(dialog.new_values).context)[0]
                            : ""
                        }}
                        <br />
                        <b>Save It As:</b>
                        {{
                          jsonParse(dialog.new_values).context[
                            Object.keys(jsonParse(dialog.new_values).context)[0]
                          ]
                        }}
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).output &&
                          jsonParse(dialog.new_values).event_name === 'focus'
                        "
                      >
                        <b>If not present, ask:</b>
                        {{ jsonParse(dialog.new_values).output.text }}
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).output &&
                          jsonParse(dialog.new_values).event_name === 'filled'
                        "
                      >
                        <b>Found:</b>
                        {{ jsonParse(dialog.new_values).output.text }}
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).output &&
                          jsonParse(dialog.new_values).event_name === 'nomatch'
                        "
                      >
                        <b>Not Found:</b>
                        {{ jsonParse(dialog.new_values).output.text }}
                      </div>
                      <div v-if="dialog.payload.description">
                        <b>Permission:</b>
                        {{ dialog.payload.description }}
                        <br />
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).conditions &&
                          jsonParse(dialog.new_values).conditions !== '' &&
                          (jsonParse(dialog.new_values).type === 'standard' ||
                            jsonParse(dialog.new_values).type === 'folder' ||
                            jsonParse(dialog.new_values).type === 'frame')
                        "
                      >
                        <b>Bot recognises:</b>
                        <table id="dialog-condition-table" style="width: 100%" border="1">
                          <tr>
                            <th class="dialog-condition-table-operator">Operator</th>
                            <th class="dialog-condition-table-text">Conditions</th>
                          </tr>
                          <tr
                            v-for="condition in jsonParse(dialog.new_values).conditionArray"
                            :key="condition.text"
                          >
                            <td
                              v-if="condition.condition == '&&'"
                              class="dialog-condition-table-operator"
                            >
                              AND
                            </td>
                            <td
                              v-else-if="condition.condition == '||'"
                              class="dialog-condition-table-operator"
                              style="color: grey"
                            >
                              OR
                            </td>
                            <td
                              v-else-if="jsonParse(dialog.new_values).conditionArray.length === 1"
                              class="dialog-condition-table-operator"
                              style="color: grey"
                            >
                              NA
                            </td>
                            <td v-else class="dialog-condition-table-operator" />
                            <td class="dialog-condition-table-text">
                              {{ condition.text }}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div
                        v-if="
                          jsonParse(dialog.new_values).conditions &&
                          jsonParse(dialog.new_values).conditions !== '' &&
                          jsonParse(dialog.new_values).type !== 'standard' &&
                          jsonParse(dialog.new_values).type !== 'folder' &&
                          jsonParse(dialog.new_values).type !== 'frame' &&
                          jsonParse(dialog.new_values).type === 'response_condition'
                        "
                      >
                        <b>Bot recognises:</b>
                        <br />
                        {{ jsonParse(dialog.new_values).conditions }}
                        <br />
                      </div>
                      <div
                        v-if="
                          dialog.payload.context &&
                          Object.keys(dialog.payload.context).length > 0 &&
                          jsonParse(dialog.new_values).type !== 'event_handler'
                        "
                      >
                        <b>Context:</b>
                        <br />
                        <table class="dialog-context-table" style="width: 100%" border="1">
                          <tr>
                            <th class="dialog-context-table-variable">Variables</th>
                            <th class="dialog-context-table-value">Value</th>
                          </tr>
                          <tr
                            v-for="(context, index) in jsonParse(dialog.new_values).contextArray"
                            :key="index"
                          >
                            <td class="dialog-context-table-variable">
                              {{ context.key }}
                            </td>
                            <td class="dialog-context-table-value">
                              {{ context.value }}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div
                        v-if="
                          dialog.payload.output &&
                          dialog.payload.output.generic &&
                          dialog.payload.output.generic.length > 0
                        "
                      >
                        <div
                          v-for="(response, index) in dialog.payload.output.generic"
                          :key="index"
                        >
                          <b>Response #{{ index + 1 }}:</b>
                          <br />
                          <b>Main Type:</b>
                          {{ response.response_type }}
                          <br />
                          <table id="dialog-response-table" style="width: 100%" border="1">
                            <tr>
                              <th class="dialog-response-table-type">Type</th>
                              <th>Value</th>
                            </tr>

                            <tr v-for="value in response.values" :key="value.text">
                              <td v-if="value.text" class="dialog-response-table-type">
                                <span v-if="contentNodeExists(value.text)"> Content node </span>
                                <span v-else>Plain text</span>
                              </td>
                              <td v-if="value.text" style="text-align: center">
                                {{ value.text }}
                                <br />
                                <Bubble
                                  v-if="contentNodeExists(value.text)"
                                  v-model="publishContentNodeObject[value.text].content"
                                />
                                <QuickReplies
                                  v-if="contentNodeExists(value.text)"
                                  v-model="publishContentNodeObject[value.text].quickReplies"
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div v-if="dialog.payload.next_step">
                        <b>Finally:</b>
                        {{ dialog.payload.next_step.behavior }}
                        <br />
                      </div>
                      <div v-if="dialog.payload.digress_in">
                        <b>Digress In:</b>
                        {{ dialog.payload.digress_in }}
                        <br />
                      </div>
                      <div v-if="dialog.payload.digress_out">
                        <b>Digress Out:</b>
                        {{ dialog.payload.digress_out }}
                        <br />
                      </div>
                      <div
                        v-if="
                          dialog.payload.metadata &&
                          dialog.payload.metadata._customization &&
                          dialog.payload.metadata._customization
                        "
                      >
                        <b>Metadata:</b>
                        <br />
                        <table class="dialog-context-table" style="width: 100%" border="1">
                          <tr>
                            <th class="dialog-context-table-variable">Type</th>
                            <th class="dialog-context-table-value">Value</th>
                          </tr>
                          <tr
                            v-for="(value, key) in JSON.parse(dialog.new_values).metadata
                              ._customization"
                            :key="key"
                          >
                            <td class="dialog-context-table-variable" style="text-align: center">
                              {{ key }}
                            </td>
                            <td class="dialog-context-table-value" style="text-align: center">
                              {{ value }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                    <td v-else style="text-align: center; color: grey">NA</td>
                  </tr>
                </table>
              </el-collapse-item>
            </el-collapse>
            <JSONEditor v-if="showAdvanced" v-model="selectedPublishSet" />
          </el-card>

          <el-card
            v-else
            v-loading="pendingLoading || completedLoading"
            style="min-height: 600px"
            element-loading-text="Loading publish..."
          >
            <h1 style="text-align: center">
              Select a publish from the left.
              <br />
              <i
                class="el-icon-document"
                style="margin-top: 0.5em; font-size: 200px; color: #e6ebf5"
              />
            </h1>
          </el-card>
        </el-col>
      </el-row>

      <el-dialog
        title="Delete Publish Confirmation"
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="deletePublish">Confirm</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import JSONEditor from "@/components/JSONEditor";
import Bubble from "@/components/Bubble";
import QuickReplies from "@/components/QuickReplies";
import { mapGetters } from "vuex";
import { jsonParse } from "@/helpers/parser";

export default Vue.extend({
  components: {
    JSONEditor,
    Bubble,
    QuickReplies,
  },
  data() {
    return {
      moment: moment,
      activePublishTab: "pending",
      adminLoading: false,
      searchTerm: "",
      editPublishLoading: false,
      editSelectedPublish: false,
      editPublishConfirmationRule: {
        publish_date: [
          {
            required: true,
            message: "Please input date and time",
            trigger: "blur",
          },
        ],
      },

      dateTimePicker: {
        disabledDate(time) {
          return time.getTime() < new Date().getTime();
        },
        shortcuts: [
          {
            text: "Tomorrow",
            onClick(picker) {
              const date = new moment().add(1, "day").toDate();
              picker.$emit("pick", date);
            },
          },
          {
            text: "A week later",
            onClick(picker) {
              const date = new moment().add(1, "week").toDate();
              picker.$emit("pick", date);
            },
          },
          {
            text: "A month later",
            onClick(picker) {
              const date = new moment().add(1, "month").toDate();
              picker.$emit("pick", date);
            },
          },
        ],
      },
      activeContentArray: [],
      centerDialogVisible: false,
      publishContentName: "",
      pendingPublishList: [],
      pendingPublishMap: new Map(),

      completedPublishList: [],
      completedPublishMap: new Map(),
      deleteLoading: false,
      pendingLoading: false,
      completedLoading: false,

      selectedIndex: 0,
      selectedPublishSet: {},

      publishContentNodeObject: {},
      completedPublishFetched: false,
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication", "makerCheckerObject", "showAdvanced"]),
    finalizedPendingPublishLeftList() {
      const currentList = this.pendingPublishList;
      const self = this;
      const orderedList = _.chain(currentList)
        .map((publish) => {
          const { publishName, publish_id, publish_date, date_created } = publish;
          const moment_publish_date = moment(publish_date, "DD-MM-YYYY HH:mm:ss");
          const moment_date_created = moment(date_created, "DD-MM-YYYY HH:mm:ss");
          return {
            publishName: publishName ? publishName : publish_id.substring(0, 8),
            publish_id,
            publish_date,
            moment_publish_date,
            moment_date_created,
            publish,
          };
        })
        .orderBy(["moment_date_created"], ["desc"])
        .value();

      const filteredOrderedList = _.chain(orderedList)
        .filter((publish) => {
          if (self.searchTerm !== "") {
            return JSON.stringify(publish).toLowerCase().includes(self.searchTerm.toLowerCase());
          } else {
            return true;
          }
        })
        .uniqBy("publish_id")
        .value();

      return filteredOrderedList;
    },

    finalizedCompletedPublishLeftList() {
      const self = this;
      const currentList = this.completedPublishList;
      const orderedList = _.chain(currentList)
        .map((publish) => {
          const { publishName, publish_id, publish_date } = publish;
          const moment_publish_date = moment(publish_date, "DD-MM-YYYY HH:mm:ss");

          return {
            publishName: publishName ? publishName : publish_id.substring(0, 8),
            publish_id,
            publish_date,
            moment_publish_date,
            publish,
          };
        })
        .orderBy(["moment_publish_date"], ["desc"])
        .value();

      const filteredOrderedList = _.chain(orderedList)
        .filter((publish) => {
          if (self.searchTerm !== "") {
            return JSON.stringify(publish).toLowerCase().includes(self.searchTerm.toLowerCase());
          } else {
            return true;
          }
        })
        .uniqBy("publish_id")
        .value();
      return filteredOrderedList;
    },
    pendingCounter() {
      const self = this;
      return _.uniqBy(this.pendingPublishList, (publish) => {
        return publish.publish_id;
      }).filter((publish) => {
        if (self.searchTerm !== "") {
          return JSON.stringify(publish).toLowerCase().includes(self.searchTerm.toLowerCase());
        } else {
          return true;
        }
      }).length;
    },
    completedCounter() {
      const self = this;
      return _.uniqBy(this.completedPublishList, (publish) => {
        return publish.publish_id;
      }).filter((publish) => {
        if (self.searchTerm !== "") {
          return JSON.stringify(publish).toLowerCase().includes(self.searchTerm.toLowerCase());
        } else {
          return true;
        }
      }).length;
    },
  },
  mounted() {
    this.fetchPendingPublish();
    this.fetchCompletedPublish();
  },
  methods: {
    jsonParse: jsonParse,
    contentNodeExists(content_node_id) {
      const contentNode = this.$store.state.nodes.content[content_node_id];
      if (contentNode) {
        this.publishContentNodeObject[content_node_id] = contentNode;
        return true;
      } else {
        return false;
      }
    },
    resetPublishSelection() {
      this.selectedPublishSet = {};
    },
    setupPendingPublicationMap(currentList) {
      _.chain(currentList)
        .orderBy(["date_created"], ["asc"])
        .map((publish) => {
          const { publish_id, publishName, publish_date, type, approved_by, modified_by } = publish;
          if (!this.pendingPublishMap.has(publish_id)) {
            this.pendingPublishMap.set(publish_id, {
              publish_id,
              publishName,
              publish_date,
              modified_by: modified_by,
              approved_by,
              [type]: [publish],
            });
          } else {
            // update the publish based on type.
            // Update the modified by as well.
            const tempObject = this.pendingPublishMap.get(publish_id);
            const typeArray = tempObject[type] || [];
            const modifiedByString = tempObject.modified_by || "";
            const modifiedByArray = modifiedByString.split(",");

            typeArray.push(publish);
            modifiedByArray.push(modified_by);

            const uniqueTypeArray = _.chain(typeArray)
              .orderBy("date_created")
              .uniqBy("payload_id")
              .value();
            const uniqueModifiedByArray = _.uniq(modifiedByArray);

            tempObject[type] = uniqueTypeArray;
            tempObject.modified_by = uniqueModifiedByArray.join(",");

            this.pendingPublishMap.set(publish_id, tempObject);
          }
          return publish;
        })
        .value();

      return this.pendingPublishMap;
      // console.log("this.pendingPublishMap: ", this.pendingPublishMap);
    },
    setupCompletedPublicationMap(currentList) {
      _.chain(currentList)
        .orderBy(["date_created"], ["asc"])
        .map((publish) => {
          const { publish_id, publishName, publish_date, type, approved_by, modified_by } = publish;
          if (!this.completedPublishMap.has(publish_id)) {
            this.completedPublishMap.set(publish_id, {
              publish_id,
              publishName,
              publish_date,
              modified_by: modified_by,
              approved_by,
              isCompleted: true,
              [type]: [publish],
            });
          } else {
            // update the publish based on type.
            // Update the modified by as well.
            const tempObject = this.completedPublishMap.get(publish_id);
            const typeArray = tempObject[type] || [];
            const modifiedByString = tempObject.modified_by || "";
            const modifiedByArray = modifiedByString.split(",");

            typeArray.push(publish);
            modifiedByArray.push(modified_by);

            const uniqueTypeArray = _.chain(typeArray)
              .orderBy("change_last_modified")
              .uniqBy("payload_id")
              .value();
            const uniqueModifiedByArray = _.uniq(modifiedByArray);

            tempObject[type] = uniqueTypeArray;
            tempObject.modified_by = uniqueModifiedByArray.join(",");

            this.completedPublishMap.delete(publish_id);
            this.completedPublishMap.set(publish_id, tempObject);
          }
          return publish;
        })
        .value();

      return this.completedPublishMap;
    },
    handleCurrentPendingPublish(row) {
      if (row && row.publish_id) {
        this.selectedPublishSet = this.pendingPublishMap.get(row.publish_id);
        // console.log("selectedPublish", this.selectedPublish.publishName);
        this.$refs.pendingPublishTable.setCurrentRow(row.publish_id);
      }
    },
    handleCurrentCompletedPublish(row) {
      // Used to get the batch of publish
      if (row && row.publish_id) {
        this.selectedPublishSet = this.completedPublishMap.get(row.publish_id);
        // console.log("selectedPublish", this.selectedPublish.publishName);
        this.$refs.completedPublishTable.setCurrentRow(row.publish_id);
      }
    },
    completeAllPublications() {
      this.adminLoading = true;
      this.$store
        .dispatch("COMPLETE_ALL_PUBLICATIONS")
        .then((isCompleted) => {
          this.adminLoading = false;
          if (isCompleted) {
            this.fetchPendingPublish();
            this.fetchCompletedPublish();
            this.resetPublishSelection();
            this.$message({
              type: "success",
              message: "All publications has been completed using ADMIN rights.",
            });
          } else {
            this.$message({
              type: "error",
              message: "Publication has been failed using ADMIN rights.",
            });
          }
        })

        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating and publish content using ADMIN rights.",
          });
        });
    },

    fetchPendingPublish(status = "pending") {
      this.pendingLoading = true;
      this.$store
        .dispatch("FETCH_PUBLISH", { status })
        .then((res) => {
          console.log("Pending publications fetched");
          this.pendingLoading = false;
          this.pendingPublishList = this.roleDepartmentPublicationFilter(res);
          this.setupPendingPublicationMap(this.roleDepartmentPublicationFilter(res));
          this.selectedPublishSet = {};
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching pending publish.",
          });
        });
    },
    fetchCompletedPublish(status = "completed", offset = 0) {
      this.completedLoading = true;
      this.$store
        .dispatch("FETCH_PUBLISH", { status, offset })
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            this.completedPublishFetched = false; // reset the view-more button
          }
          console.log("Completed publications fetched");
          this.completedLoading = false;
          this.completedPublishList = this.roleDepartmentPublicationFilter(res);
          this.setupCompletedPublicationMap(this.roleDepartmentPublicationFilter(res));
          this.selectedPublishSet = {};
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error fetching publications.",
          });
        });
    },
    fetchNextHundredCompletedPublish(status = "completed") {
      const currentCompletedSize = this.completedPublishList && this.completedPublishList.length;
      const completedPublishExist = currentCompletedSize > 0;

      const makerCheckerEnabled = this.makerCheckerObject && this.makerCheckerObject.enabled;

      if (makerCheckerEnabled) {
        this.completedLoading = true;

        this.$store
          .dispatch("FETCH_PUBLISH", { status, offset: currentCompletedSize })
          .then((publish) => {
            this.completedLoading = false;
            if (publish && publish.length === 0) {
              this.completedPublishFetched = true;
            } else {
              this.completedPublishFetched = false;
            }

            this.completedPublishList.push(...this.roleDepartmentPublicationFilter(publish));

            this.setupCompletedPublicationMap(this.completedPublishList);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "Encountered error fetching completed publish",
            });
          });
      } else {
        this.completedLoading = false;
      }
    },
    roleDepartmentPublicationFilter(publicationList) {
      let userAuthenticatedPublicationList = this.userAuthenticationFilter(publicationList);
      let finalList = this.publicationDepartmentFilter(userAuthenticatedPublicationList);
      if (finalList.length === 0) {
        return [];
      } else {
        return finalList;
      }
    },
    userAuthenticationFilter(publicationList) {
      const { departments, permissions, roles } = this.getUserAuthentication;
      return _.filter(publicationList, (publish) => {
        // Authentication Check
        return roles && roles.includes("admin");
      });
    },
    publicationDepartmentFilter(publicationList) {
      const { departments, permissions, roles } = this.getUserAuthentication;
      const lowerCaseDepartments = departments ? departments.map((v) => v.toLowerCase()) : [];
      return _.filter(publicationList, (publish) => {
        // Authentication Check
        const { permission } = publish;
        return (
          _.intersection(permission, lowerCaseDepartments).length > 0 ||
          permission.includes("general")
        );
      });
    },
    showDeleteConfirmation() {
      this.centerDialogVisible = true;
    },
    deletePublish() {
      this.deletePublishLoading = true;
      this.$store
        .dispatch("DELETE_PUBLICATION", {
          publish_id: this.selectedPublishSet.publish_id,
        })
        .then((isDeleted) => {
          this.deletePublishLoading = false;
          this.centerDialogVisible = false;
          if (isDeleted) {
            this.$message({
              type: "success",
              message: "Publish has been successfully deleted.",
            });
            this.fetchPendingPublish();
          } else {
            this.$message({
              type: "error",
              message: "Failed to delete publish.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error deleting publish.",
          });
        });
    },
    submitPublishUpdateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveCurrentPublish();
          return false;
        } else {
          console.log("Error submiting approval form!!");
          return false;
        }
      });
    },
    saveCurrentPublish() {
      this.editPublishLoading = true;
      const { publish_id, publishName, publish_date } = this.selectedPublishSet;
      const payload = {
        publish_id,
        publishName,
        publish_date,
      };
      this.$store
        .dispatch("UPDATE_PUBLISH", payload)
        .then((isUpdated) => {
          this.editPublishLoading = false;
          if (isUpdated) {
            this.editSelectedPublish = false;
            this.$message({
              type: "success",
              message: "The publish has been updated successfully.",
            });
            this.fetchPendingPublish();
          } else {
            this.$message({
              type: "error",
              message: "Failed to update publish.",
            });
          }
        })
        .catch((err) => {
          this.editSelectedPublish = false;
          this.$message({
            type: "error",
            message: "Encountered error updating publish.",
          });
        });
    },
    collapseAll() {
      this.activeContentArray = [];
    },
    expandAll() {
      this.activeContentArray = ["editor", "intent", "entity"];
    },
  },
});
</script>

<style>
#pane-publisher .el-collapse-item__wrap {
  overflow-x: auto !important;
}
</style>

<style scoped>
.delete.button {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: #e4392b !important;
  color: white !important;
  border-color: #e4392b !important;
}

.delete.button:hover {
  background: white !important;
  color: #e4392b !important;
  border-color: #e4392b !important;
}

.button:hover {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: #409eff !important;
  color: white !important;
  border-color: #409eff !important;
}

.button {
  font-size: 14px;
  text-align: center;
  background: white !important;
  color: #409eff !important;
  border-color: #409eff !important;
}

.PublishTable {
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;
}

.PublishTable tr th {
  width: 25%;
  text-align: center;
}

.PublishTable tr td {
  width: 25%;
  max-width: 300px;
  text-align: left;
  vertical-align: top;
}

.PublishTable * .table-id {
  width: 10%;
  text-align: center;
}

.PublishTable * .table-status {
  width: 5%;
  text-align: center;
}

#entity-table * .index {
  width: 5%;
  text-align: center;
}

#entity-table * .value {
  width: 10%;
  text-align: center;
}

#entity-table * .value-type {
  width: 20%;
  text-align: center;
}

#entity-table * .synonyms {
  text-align: center;
  width: 20%;
}

#entity-table * .patterns {
  text-align: center;
  width: 20%;
}

#dialog-condition-table .dialog-condition-table-operator {
  width: 5%;
  text-align: center;
}

#dialog-condition-table .dialog-condition-table-text {
  text-align: center;
}

.dialog-context-table .dialog-context-table-variable {
  width: 20%;
  text-align: center;
}

.dialog-context-table .dialog-context-table-value {
  text-align: center;
}

#dialog-response-table .dialog-response-table-type {
  width: 5%;
  text-align: center;
}

.completed-view-more {
  color: grey;
}

.completed-view-more:hover {
  color: #e4392b;
}
</style>
