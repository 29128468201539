export const EVENT_NODE_TYPES = [
  {
    label: "Jump to Content",
    name: "goto",
  },
  {
    label: "Capture Data",
    name: "capture",
  },
  {
    label: "Set Data",
    name: "set",
  },
  {
    label: "Start Workflow",
    name: "startflow",
  },
  {
    label: "Escalate to Agent",
    name: "escalate",
  },
  {
    label: "API Call",
    name: "callApi",
  },
  {
    label: "Embed Content",
    name: "$display",
  },
  {
    label: "Custom Event",
    name: "custom event",
  },
];
