<template>
  <el-card style="position: relative">
    <el-row
      v-for="(suggestedAnswer, suggestedAnswerIndex) in suggestedAnswers"
      :gutter="0"
      :key="`${activeFlow.flowId}-text-option-${suggestedAnswer.id}`"
    >
      <el-form :model="suggestedAnswer" label-width="140px">
        <el-divider style="margin: 12px 0 0 0">
          Text Pattern #{{ suggestedAnswerIndex + 1 }}
          <el-button
            circle
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click.prevent="deleteSuggestedAnswer(suggestedAnswer.id)"
          />
        </el-divider>

        <el-form-item style="margin-bottom: 0px">
          <label slot="label" style="text-align: left">
            Text Pattern
            <el-tooltip
              effect="dark"
              content="Regular Expression to match reply's text pattern OR Custom Event to validate"
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em; color: #909399" />
            </el-tooltip>
          </label>

          <el-input
            v-model="suggestedAnswer.textPattern"
            size="mini"
            placeholder="Type your text pattern"
            style="width: 100%"
          />
        </el-form-item>

        <el-form-item style="margin-bottom: 0px">
          <label slot="label" style="text-align: left">
            Test
            <el-tooltip
              effect="dark"
              content="Test regular expression to match reply's text pattern"
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em; color: #909399" />
            </el-tooltip>
          </label>

          <el-input
            v-model="suggestedAnswer.positiveTest"
            size="mini"
            placeholder="Test your text pattern here"
            style="width: 100%"
          >
            <div slot="append" style="cursor: pointer">
              <i
                :class="{
                  'el-icon-check has-text-success': regexTest(
                    suggestedAnswer.positiveTest,
                    true,
                    suggestedAnswerIndex
                  ),
                  'el-icon-warning-outline has-text-danger': !regexTest(
                    suggestedAnswer.positiveTest,
                    true,
                    suggestedAnswerIndex
                  ),
                }"
              ></i>
            </div>
          </el-input>
        </el-form-item>

        <el-form-item style="margin-bottom: 0px">
          <label slot="label" style="text-align: left">If matched</label>

          <div style="display: flex; align-items: center">
            <el-tooltip
              popper-class="previewer"
              :content="previewSuccessOption(suggestedAnswer.afterAction.success)"
              placement="top"
            >
              <el-select
                size="mini"
                filterable
                v-model="suggestedAnswer.afterAction.success"
                style="width: 100%"
                placeholder="Select action"
              >
                <el-option-group
                  v-for="group in successOptions"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-tooltip>

            <el-button
              size="mini"
              type="text"
              icon="el-icon-d-arrow-right"
              @click.prevent="$emit('navigate', suggestedAnswer.afterAction.success)"
              circle
            ></el-button>
          </div>
        </el-form-item>

        <el-form-item style="margin-bottom: 0px" v-if="$store.getters.isShowAdvanced">
          <label slot="label" style="text-align: left">
            Side Effect
            <el-tooltip
              effect="dark"
              content="Side effect event to execute when suggested answer is met. Cannot have 'goto' OR 'starflow'"
              placement="right"
            >
              <i class="el-icon-question" style="margin-left: 0.5em; color: #909399" />
            </el-tooltip>
          </label>

          <SideEffect :active-flow="activeFlow" :answer="suggestedAnswer" />
        </el-form-item>
      </el-form>
    </el-row>

    <el-button
      plain
      size="mini"
      type="text"
      icon="el-icon-plus"
      @click.prevent="addSuggestedAnswer"
    >
      Add Answer
    </el-button>
  </el-card>
</template>

<script>
import _ from "lodash";
import { previewSuccessOption } from "../Index.ts";
import SideEffect from "../SideEffect/Index";

export default {
  props: ["question", "activeFlow", "successOptions"],
  data() {
    return {
      activeSuggestedAnswer: [],
      previewSuccessOptionText: {},
    };
  },
  components: {
    SideEffect,
  },
  computed: {
    suggestedAnswers() {
      const tempAnswers = this.question.suggestedAnswers;
      if (Array.isArray(tempAnswers)) {
        return tempAnswers.filter((answer) => {
          return answer.answerType === "phone";
        });
      }
      return tempAnswers;
    },
  },

  methods: {
    previewSuccessOption,
    addValidationMethod(answerId) {
      const newValidation = {
        methodType: "customEvent",
        value: "",
      };

      if (this.question.suggestedAnswers) {
        const indexOfAnswer = _.findIndex(this.question.suggestedAnswers, {
          id: answerId,
        });

        if (indexOfAnswer > -1) {
          const currentAnswerValidations = _.get(
            this,
            `question.suggestedAnswers[${indexOfAnswer}].validationMethods`,
            []
          );

          currentAnswerValidations.push(newValidation);
          this.$set(
            this.question.suggestedAnswers[indexOfAnswer],
            "validationMethods",
            currentAnswerValidations
          );
        }
      }
    },
    removeValidationMethod(answerId, methodIndex) {
      const invalidIndex = methodIndex <= -1;
      if (invalidIndex) {
        return;
      }

      if (this.question.suggestedAnswers) {
        const indexOfAnswer = _.findIndex(this.question.suggestedAnswers, {
          id: answerId,
        });
        if (indexOfAnswer > -1) {
          const currentAnswerValidations = _.get(
            this,
            `question.suggestedAnswers[${indexOfAnswer}].validationMethods`,
            []
          );

          currentAnswerValidations.splice(methodIndex, 1);
          this.$set(
            this.question.suggestedAnswers[indexOfAnswer],
            "validationMethods",
            currentAnswerValidations
          );
        }
      }
    },
    async addSuggestedAnswer(question, suggestedAnswers, answerType = "phone") {
      try {
        const suggestedAnswer = await this.$store.dispatch("CREATE_NEW_SUGGESTED_ANSWER", {
          questionId: this.question.questionId,
          answerType: "phone",
          question: this.question,
        });
        this.question.suggestedAnswers.push(suggestedAnswer);
      } catch (error) {
        this.$message({
          type: "error",
          message: "Encountered error creating new suggested answer.",
        });
      }
    },
    async deleteSuggestedAnswer(suggestedAnswerId) {
      try {
        const deleted = await this.$store.dispatch("DELETE_SUGGESTED_ANSWER", {
          suggestedAnswerId,
        });
        this.question.suggestedAnswers.splice(
          _.findIndex(this.suggestedAnswers, { id: suggestedAnswerId }),
          1
        );
      } catch (error) {
        this.$message({
          type: "error",
          message: "Encountered error deleting suggested answer.",
        });
      }
    },
    regex(suggestedAnswerIndex) {
      const currentTempSuggestedAnswer = this.question.suggestedAnswers[suggestedAnswerIndex];
      if (currentTempSuggestedAnswer.textPattern) {
        let flags = currentTempSuggestedAnswer.textPattern.replace(/.*\/([gimy]*)$/, "$1") || "i";
        let pattern = currentTempSuggestedAnswer.textPattern.replace(
          new RegExp("^/(.*?)/" + flags + "$"),
          "$1"
        );
        try {
          let regex = new RegExp(pattern, flags);
          return regex;
        } catch (e) {
          return null;
        }
      }
    },

    regexTest(text, isPositive, suggestedAnswerIndex) {
      if (this.regex(suggestedAnswerIndex) && this.regex(suggestedAnswerIndex).test(text)) {
        return isPositive;
      } else {
        return !isPositive;
      }
    },
  },
};
</script>
