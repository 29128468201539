<template>
  <el-container
    v-loading="$store.state.loaded < 2 && $route.name !== 'error' && !$store.state.showAdvanced"
    :element-loading-text="randomLoadingMessage"
    style="height: 100%"
  >
    <el-aside
      v-if="$route.name !== 'error' && $route.name !== 'logout'"
      width="auto"
      style="position: fixed; height: 100%"
    >
      <side-nav />
    </el-aside>

    <el-main style="margin-left: 65px; padding: 10px">
      <template v-if="$auth.isAuthenticated">
        <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="inactiveDialogVisible"
          width="60vw"
          center
        >
          <span slot="title" class="session-timeout-header">
            <i class="el-icon-warning" style="color: #eb9e05; position: relative; right: 5px"></i>
            <label>Session Timeout</label>
          </span>
          <span class="session-timeout-body">
            You're about to be logged out for security purposes. Please click on "Continue" to
            remain logged in, or "Logout" to sign out of the dashboard.
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              :disabled="isContinueButtonDisabled"
              @click="closeAllDialogs"
              class="session-timeout-btn"
            >
              Continue ({{ secondsOfInactivityRemaining }})
            </el-button>
            <el-button
              @click="sessionLogout"
              :disabled="isLogoutButtonDisabled"
              class="session-timeout-btn"
            >
              Logout
            </el-button>
          </span>
        </el-dialog>
        <transition name="el-fade-in">
          <router-view v-if="$store.state.loaded >= 2 || $route.name === 'error'" />
        </transition>
      </template>

      <DeveloperConsole v-if="$store.state.showDeveloperConsole" />
      <IntentDrawer />
      <WebWidget />
    </el-main>

    <div class="version">
      <span v-if="$store.state.showAdvanced">
        {{ releaseId.server }} | {{ releaseId.dashboard }}
      </span>
      <span v-else-if="releaseId.simple">{{ releaseId.simple }}</span>
    </div>
  </el-container>
</template>
<script>
import gql from "graphql-tag";
import DeveloperConsole from "./components/DeveloperConsole.vue";
import IntentDrawer from "./components/IntentDrawer.vue";
import SideNav from "./components/SideNav.vue";
import _ from "lodash";
import * as IdleJs from "idle-js";
import { logout, shouldLogOut } from "@/helperMethods/auth";
import { trackNetworkStatus } from "@/helpers/networkStatusStorage";
import {
  resetAutoSessionLocalStorage,
  setAutoSessionLocalStorage,
  setStartAutoSession,
  onInactiveSession,
  addTab,
  removeTab,
  setLoginTime,
  toMinutes,
} from "@/helperMethods/autoSession";
import WebWidget from "@/components/WebWidget/Index";
import globalSubscriptionMixin from "@/mixins/globalSubscription";
import LocalStorageManager from "@/localStorageManager";

export default {
  mixins: [globalSubscriptionMixin],
  components: {
    SideNav,
    DeveloperConsole,
    IntentDrawer,
    WebWidget,
  },
  data() {
    return {
      isContinueButtonDisabled: false,
      isLogoutButtonDisabled: false,
      secondsOfInactivityCounter: undefined,
      secondsOfInactivityRemaining: 60,
      inactiveDialogVisible: false,
      loaded: false,
      socketInitTimer: null,
      logoutDialogVisible: false,
      sessionLoggingOut: false,
      inIdle: false,
      timeoutInstance: null,
    };
  },
  computed: {
    releaseId() {
      return this.$store.getters.releaseId;
    },
    randomLoadingMessage() {
      return _.sample([
        "Assembling bot...",
        "Generating witty dialog...",
        "Swapping time and space...",
        "Spinning violently around the y-axis...",
        "Tokenizing real life...",
        "Bending the spoon...",
        "Multiplying the matrix",
        "Filtering morale...",
        "The architects are still drafting",
        "The bits are breeding",
        "Playing elevator music...",
        "...at least you're not on hold...",
        "It's not you. It's me.",
        "Winter is coming...",
        "Obfuscating quantum entaglement...",
        "Why don't you order a sandwich?",
        "We're making you a cookie...",
        "Computing chance of success..",
        "Adjusting flux capacitor...",
        "Granting wishes...",
        "I think I am, therefore, I am. I think.",
        "These violent delights have violent ends...",
        "Searching for plot device...",
      ]);
    },
    timeBeforeIdle() {
      let sessionTimeoutDuration = _.get(
        this,
        "$store.state.modules.system.sessionTimeoutConfig.duration.time",
        15
      );
      const sessionTimeoutDurationUnit = _.get(
        this,
        "$store.state.modules.system.sessionTimeoutConfig.duration.unit",
        "minutes"
      );
      sessionTimeoutDuration = toMinutes(sessionTimeoutDuration, sessionTimeoutDurationUnit);
      const sessionTimeoutWarningDuration = _.get(
        this,
        "$store.state.modules.system.sessionTimeoutConfig.warningDuration",
        1
      );

      return (sessionTimeoutDuration - sessionTimeoutWarningDuration) * 1000 * 60; // in ms
    },
  },
  watch: {
    inactiveDialogVisible(newValue, oldValue) {
      if (newValue === false) {
        clearInterval(this.secondsOfInactivityCounter);
        this.secondsOfInactivityCounter = undefined;
        this.setStartAutoSession();
      } else if (newValue === true && oldValue === false) {
        const sessionTimeoutWarningDuration = _.get(
          this,
          "$store.state.modules.system.sessionTimeoutConfig.warningDuration",
          1
        );
        this.secondsOfInactivityRemaining = Math.floor(sessionTimeoutWarningDuration * 60);
        this.secondsOfInactivityCounter = setInterval(() => {
          if (this.secondsOfInactivityRemaining <= 0) {
            this.isContinueButtonDisabled = true;
            this.isLogoutButtonDisabled = true;
            clearInterval(this.secondsOfInactivityCounter);
            this.secondsOfInactivityCounter = undefined;
            this.sessionLogout();
          } else {
            this.secondsOfInactivityRemaining--;
          }
        }, 1000);
      }
    },
  },
  beforeDestroy() {
    this.teardownAutoSessionTimeout();
    window.removeEventListener("online", this.onNetworkStatusOnline);
    window.removeEventListener("offline", this.onNetworkStatusOffline);
  },
  methods: {
    logout,
    shouldLogOut,
    resetAutoSessionLocalStorage,
    setAutoSessionLocalStorage,
    setStartAutoSession,
    onInactiveSession,
    addTab,
    removeTab,
    setLoginTime,
    onIdle() {
      this.inIdle = true;
      this.addTab("idleTabs");
      const tabs = LocalStorageManager.getItem("numberOfTabs");
      const idleTabs = LocalStorageManager.getItem("idleTabs");
      if (this.$auth.isAuthenticated && !this.inactiveDialogVisible && tabs === idleTabs) {
        this.$store.dispatch("SET_ALL_SESSION_DIALOG", true);
      }
    },
    onActive() {
      this.inIdle = false;
      this.removeTab("idleTabs");
    },
    closeAllDialogs() {
      const sessionTimeoutType = _.get(
        this,
        "$store.state.modules.system.sessionTimeoutConfig.type",
        "lastActivity"
      );
      if (sessionTimeoutType === "loginTime") {
        this.$store.dispatch("RENEW_TIMEOUT");
      }
      this.inactiveDialogVisible = false;
      document.title = "KeyReply";
      this.$store.dispatch("SET_ALL_SESSION_DIALOG", false);
    },
    onNetworkStatusOnline() {
      trackNetworkStatus(true);
    },
    onNetworkStatusOffline() {
      trackNetworkStatus(false);
    },
    setupNetworkConnectionHandler() {
      window.addEventListener("online", this.onNetworkStatusOnline);
      window.addEventListener("offline", this.onNetworkStatusOffline);
    },
    teardownAutoSessionTimeout() {
      if (this.idleChecking) {
        this.idleChecking.stop();
      }
      if (this.timeoutInstance) {
        clearTimeout(this.timeoutInstance);
        this.timeoutInstance = null;
      }
      if (this.secondsOfInactivityCounter) {
        clearInterval(this.secondsOfInactivityCounter);
      }
    },
    setupAutoSessionLogout() {
      const sessionTimeoutEnabled = _.get(
        this,
        "$store.state.modules.system.sessionTimeoutConfig.enabled",
        false
      );
      const sessionTimeoutType = _.get(
        this,
        "$store.state.modules.system.sessionTimeoutConfig.type",
        "lastActivity"
      );

      if (sessionTimeoutEnabled) {
        this.setAutoSessionLocalStorage();
        if (sessionTimeoutType === "lastActivity") {
          this.idleChecking = new IdleJs({
            idle: this.timeBeforeIdle, // idle time in ms
            events: ["mousemove", "keydown", "mousedown", "touchstart"], // events that will trigger the idle resetter
            onIdle: this.onIdle, // callback function to be executed after idle time
            onActive: this.onActive, // callback function to be executed after back form idleness
            onHide: this.setStartAutoSession, // callback function to be executed when window become hidden
            onShow: this.setStartAutoSession, // callback function to be executed when window become visible
            keepTracking: true, // set it to false if you want to be notified only on the first idleness change
            startAtIdle: false, // set it to true if you want to start in the idle state
          });
          this.idleChecking.start();
        } else if (sessionTimeoutType === "loginTime") {
          this.$store.dispatch("RENEW_TIMEOUT");
        } else {
          this.resetAutoSessionLocalStorage();
        }
      } else {
        this.resetAutoSessionLocalStorage();
      }
    },
    storeConnectedAgents(data) {
      const connectedAgents = _.chain(data)
        .cloneDeep()
        .get("data.livechatAgentConnectionStatus", {})
        .value();
      this.$store.dispatch("SET_CONNECTED_AGENTS", connectedAgents);
      return connectedAgents;
    },
    storeLatestAgentStatus(connectedAgents) {
      const currentAgent = _.find(
        connectedAgents,
        (agent) => agent.email === this.$store.state.profile.email
      );
      if (currentAgent) {
        this.$store.commit("SET_AGENT_STATUS", currentAgent.status);
        // this check for user click logout
        if (this.$store.state.currentTabLoggedOut === true) {
          this.$store.commit("SET_CURRENT_TAB_LOGGED_OUT", null);
          return;
        }
        // this check is for sync offline status when other tab has logged out
        if (!this.logoutDialogVisible && currentAgent.status === "OFFLINE" && this.shouldLogOut()) {
          this.logoutDialogVisible = true;
          this.confirmLogout(() => {
            this.logoutDialogVisible = false;
            this.logout();
          });
        }
      }
    },
    sessionLogout() {
      this.sessionLoggingOut = true;
      this.onInactiveSession();
    },
    confirmLogout(cb) {
      if (this.sessionLoggingOut || this.logoutDialogVisible) {
        return;
      }
      this.$alert(
        "It seems you have logged out from other window, you are now being redirected to login page.",
        "Logging out notice",
        {
          confirmButtonText: "Okay",
          callback: (action) => {
            if (action) cb();
          },
        }
      );
    },
  },
  apollo: {
    $subscribe: {
      sessionTimeout: {
        query: gql`
          subscription {
            sessionTimeout
          }
        `,
        result({ data }) {
          const action = _.get(data, "sessionTimeout.action");
          const actionData = _.get(data, "sessionTimeout.data");
          switch (action) {
            case "showDialog":
              if (this.inactiveDialogVisible !== actionData) {
                this.inactiveDialogVisible = actionData;
                if (actionData) {
                  document.title = "KeyReply - Logging out?";
                } else {
                  document.title = "KeyReply";
                }
              }
              break;
            case "logAllOut": {
              this.inactiveDialogVisible = false;
              const profile = LocalStorageManager.getItem("profile");
              // to avoid the screen that triggering logout will not doing the rest screen do
              if (!this.sessionLoggingOut && !profile) {
                this.$emit("closeInit", document.hasFocus());
              }
              break;
            }
            case "renewTimeout": {
              if (this.timeoutInstance) {
                clearTimeout(this.timeoutInstance);
                this.timeoutInstance = null;
              }
              this.timeoutInstance = setTimeout(() => {
                if (this.$auth.isAuthenticated && !this.inactiveDialogVisible) {
                  this.$store.dispatch("SET_ALL_SESSION_DIALOG", true);
                }
              }, this.timeBeforeIdle);
              break;
            }
            default:
              break;
          }
        },
      },
    },
  },
  mounted() {
    this.addTab("numberOfTabs");
    this.setLoginTime();
    window.onunload = () => {
      this.removeTab("numberOfTabs");
      if (this.inIdle) {
        this.removeTab("idleTabs");
      }
    };

    this.setupNetworkConnectionHandler();
    this.$store.dispatch("FETCH_BOT").then(() => {
      this.setupAutoSessionLogout();
    });
    this.$apollo.addSmartQuery("bot", {
      query: gql`
        query {
          Server {
            releaseId
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => {
        const releaseId = _.get(data, "Server.releaseId", "");
        window.SERVER_RELEASE_ID = releaseId;
        this.$store.commit("SET_RELEASE_ID", {
          key: "server",
          value: releaseId,
        });
        return data;
      },
    });
    this.subscribeLivechatAgentConnectionStatus();
    this.subscribeLivechatInviteAgent();
    this.subscribeNewLivechatMessageFromUser();
    this.$store.dispatch("FETCH_FAQ_DATASET");
    this.$store.dispatch("FETCH_ALL_MAPPINGS", {
      brain: this.$store.state.brain,
    });
  },
};
</script>

<style scoped>
.session-timeout-header {
  position: relative;
  font-size: 4.5vh;
}
.session-timeout-body {
  word-break: break-word;
  font-size: 3vh;
  line-height: 1.5em;
}
.session-timeout-btn {
  font-size: 3vh;
  height: 100%;
}
</style>
<style lang="scss">
html {
  display: none;
  height: 100%;
}

body {
  height: 100%;
}

.version {
  position: fixed;
  width: 64px;
  bottom: 0px;
  text-align: center;
  font-size: 6px;
  font-weight: bold;
  padding-bottom: 2px;
  // color: black;
  background-color: white;
  opacity: 0.5;
}

/** Mobile **/
@media only screen and (max-width: 867px), only screen and (max-device-width: 867px) {
  .el-main.outer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .el-main {
    padding-left: 0;
    padding-right: 0;
  }
}

.tooltip-button {
  /* background:red!important; */
  cursor: pointer;
  /* background:#E4392B; */
  /* color:white; */
  font-weight: 600;
}

.el-tabs__content {
  // height: 100% !important;
  // overflow: auto !important;
}
.el-tab-pane {
  height: 100%;
}

.el-autocomplete-suggestion {
  display: flex;
  -webkit-flex: 1; /* Safari 6.1+ */
  -ms-flex: 1; /* IE 10 */
  flex: 1;
  flex-direction: column;
  width: fit-content !important;
  min-width: 250px;
}
.el-autocomplete-suggestion__list {
  // min-width: 200px;
  display: flex;
  -webkit-flex: 1; /* Safari 6.1+ */
  -ms-flex: 1; /* IE 10 */
  flex: 1;
  flex-direction: column;
}
.el-autocomplete-suggestion li {
  text-overflow: clip;
}

// Add in cascader styling because it seems to be missing from elements version 9
.el-cascader-node__label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-cascader-node {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 30px 0 20px;
  height: 34px;
  line-height: 34px;
  outline: 0;
}

.el-cascader-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  font-size: 14px;
}

.el-cascader-menu {
  min-width: 180px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  border-right: solid 1px #e4e7ed;
}

.el-scrollbar {
  overflow: hidden;
  position: relative;
}

.el-scrollbar__wrap {
  overflow: scroll;
  height: 100%;
}

.el-cascader-menu__wrap {
  height: 204px;
}

.el-cascader-menu__list {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 6px 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.el-cascader__tags {
  position: absolute;
  left: 0;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
  line-height: normal;
  text-align: left;
  box-sizing: border-box;
}

.el-tag {
  background-color: #ecf5ff;
  display: inline-block;
  // height: 32px;
  // padding: 0 10px;
  // line-height: 30px;
  // font-size: 12px;
  color: #409eff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}

// .el-tag--small {
//   height: 24px;
//   padding: 0 8px;
//   line-height: 22px;
// }

.el-tag.el-tag--info {
  background-color: #f4f4f5;
  border-color: #e9e9eb;
  color: #909399;
}

.el-cascader__tags .el-tag {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  margin: 2px 0 2px 6px;
  text-overflow: ellipsis;
  background: #f0f2f5;
}

.el-cascader__tags .el-tag:not(.is-hit) {
  border-color: transparent;
}

.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: #e4392b;
  font-weight: 700;
}

// helper css
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.is-paddingless {
  padding: 0 !important;
}
.is-marginless {
  margin: 0 !important;
}
.has-text-right {
  text-align: right;
}
.has-text-left {
  text-align: left;
}
.has-text-danger {
  color: #e4392b;
}
.has-text-success {
  color: #67c23a;
}
.selected {
  box-shadow: none !important;
}
.el-select-group__wrap,
.el-select-group {
  width: 100% !important;
}
</style>
