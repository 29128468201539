import Vue from "vue";
import Vuex, { Store } from "vuex";
import state from "@/store/initialState";
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import { RootState } from "./types";
import livechat from "@/store/modules/livechat";
import supervisor from "@/store/modules/supervisor";
import floweditor from "@/store/modules/floweditor";
import apieditor from "@/store/modules/apieditor";
import whatsapp from "@/store/modules/whatsapp";
import watson from "@/store/modules/watson";
import faq from "@/store/modules/faq";
import department from "@/store/modules/department";
import prism from "@/store/modules/prism";
// @ts-ignore
import makerChecker from "./modules/makerchecker";
import editor from "./modules/editor";
import broadcast from "./modules/broadcast";
import fileUpload from "@/store/modules/miniapps"; // TODO: change miniapps to fileupload
import {
  contentNodeNames,
  getAgentName,
  getAgentSOEId,
  isShowAdvanced,
  releaseId,
} from "./getters";

Vue.use(Vuex);

const store: Store<RootState> = new Vuex.Store({
  modules: {
    livechat,
    supervisor,
    floweditor,
    apieditor,
    whatsapp,
    faq,
    department,
    watson,
    makerChecker,
    editor,
    broadcast,
    prism,
    fileUpload,
  },
  // @ts-ignore
  state,
  actions,
  mutations,
  getters: {
    getAgentSOEId,
    getAgentName,
    isShowAdvanced,
    releaseId,
    contentNodeNames,
  },
});

export default store;
